/* eslint-disable no-nested-ternary */
import './ContactUsPage.scss';

import {
  IonCard,
  IonCardContent,
  IonContent,
  IonLabel,
  IonPage,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserRole } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState, UserBatchModel } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import Heading from '../../components/Heading';
import TopHeader from '../../components/TopHeader';
import {
  loadCenterHeadContactInfo,
  loadTeacherContactInfo,
} from './contact-us.slice';

const ContactUsPage: React.FC = () => {
  const { contact: contactText, app: appTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const {
    filter: { batchId: selectedBatch, childrenId: selectedChildren },
  } = useSelector((state: RootState) => state.app);
  const { centerHeadContactInfo, teacherContactInfo, loading } = useSelector(
    (state: RootState) => state.contacts,
  );
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState<boolean>();

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadCenterHeadContactInfo({
          role: [UserRole.CenterHead],
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadTeacherContactInfo({
          role: [UserRole.Teacher],
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  let batchIds: string[] = [];
  let centerIds: string[] = [];

  if (currentUser) {
    let batches: UserBatchModel[] = [];

    if (currentUser.role.includes(UserRole.Student)) {
      batches = currentUser.batches || [];
    } else if (currentUser.role.includes(UserRole.Parent)) {
      if (selectedChildren) {
        const student = currentUser.children?.find(
          ({ _id }) => _id === selectedChildren,
        );

        batches = student?.batches || [];
      }
    }

    if (selectedBatch) {
      const userBatch =
        batches.find(({ batch }) => batch._id === selectedBatch)?.batch || null;

      if (userBatch) {
        batchIds = [userBatch._id];

        const userCenter = userBatch.center;

        if (userCenter) {
          centerIds = [userCenter._id];
        }
      }
    }
  }

  const batchTeachers =
    teacherContactInfo?.filter(({ batches }) => {
      const tBatchIds =
        batches?.map(({ batch: { _id } = {} }) => _id).filter((id) => !!id) ||
        [];
      const batchCount = tBatchIds.filter(
        (bId) => bId && batchIds.indexOf(bId) !== -1,
      ).length;

      return batchCount > 0;
    }) || [];

  const centerHeads =
    centerHeadContactInfo?.filter(({ centerIds: tCenterIds }) => {
      const centerCount =
        tCenterIds?.filter((tId) => centerIds.indexOf(tId) !== -1).length || 0;

      return centerCount > 0;
    }) || [];

  return (
    <IonPage>
      <TopHeader />
      <IonContent className="main-content" fullscreen>
        <Heading title={contactText.heading} iconName="fas fa-address-book" />
        <IonCard className="mb-2">
          <div className="flex-row-align-center card-title">
            <i className="fas fa-angle-double-right card-header-icon mr" />
            <IonLabel>{contactText.centerHead}</IonLabel>
          </div>
          {loading || !loadRequested ? (
            <AppLoader />
          ) : centerHeads.length === 0 ? (
            <IonCard className="mb-2">
              <IonCardContent className="card-content">
                <p>{appTxt.noRecords}</p>
              </IonCardContent>
            </IonCard>
          ) : (
            centerHeads?.map(({ _id, name, email, phone }, i) => {
              return (
                <React.Fragment key={_id}>
                  <IonCardContent className="card-content">
                    <IonText
                      color="secondary"
                      className="flex-row-align-center mb-1"
                    >
                      <p>
                        <i className="fas fa-user-alt mr" />
                        {name}
                      </p>
                    </IonText>
                    <IonText
                      color="secondary"
                      className="flex-row-align-center mb-1"
                    >
                      <p>
                        <i className="fas fa-envelope mr" />
                        <a href={`mailto:${email}`} className="common-link">
                          {email}
                        </a>
                      </p>
                    </IonText>
                    <IonText
                      color="secondary"
                      className="flex-row-align-center"
                    >
                      <p>
                        <i className="fas fa-phone mr" />
                        <a href={`tel:${phone}`} className="common-link">
                          {phone}
                        </a>
                      </p>
                    </IonText>
                  </IonCardContent>
                  {centerHeads.length !== 1 && i !== centerHeads.length - 1 ? (
                    <hr />
                  ) : null}
                </React.Fragment>
              );
            })
          )}
        </IonCard>
        <IonCard className="mb-2">
          <div className="flex-row-align-center card-title">
            <i className="fas fa-angle-double-right card-header-icon mr" />
            <IonLabel>{contactText.teacher}</IonLabel>
          </div>
          {loading || !loadRequested ? (
            <AppLoader />
          ) : batchTeachers.length === 0 ? (
            <IonCard className="mb-2">
              <IonCardContent className="card-content">
                <p>{appTxt.noRecords}</p>
              </IonCardContent>
            </IonCard>
          ) : (
            batchTeachers?.map(({ _id, name, email, phone }, i) => {
              return (
                <React.Fragment key={_id}>
                  <IonCardContent className="card-content">
                    <IonText
                      color="secondary"
                      className="flex-row-align-center mb-1"
                    >
                      <p>
                        <i className="fas fa-user-alt mr" />
                        {name}
                      </p>
                    </IonText>
                    <IonText
                      color="secondary"
                      className="flex-row-align-center mb-1"
                    >
                      <p>
                        <i className="fas fa-envelope mr" />
                        <a href={`mailto:${email}`} className="common-link">
                          {email}
                        </a>
                      </p>
                    </IonText>
                    <IonText
                      color="secondary"
                      className="flex-row-align-center"
                    >
                      <p>
                        <i className="fas fa-phone mr" />
                        <a href={`tel:${phone}`} className="common-link">
                          {phone}
                        </a>
                      </p>
                    </IonText>
                  </IonCardContent>
                  {batchTeachers.length !== 1 &&
                  i !== batchTeachers.length - 1 ? (
                    <hr />
                  ) : null}
                </React.Fragment>
              );
            })
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ContactUsPage;
