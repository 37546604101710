import './ProfilePage.scss';

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import TopHeader from '../../components/TopHeader';
import { logout } from '../login/login.slice';
import ChangePassword from './change-password/ChangePassword';
import PaymentMethodPage from './payment-method/PaymentMethodPage';
import { loadPaymentMethods } from './profile.slice';
import UploadProfilePhoto from './upload-profile-photo/UploadProfilePhoto';

const ProfilePage: React.FC = () => {
  const { profile: profileText } = LanguageTexts;

  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.login);
  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (!infoLoaded) {
      dispatch(loadPaymentMethods());
      setInfoLoaded(true);
    }
  }, [dispatch, infoLoaded]);

  function doLogout() {
    dispatch(logout());
  }

  return (
    <IonPage>
      <TopHeader />
      <IonContent className="main-content profile" fullscreen>
        <div className="flex-column-align-center profile-info mb-2">
          <div className="profile-info-img">
            <img
              src={
                // eslint-disable-next-line no-nested-ternary
                user?.profilePicUrl !== null
                  ? user?.profilePicUrl
                  : user?.gender === 'Male'
                  ? `${process.env.REACT_APP_IMAGE_PATH}website/profile/man.png`
                  : `${process.env.REACT_APP_IMAGE_PATH}website/profile/woman.png`
              }
              alt={user?.name}
            />
          </div>
          <IonText>
            <h2>
              {user?.name ? (
                <>
                  <span className="user-name ml-2">{user?.name}</span>
                </>
              ) : null}
            </h2>
          </IonText>
        </div>
        <IonCard className="mb-2">
          <div className="flex-row-align-center card-title">
            <i className="fas fa-angle-double-right card-header-icon mr" />
            <IonLabel>{profileText.uploadProfilePhoto}</IonLabel>
          </div>

          <IonCardContent className="card-content">
            <UploadProfilePhoto />
          </IonCardContent>
        </IonCard>
        <IonCard className="mb-2">
          <div className="flex-row-align-center card-title">
            <i className="fas fa-angle-double-right card-header-icon mr" />
            <IonLabel>{profileText.changePassword}</IonLabel>
          </div>

          <IonCardContent className="card-content">
            <ChangePassword />
          </IonCardContent>
        </IonCard>
        <IonCard className="mb-2">
          <div className="flex-row-align-center card-title">
            <i className="fas fa-angle-double-right card-header-icon mr" />
            <IonLabel>{profileText.paymentInformation}</IonLabel>
          </div>

          <IonCardContent className="card-content">
            <PaymentMethodPage infoLoaded={infoLoaded} />
          </IonCardContent>
        </IonCard>
        <IonRow>
          <IonCol>
            <div className="text-center">
              <IonButton color="secondary" className="mb-2" onClick={doLogout}>
                <i className="fas fa-sign-out-alt icon-with-text" />{' '}
                {profileText.logoutBtn}
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default ProfilePage;
