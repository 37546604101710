import './ForgotPasswordPage.scss';

import { IonContent, IonPage, IonText } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LanguageTexts from '../../common/language';
import { ForgotPasswordInput, RootState } from '../../common/types';
import { forgotPassword } from './forgot-password.slice';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage: React.FC = () => {
  const { forgotPassword: forgotPasswordText } = LanguageTexts;

  const history = useHistory();
  const dispatch = useDispatch();

  const { errors, success, loading } = useSelector(
    (state: RootState) => state.forgotPassword,
  );

  function onSubmit(data: ForgotPasswordInput) {
    dispatch(forgotPassword(data));
  }

  return (
    <IonPage>
      <IonContent>
        <div
          className="bg-image"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/login/side-image.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <section className="logo-part flex-column-align-center">
            <img
              className="logo"
              src={`${process.env.REACT_APP_IMAGE_PATH}website/logo/main-app-logo.jpg`}
              alt="logo"
            />
          </section>
          <section className="auth-part">
            {success ? (
              <>
                <div className="text-center heading">
                  <IonText color="primary">
                    <h3 className="font-weight-bold">
                      {forgotPasswordText.titleSuccess}
                    </h3>
                  </IonText>
                  <IonText color="secondary">
                    <h5 className="font-weight-bold">
                      {LanguageTexts.forgotPassword.successMsg}
                    </h5>
                  </IonText>
                </div>
              </>
            ) : (
              <>
                <div className="text-center heading">
                  <IonText color="primary">
                    <h3 className="font-weight-bold">
                      {forgotPasswordText.title}
                    </h3>
                  </IonText>
                  <IonText color="secondary">
                    <h5 className="font-weight-bold">
                      {forgotPasswordText.subtitle}
                    </h5>
                  </IonText>
                </div>
                <ForgotPasswordForm
                  onSubmit={onSubmit}
                  errors={errors}
                  loading={loading}
                />
              </>
            )}
            <div className="text-center mb-1">
              <IonText
                color="secondary"
                onClick={() => {
                  history.goBack();
                }}
                className="flex-row-align-center justify-content-center"
              >
                <i className="icon-with-text fas fa-chevron-left" />
                &nbsp;
                {forgotPasswordText.backToLogin}
              </IonText>
            </div>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPasswordPage;
