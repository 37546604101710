import { IsNotEmpty } from 'class-validator';

export default class BankAccountDto {
  @IsNotEmpty()
  name: string | undefined;

  @IsNotEmpty()
  accountNumber: string | undefined;

  @IsNotEmpty()
  routingNumber: string | undefined;
}
