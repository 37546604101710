/* eslint-disable jsx-a11y/label-has-associated-control */
import '../ProfilePage.scss';

import {
  IonCol,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from '@ionic/react';
import { Form, Formik } from 'formik';
import React from 'react';

import { PaymentMethodType } from '../../../common/constants';
import LanguageTexts from '../../../common/language';
import BankAccountPage from '../bank-account/BankAccountPage';
import CreditCardPage from '../credit-card/CreditCardPage';

type PaymentMethodFormProps = {
  initialValues: { paymentMethodType: PaymentMethodType };
  infoLoaded: boolean;
  onCancel: () => void;
};

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({
  initialValues,
  infoLoaded,
  onCancel,
}: PaymentMethodFormProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <div className="payment-card">
            <div className="payment-card-header">
              <Form>
                <IonRadioGroup
                  value={values.paymentMethodType}
                  onIonChange={(e) => {
                    setFieldValue('paymentMethodType', e.detail.value);
                  }}
                >
                  <IonRow>
                    <IonCol>
                      <IonItem lines="none" className="payment-method-type">
                        <IonLabel color="secondary">
                          <b>{profileTxt.creditCard}</b>
                        </IonLabel>
                        <IonRadio
                          slot="start"
                          name="paymentMethodType"
                          value={PaymentMethodType.Card}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem lines="none" className="payment-method-type">
                        <IonLabel color="secondary">
                          <b>{profileTxt.ach}</b>
                        </IonLabel>
                        <IonRadio
                          slot="start"
                          name="paymentMethodType"
                          value={PaymentMethodType.ACH}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol />
                    <IonCol />
                    <IonCol />
                  </IonRow>
                </IonRadioGroup>
              </Form>
            </div>
            <div className="payment-card-body">
              {values.paymentMethodType === PaymentMethodType.Card ? (
                <CreditCardPage infoLoaded={infoLoaded} onCancel={onCancel} />
              ) : null}

              {values.paymentMethodType === PaymentMethodType.ACH ? (
                <BankAccountPage infoLoaded={infoLoaded} onCancel={onCancel} />
              ) : null}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default PaymentMethodForm;
