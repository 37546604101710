import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { Form, Formik } from 'formik';
import { flattenDeep } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedFilter } from '../app/app.slice';
import { UserRole } from '../common/constants';
import LanguageTexts from '../common/language';
import { RootState } from '../common/types';

const FilterBox = (): JSX.Element => {
  const { app: appTxt } = LanguageTexts;

  const batchSelectInputRef = useRef<HTMLIonSelectElement>(null);
  const dispatch = useDispatch();
  const { filter } = useSelector((state: RootState) => state.app);
  const { batches } = useSelector((state: RootState) => state.batches);
  const { user: currentUser } = useSelector((state: RootState) => state.login);

  const initValues = {
    batchId: filter.batchId || '',
    childrenId: filter.childrenId || '',
  };

  const filteredBatches = useMemo(() => {
    let batchIds: string[] = [];

    if (
      currentUser?.role.indexOf(UserRole.Teacher) !== -1 ||
      currentUser?.role.indexOf(UserRole.Student) !== -1
    ) {
      batchIds = currentUser?.batches?.map(({ batch: { _id } }) => _id) || [];
    } else if (currentUser?.role.indexOf(UserRole.Parent) !== -1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const userBatchIds: any =
        currentUser?.children?.map(({ batches: studentBatches }) => {
          return studentBatches?.map(({ batch: { _id } }) => _id) || [];
        }) || [];

      batchIds = flattenDeep(userBatchIds);
      batchIds = batchIds.length === 0 ? [''] : batchIds;
    }

    return batches?.filter(({ _id }) => {
      if (batchIds.length > 0) {
        return batchIds.indexOf(_id) !== -1;
      }

      return true;
    });
  }, [currentUser, batches]);

  const children = currentUser?.children || [];

  function doSubmit(values: { batchId: string; childrenId: string }) {
    if (currentUser?.role.indexOf(UserRole.Student) !== -1) {
      if (values.batchId) {
        dispatch(
          setSelectedFilter({
            role: null,
            centerId: null,
            batchId: values.batchId,
            childrenId: null,
          }),
        );
      }
    } else if (currentUser?.role.indexOf(UserRole.Parent) !== -1) {
      if (values.batchId && values.childrenId) {
        dispatch(
          setSelectedFilter({
            role: null,
            centerId: null,
            batchId: values.batchId,
            childrenId: values.childrenId,
          }),
        );
      }
    }
  }

  return (
    <Formik
      initialValues={initValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        doSubmit(values);
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        const showBatches =
          currentUser?.role.indexOf(UserRole.Student) !== -1 ||
          currentUser?.role.indexOf(UserRole.Parent) !== -1;

        const showChildren = currentUser?.role.indexOf(UserRole.Parent) !== -1;

        let allowedBatchIds: (string | undefined)[] = [];

        if (values.childrenId) {
          const selectedChild = children.find(
            ({ _id }) => _id === values.childrenId,
          );

          allowedBatchIds =
            selectedChild?.batches
              ?.map(({ batch: { _id } = {} }) => _id)
              .filter((id) => id) || [];
        }

        return (
          <Form>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    {showChildren && children ? (
                      <IonItem lines="none">
                        <IonSelect
                          placeholder={appTxt.choose}
                          name="childrenId"
                          value={values.childrenId}
                          interface="action-sheet"
                          onIonChange={(e) => {
                            setFieldValue('batchId', null);
                            setFieldValue('childrenId', e.detail.value);
                            submitForm();

                            if (
                              currentUser?.role.indexOf(UserRole.Parent) !==
                                -1 &&
                              !values.batchId &&
                              e.detail.value &&
                              e.detail.value !== values.childrenId
                            ) {
                              setTimeout(() => {
                                batchSelectInputRef?.current?.open();
                              }, 500);
                            }
                          }}
                        >
                          {children.map(({ _id, name }) => {
                            return (
                              <IonSelectOption key={_id} value={_id}>
                                {name}
                              </IonSelectOption>
                            );
                          })}
                        </IonSelect>
                      </IonItem>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    {showBatches && filteredBatches && batchSelectInputRef ? (
                      <IonItem lines="none">
                        <IonSelect
                          placeholder={appTxt.chooseBatch}
                          name="batchId"
                          value={values.batchId}
                          onIonChange={(e) => {
                            setFieldValue('batchId', e.detail.value);
                            submitForm();
                          }}
                          interface="action-sheet"
                          ref={batchSelectInputRef}
                        >
                          {filteredBatches
                            .filter(({ _id }) => {
                              if (
                                allowedBatchIds &&
                                allowedBatchIds.length > 0
                              ) {
                                return allowedBatchIds.indexOf(_id) !== -1;
                              }

                              return true;
                            })
                            .map(({ _id, name }) => {
                              return (
                                <IonSelectOption key={_id} value={_id}>
                                  {name}
                                </IonSelectOption>
                              );
                            })}
                        </IonSelect>
                      </IonItem>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FilterBox;
