import { IonTitle } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from '../common/constants';
import { RootState } from '../common/types';

const SelectedFilter: React.FC = (): JSX.Element => {
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const {
    filter: {
      role: selectedRole,
      centerId: selectedCenter,
      batchId: selectedBatch,
      childrenId: selectedChildren,
    },
  } = useSelector((state: RootState) => state.app);
  const { centers } = useSelector((state: RootState) => state.centers);
  const { batches } = useSelector((state: RootState) => state.batches);
  const children = currentUser?.children || [];

  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  let filterText = '';

  if (selectedCenter && centers && userRoles.includes(UserRole.CenterHead)) {
    filterText = centers?.find(({ _id }) => _id === selectedCenter)?.name || '';

    if (selectedBatch && batches && userRoles.includes(UserRole.Teacher)) {
      const batchObj = batches?.find(({ _id }) => _id === selectedBatch);

      filterText += ` + ${batchObj?.name || ''}`;
    }
  } else if (
    selectedBatch &&
    batches &&
    (userRoles.includes(UserRole.Teacher) ||
      userRoles.includes(UserRole.Student))
  ) {
    const batchObj = batches?.find(({ _id }) => _id === selectedBatch);

    filterText = `${batchObj?.name || ''}`;
  } else if (
    selectedChildren &&
    children &&
    userRoles.includes(UserRole.Parent)
  ) {
    const childrenObj = children?.find(({ _id }) => _id === selectedChildren);

    filterText = `${childrenObj?.name || ''}`;
  }

  return (
    <IonTitle className="text-center selected-filter-title">
      {filterText}
    </IonTitle>
  );
};

export default SelectedFilter;
