import '../ProfilePage.scss';

import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonModal,
  IonRow,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { BankAccountModel, RootState } from '../../../common/types';
import {
  loadPaymentMethods,
  resetVerifyBankAccount,
  verifyBankAccount,
} from '../profile.slice';
import BankAccountVerifyForm from './BankAccountVerifyForm';

type BankAccountDetailProps = {
  data: BankAccountModel;
  handleSetDefaultPaymentMethod?: (id: string) => void;
  handleDeletePaymentMethod?: (id: string) => void;
};

const BankAccountDetail: React.FC<BankAccountDetailProps> = ({
  data,
  handleSetDefaultPaymentMethod,
  handleDeletePaymentMethod,
}: BankAccountDetailProps): JSX.Element => {
  const { profile: profileTxt, app: appTxt } = LanguageTexts;
  const [showModel, setShowModel] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useDispatch();
  const {
    verifyBankAccount: {
      errors: verifyBankAccountErrors,
      loading: verifyBankAccountLoading,
      success: verifyBankAccountSuccess,
    },
    setDefaultPaymentMethod: { loading: setDefaultPaymentMethodLoading },
    deletePaymentMethod: { loading: deletePaymentMethodLoading },
  } = useSelector((state: RootState) => state.profile);

  function onSubmit(input: {
    amount1: number | undefined;
    amount2: number | undefined;
  }) {
    dispatch(verifyBankAccount({ ...input, bankAccountId: data.id }));
  }

  useEffect(() => {
    if (verifyBankAccountSuccess) {
      dispatch(resetVerifyBankAccount());
      dispatch(loadPaymentMethods());
      setShowModel(false);
    }
  }, [verifyBankAccountSuccess, dispatch]);

  function handleModelClose() {
    setShowModel(false);
  }

  return (
    <div className="payment-card">
      <div className="card-title payment-card-header">
        <IonRow>
          <IonCol>
            <b>{profileTxt.ach}</b>
          </IonCol>
          <IonCol>
            <div className="text-right">
              {data.isDefault ? (
                <small>
                  <b>{profileTxt.default}</b>
                </small>
              ) : null}
            </div>
          </IonCol>
        </IonRow>
      </div>
      <div className="payment-card-body">
        <IonRow>
          <IonCol>
            <h5>
              <b>{profileTxt.accountHolderName}</b>
            </h5>
          </IonCol>
          <IonCol>
            <div className="text-right">
              <h5>{data.name}</h5>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5>
              <b>{profileTxt.bankName}</b>
            </h5>
          </IonCol>
          <IonCol>
            <div className="text-right">
              <h5>{data.bankName}</h5>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5>
              <b>{profileTxt.accountNumber}</b>
            </h5>
          </IonCol>
          <IonCol>
            <div className="text-right">
              <h5>**** {data.accountNumber}</h5>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5>
              <b>{profileTxt.routingNumber}</b>
            </h5>
          </IonCol>
          <IonCol>
            <div className="text-right">
              <h5>{data.routingNumber}</h5>
            </div>
          </IonCol>
        </IonRow>
        <div className="mb-1">
          <IonRow>
            <IonCol>
              <h5>
                <b>{profileTxt.verificationStatus}</b>
              </h5>
            </IonCol>
            {data.status === 'new' ? (
              <IonCol>
                <div className="text-right">
                  <button
                    type="button"
                    className="btn-link"
                    onClick={() => setShowModel(true)}
                  >
                    {profileTxt.pendingVerification}
                  </button>
                </div>
                <IonModal isOpen={showModel} onDidDismiss={handleModelClose}>
                  <IonContent>
                    <div className="modal-content">
                      <IonText color="secondary">
                        <h2 className="text-center">
                          <b>{profileTxt.verifyBankAccount}</b>
                        </h2>
                      </IonText>
                      <IonText color="secondary">
                        <p>
                          <b>{profileTxt.note}: </b>
                          {profileTxt.verifyNote}
                        </p>
                      </IonText>
                      {!verifyBankAccountSuccess ? (
                        <BankAccountVerifyForm
                          errors={verifyBankAccountErrors}
                          loading={verifyBankAccountLoading}
                          onSubmit={onSubmit}
                        />
                      ) : null}
                    </div>
                  </IonContent>
                  <div className="modal-content mb-1">
                    <IonButton expand="block" onClick={handleModelClose}>
                      {profileTxt.close}
                    </IonButton>
                  </div>
                </IonModal>
              </IonCol>
            ) : (
              <>
                <IonCol>
                  <div className="text-right">
                    <h5>{profileTxt.verified}</h5>
                  </div>
                </IonCol>
              </>
            )}
          </IonRow>
        </div>
        <IonRow>
          <IonCol>
            {!data.isDefault && handleSetDefaultPaymentMethod ? (
              <button
                type="button"
                className="default-btn"
                disabled={setDefaultPaymentMethodLoading}
                onClick={() => handleSetDefaultPaymentMethod(data.id)}
              >
                {profileTxt.setDefault}
              </button>
            ) : null}
          </IonCol>
          <IonCol>
            {!data.isDefault && handleDeletePaymentMethod ? (
              <div className="text-right">
                <button
                  type="button"
                  className="delete-btn"
                  disabled={deletePaymentMethodLoading}
                  onClick={() => setShowAlert(true)}
                >
                  {profileTxt.delete}
                </button>
                <IonAlert
                  isOpen={showAlert}
                  onDidDismiss={() => setShowAlert(false)}
                  header={profileTxt.deletePaymentMethod}
                  message={appTxt.confirmText}
                  buttons={[
                    {
                      text: appTxt.no,
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: () => setShowAlert(false),
                    },
                    {
                      text: appTxt.yes,
                      handler: () => {
                        handleDeletePaymentMethod(data.id);
                      },
                    },
                  ]}
                />
              </div>
            ) : null}
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};

BankAccountDetail.defaultProps = {
  handleSetDefaultPaymentMethod: undefined,
  handleDeletePaymentMethod: undefined,
};

export default BankAccountDetail;
