import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonText,
} from '@ionic/react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  AssignmentSubmissionFormat,
  AssignmentSubmissionStatus,
  UserRole,
} from '../../common/constants';
import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { RootState, UserModel } from '../../common/types';
import FileUpload from '../../components/FileUpload';

type AssignmentSubmissionFormData = {
  _id?: string;
  assignmentId: string;
  studentId: string;
  submittedAs: string;
  submissionStatus: string;
  comment: string;
  grade: string;
  assignmentSubmissionFormat: string;
  assignmentUrl: string;
  assignmentFile: File | undefined;
};

type AssignmentSubmissionProps = {
  onSubmit: (data: AssignmentSubmissionFormData) => void;
  loading: boolean;
  errors: string[];
  initialValues: AssignmentSubmissionFormData;
  currentUser: UserModel;
};

const AssignmentSubmissionForm: React.FC<AssignmentSubmissionProps> = ({
  onSubmit,
  loading,
  errors,
  initialValues,
  currentUser,
}: AssignmentSubmissionProps): JSX.Element => {
  const { assignments: assignmentsText } = LanguageTexts;
  const { assignmentSubmission } = useSelector(
    (state: RootState) => state.assignments,
  );

  const [
    selectedAssignmentSubmissionFile,
    setAssignmentSubmissionFile,
  ] = useState<File | null>(null);

  const { ...initVal } = initialValues;

  return (
    <Formik
      initialValues={initVal}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        switch (input.assignmentSubmissionFormat) {
          case AssignmentSubmissionFormat.File:
            if (selectedAssignmentSubmissionFile) {
              input.assignmentFile = selectedAssignmentSubmissionFile;
            }
            input.assignmentUrl = '';
            break;
          case AssignmentSubmissionFormat.Url:
            input.assignmentFile = undefined;
            break;
          default:
            break;
        }

        onSubmit(input);

        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, handleChange, setFieldValue }) => {
        const errorKeys = getLangAndErrKeys({
          ...values,
          assignmentFile: null,
        });
        const formattedErrors = formatErrors(
          errorKeys,
          errors,
          assignmentsText,
        );

        return (
          <Form>
            {currentUser.role.includes(UserRole.Student) &&
            assignmentSubmission?.submissionStatus !==
              AssignmentSubmissionStatus.Submitted &&
            assignmentSubmission?.submissionStatus !==
              AssignmentSubmissionStatus.Resubmitted &&
            assignmentSubmission?.submissionStatus !==
              AssignmentSubmissionStatus.Completed ? (
              <>
                <IonCard className="mb-2">
                  <div className="flex-row-align-center card-title">
                    <i className="fas fa-angle-double-right card-header-icon mr" />
                    <IonLabel>{assignmentsText.uploadFileOrUrl}</IonLabel>
                  </div>
                  <IonCardContent className="card-content">
                    <IonText>
                      <p>{assignmentsText.submissionFormat}</p>
                    </IonText>
                    <IonRadioGroup
                      value={values.assignmentSubmissionFormat}
                      onIonChange={(e) => {
                        setFieldValue(
                          'assignmentSubmissionFormat',
                          e.detail.value,
                        );
                      }}
                    >
                      <IonRow>
                        <IonCol>
                          <IonItem lines="none">
                            <IonLabel>{assignmentsText.labelFile}</IonLabel>
                            <IonRadio
                              slot="start"
                              className="submission-format"
                              name="assignmentSubmissionFormat"
                              value={AssignmentSubmissionFormat.File}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem lines="none">
                            <IonLabel>{assignmentsText.labelUrl}</IonLabel>
                            <IonRadio
                              slot="start"
                              className="submission-format"
                              name="assignmentSubmissionFormat"
                              value={AssignmentSubmissionFormat.Url}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol />
                        <IonCol />
                        <IonCol />
                      </IonRow>
                    </IonRadioGroup>
                    {values.assignmentSubmissionFormat ===
                    AssignmentSubmissionFormat.File ? (
                      <>
                        <FileUpload
                          id="assignment-file"
                          label=""
                          onFileSelect={(file) => {
                            setAssignmentSubmissionFile(file);
                          }}
                          disabled={loading}
                          maxFileSize={1024 * 200}
                        />
                      </>
                    ) : null}
                    {values.assignmentSubmissionFormat ===
                    AssignmentSubmissionFormat.Url ? (
                      <>
                        <IonItem lines="none" className="inputField">
                          <IonInput
                            placeholder="URL"
                            name="assignmentUrl"
                            type="text"
                            className="font-weight-bold"
                            color="secondary"
                            onIonChange={handleChange}
                          />
                        </IonItem>
                      </>
                    ) : null}
                    <div className="mb-1 error-msg">
                      {formattedErrors.map((error) => (
                        <IonText color="secondary">
                          <p style={{ fontWeight: 'bold' }} key={error}>
                            {error}
                          </p>
                        </IonText>
                      ))}
                    </div>
                    <div className="text-right mb-1">
                      <IonButton type="submit" disabled={loading}>
                        {assignmentsText.saveBtn}
                      </IonButton>
                    </div>
                  </IonCardContent>
                </IonCard>
              </>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssignmentSubmissionForm;
