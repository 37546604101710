/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonButton, IonInput, IonItem, IonText } from '@ionic/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorCodes, PublicRoutePath } from '../../common/constants';
import {
  formatErrors,
  getDomain,
  getLangAndErrKeys,
} from '../../common/helper';
import LanguageTexts from '../../common/language';
import { LoginInput } from '../../common/types';

type ILoginProps = {
  onSubmit: (data: LoginInput) => void;
  errors: string[];
  loading: boolean;
};

const LoginForm: React.FC<ILoginProps> = ({
  onSubmit,
  errors,
  loading,
}: ILoginProps): JSX.Element => {
  const { login: loginText } = LanguageTexts;
  const history = useHistory();
  const initialValues: LoginInput = {
    email: '',
    password: '',
  };

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(errorKeys, errors, loginText);
  const domain = getDomain();
  const webUrl = `https://${domain.replace('m.', '')}/login`;

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleChange, values }) => (
          <Form>
            <IonItem lines="none" className="inputField mb-1">
              <IonInput
                placeholder={loginText['input.email']}
                name="email"
                type="text"
                className="font-weight-bold"
                color="secondary"
                value={values.email}
                onIonChange={handleChange}
              />
            </IonItem>
            <IonItem lines="none" className="inputField mb-1">
              <IonInput
                placeholder={loginText['input.password']}
                name="password"
                type="password"
                className="font-weight-bold"
                color="secondary"
                value={values.password}
                onIonChange={handleChange}
              />
            </IonItem>
            <div className="text-center mb-1">
              <IonText
                color="secondary"
                onClick={() => {
                  history.push(PublicRoutePath.ForgotPass);
                }}
              >
                {loginText.forgotPassword}
              </IonText>
            </div>
            <div className="mb-1 error-msg">
              {formattedErrors.map((error) => {
                if (error === ErrorCodes.LoginNotAllowed) {
                  return (
                    <IonText color="secondary">
                      <p
                        style={{ fontWeight: 'bold' }}
                        key={error}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: loginText.loginNotAllowed.replace(
                            '{link}',
                            `<a href="${webUrl}" target="_blank">${loginText.webVersion}</a>`,
                          ),
                        }}
                      />
                    </IonText>
                  );
                }

                return (
                  <IonText color="secondary">
                    <p style={{ fontWeight: 'bold' }} key={error}>
                      {error}
                    </p>
                  </IonText>
                );
              })}
            </div>
            <div className="mb-1">
              <IonButton
                className="mb-2"
                expand="block"
                type="submit"
                disabled={loading}
              >
                {loginText.loginBtn}
              </IonButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

LoginForm.defaultProps = {};

export default LoginForm;
