/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CenterDepsResponse,
  CenterModel,
  CentersState,
  CreateCenterInput,
  GetCentersQuery,
} from '../../common/types';

const initialState: CentersState = {
  loading: false,
  success: null,
  errors: [],
  centers: null,
  addCenter: {
    loading: false,
    success: null,
    errors: [],
  },
  loadCenter: {
    loading: false,
    success: null,
    errors: [],
  },
  cities: null,
  loadCenterDeps: {
    loading: false,
    success: null,
    errors: [],
  },
};

const centersSlice = createSlice({
  name: 'centers',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadCenters(state, action: PayloadAction<GetCentersQuery>) {
      state.loading = true;
      state.errors = [];
    },
    loadCentersSuccess(state, action: PayloadAction<CenterModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.centers = action.payload;
    },
    loadCentersError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadCenters(state) {
      state.loadCenter.success = null;
      state.loadCenter.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addCenter(state, action: PayloadAction<CreateCenterInput>) {
      state.addCenter.loading = true;
      state.addCenter.errors = [];
    },
    addCenterSuccess(state, action: PayloadAction<boolean>) {
      state.addCenter.loading = false;
      state.addCenter.success = action.payload;
    },
    addCenterError(state, action: PayloadAction<string[]>) {
      state.addCenter.loading = false;
      state.addCenter.errors = action.payload;
    },
    resetAddCenterSuccess(state) {
      state.addCenter.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadCenter(state, action: PayloadAction<string>) {
      state.loadCenter.loading = true;
      state.loadCenter.errors = [];
    },
    loadCenterSuccess(state, action: PayloadAction<CenterModel>) {
      state.loadCenter.loading = false;
      state.loadCenter.success = action.payload;
    },
    loadCenterError(state, action: PayloadAction<string[]>) {
      state.loadCenter.loading = false;
      state.loadCenter.errors = action.payload;
    },
    resetLoadCenter(state) {
      state.loadCenter.success = null;
      state.loadCenter.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadCenterDeps(state, action: PayloadAction<undefined>) {
      state.loadCenterDeps.loading = true;
      state.loadCenterDeps.errors = [];
    },
    loadCenterDepsSuccess(state, action: PayloadAction<CenterDepsResponse>) {
      state.loadCenterDeps.loading = false;
      state.loadCenterDeps.success = !!action.payload;
      state.cities = action.payload.cities;
    },
    loadCenterDepsError(state, action: PayloadAction<string[]>) {
      state.loadCenterDeps.loading = false;
      state.loadCenterDeps.errors = action.payload;
    },
    resetLoadCenterDeps(state) {
      state.loadCenterDeps.success = null;
      state.loadCenterDeps.errors = [];
    },
  },
});

export const {
  addCenter,
  addCenterSuccess,
  addCenterError,
  resetAddCenterSuccess,
  loadCenter,
  loadCenterSuccess,
  loadCenterError,
  resetLoadCenter,
  loadCenters,
  loadCentersSuccess,
  loadCentersError,
  resetLoadCenters,
  loadCenterDeps,
  loadCenterDepsSuccess,
  loadCenterDepsError,
  resetLoadCenterDeps,
} = centersSlice.actions;

export const {
  name: centersSliceName,
  reducer: centersSliceReducer,
} = centersSlice;
