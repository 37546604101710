import {
  IonBackButton,
  IonBadge,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppRoutePath, NotificationType, UserRole } from '../common/constants';
import { RootState } from '../common/types';
import { loadNotifications } from '../features/notification/notification.slice';
import SelectedFilter from './SelectedFilter';

type TopHeaderProps = {
  showBackButton?: boolean;
};

const TopHeader: React.FC<TopHeaderProps> = ({
  showBackButton,
}: TopHeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector((state: RootState) => state.login);
  const { notifications } = useSelector(
    (state: RootState) => state.notification,
  );
  const {
    filter: { childrenId: selectedChildren, batchId: selectedBatch },
  } = useSelector((state: RootState) => state.app);
  const [infoLoaded, setInfoLoaded] = useState(false);

  const showNotifications =
    user?.role.includes(UserRole.Student) ||
    user?.role.includes(UserRole.Parent);
  const filteredNotifications = showNotifications
    ? notifications?.filter(({ userId, payload }) => {
        const userCheck = user?.role.includes(UserRole.Parent)
          ? userId === selectedChildren
          : true;

        switch (payload.notificationType) {
          case NotificationType.FeesDue:
            return userCheck && payload.userInvoice.batchId === selectedBatch;
          case NotificationType.BatchScheduleChange:
            return userCheck && payload.batchId === selectedBatch;
          case NotificationType.NewAnnouncement:
            return (
              userCheck &&
              selectedBatch &&
              payload.announcement.batchIds.includes(selectedBatch)
            );
          case NotificationType.NewAssignment:
            return userCheck && payload.assignment.batchId === selectedBatch;
          case NotificationType.AssignmentSubmissionStatusChange:
            return userCheck && payload.assignment.batchId === selectedBatch;
          default:
            return userCheck;
        }
      })
    : [];

  useEffect(() => {
    if (!infoLoaded) {
      if (showNotifications) {
        dispatch(loadNotifications({ readByUser: false }));
      }
      setInfoLoaded(true);
    }
  }, [dispatch, infoLoaded, showNotifications]);

  return (
    <>
      <IonHeader className="toolbar-header">
        <IonToolbar>
          <IonButtons slot="start" className="back-button-android">
            <IonBackButton />
          </IonButtons>
          {showBackButton ? (
            <IonButtons
              onClick={() => {
                history.goBack();
              }}
              className="back-button"
            >
              <IonIcon
                slot="icon-only"
                color="secondary"
                icon={chevronBack}
                className="back-button-icon"
              />
            </IonButtons>
          ) : null}
          <IonButtons
            slot="primary"
            onClick={() => {
              history.push(AppRoutePath.Notification);
            }}
            className="notification-btn primary-buttons"
          >
            <i className="toolbar-icon fas fa-bell" />
            {filteredNotifications && filteredNotifications.length > 0 ? (
              <IonBadge
                slot="primary"
                color="danger"
                className="notification-count-badge"
              >
                {filteredNotifications.length}
              </IonBadge>
            ) : null}
          </IonButtons>
          <IonButtons
            slot="primary"
            className="primary-buttons"
            onClick={() => {
              history.push(AppRoutePath.Profile);
            }}
          >
            <i className="toolbar-icon fas fa-user-alt" />
          </IonButtons>
          <SelectedFilter />
        </IonToolbar>
      </IonHeader>
    </>
  );
};

TopHeader.defaultProps = {
  showBackButton: true,
};

export default TopHeader;
