/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AnnouncementDepsResponse,
  AnnouncementModel,
  AnnouncementsState,
  CreateAnnouncementInput,
  GetAnnouncementDepsQuery,
  GetAnnouncementsQuery,
} from '../../common/types';

const initialState: AnnouncementsState = {
  loading: false,
  success: null,
  errors: [],
  announcements: null,
  addAnnouncement: {
    loading: false,
    success: null,
    errors: [],
  },
  loadAnnouncement: {
    loading: false,
    success: null,
    errors: [],
  },
  loadAnnouncementDeps: {
    loading: false,
    success: null,
    errors: [],
  },
  batches: null,
  centers: null,
  students: null,
};

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadAnnouncements(state, action: PayloadAction<GetAnnouncementsQuery>) {
      state.loading = true;
      state.errors = [];
    },
    loadAnnouncementsSuccess(
      state,
      action: PayloadAction<AnnouncementModel[]>,
    ) {
      state.loading = false;
      state.success = !!action.payload;
      state.announcements = action.payload;
    },
    loadAnnouncementsError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadAnnouncements(state) {
      state.loadAnnouncement.success = null;
      state.loadAnnouncement.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addAnnouncement(state, action: PayloadAction<CreateAnnouncementInput>) {
      state.addAnnouncement.loading = true;
      state.addAnnouncement.errors = [];
    },
    addAnnouncementSuccess(state, action: PayloadAction<boolean>) {
      state.addAnnouncement.loading = false;
      state.addAnnouncement.success = action.payload;
    },
    addAnnouncementError(state, action: PayloadAction<string[]>) {
      state.addAnnouncement.loading = false;
      state.addAnnouncement.errors = action.payload;
    },
    resetAddAnnouncementSuccess(state) {
      state.addAnnouncement.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadAnnouncement(state, action: PayloadAction<string>) {
      state.loadAnnouncement.loading = true;
      state.loadAnnouncement.errors = [];
    },
    loadAnnouncementSuccess(state, action: PayloadAction<AnnouncementModel>) {
      state.loadAnnouncement.loading = false;
      state.loadAnnouncement.success = action.payload;
    },
    loadAnnouncementError(state, action: PayloadAction<string[]>) {
      state.loadAnnouncement.loading = false;
      state.loadAnnouncement.errors = action.payload;
    },
    resetLoadAnnouncement(state) {
      state.loadAnnouncement.success = null;
      state.loadAnnouncement.errors = [];
    },
    loadAnnouncementDeps(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetAnnouncementDepsQuery>,
    ) {
      state.loadAnnouncementDeps.loading = true;
      state.loadAnnouncementDeps.errors = [];
    },
    loadAnnouncementDepsSuccess(
      state,
      action: PayloadAction<AnnouncementDepsResponse>,
    ) {
      state.loadAnnouncementDeps.loading = false;
      state.loadAnnouncementDeps.success = !!action.payload;
      state.centers = action.payload.centers;
      state.batches = action.payload.batches;
      state.students = action.payload.students;
    },
    loadAnnouncementDepsError(state, action: PayloadAction<string[]>) {
      state.loadAnnouncementDeps.loading = false;
      state.loadAnnouncementDeps.errors = action.payload;
    },
    resetLoadAnnouncementDeps(state) {
      state.loadAnnouncementDeps.success = null;
      state.loadAnnouncementDeps.errors = [];
    },
  },
});

export const {
  loadAnnouncement,
  loadAnnouncementError,
  loadAnnouncementSuccess,
  resetLoadAnnouncement,
  loadAnnouncements,
  loadAnnouncementsError,
  loadAnnouncementsSuccess,
  resetLoadAnnouncements,
  addAnnouncement,
  addAnnouncementSuccess,
  addAnnouncementError,
  resetAddAnnouncementSuccess,
  loadAnnouncementDeps,
  loadAnnouncementDepsSuccess,
  loadAnnouncementDepsError,
  resetLoadAnnouncementDeps,
} = announcementsSlice.actions;

export const {
  name: announcementsSliceName,
  reducer: announcementsSliceReducer,
} = announcementsSlice;
