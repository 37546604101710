/* eslint-disable no-nested-ternary */
import './NotificationPage.scss';

import { IonCard, IonCardContent, IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationType, UserRole } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import Heading from '../../components/Heading';
import TopHeader from '../../components/TopHeader';
import { loadNotifications } from './notification.slice';
import NotificationList from './NotificationList';

const NotificationPage: React.FC = () => {
  const { app: appTxt, notification: notificationTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.login);
  const { notifications, loading } = useSelector(
    (state: RootState) => state.notification,
  );
  const {
    filter: { childrenId: selectedChildren, batchId: selectedBatch },
  } = useSelector((state: RootState) => state.app);
  const [infoLoaded, setInfoLoaded] = useState(false);

  const showNotifications =
    user?.role.includes(UserRole.Student) ||
    user?.role.includes(UserRole.Parent);
  const filteredNotifications = showNotifications
    ? notifications?.filter(({ userId, payload }) => {
        const userCheck = user?.role.includes(UserRole.Parent)
          ? userId === selectedChildren
          : true;

        switch (payload.notificationType) {
          case NotificationType.FeesDue:
            return userCheck && payload.userInvoice.batchId === selectedBatch;
          case NotificationType.BatchScheduleChange:
            return userCheck && payload.batchId === selectedBatch;
          case NotificationType.NewAnnouncement:
            return (
              userCheck &&
              selectedBatch &&
              payload.announcement.batchIds.includes(selectedBatch)
            );
          case NotificationType.NewAssignment:
            return userCheck && payload.assignment.batchId === selectedBatch;
          case NotificationType.AssignmentSubmissionStatusChange:
            return userCheck && payload.assignment.batchId === selectedBatch;
          default:
            return userCheck;
        }
      })
    : [];

  useEffect(() => {
    if (!infoLoaded) {
      if (showNotifications) {
        dispatch(loadNotifications({ readByUser: false }));
      }
      setInfoLoaded(true);
    }
  }, [dispatch, infoLoaded, showNotifications]);

  return (
    <IonPage>
      <TopHeader />
      <IonContent className="main-content" fullscreen>
        <Heading title={notificationTxt.heading} iconName="fas fa-bell" />
        {loading || !infoLoaded ? (
          <AppLoader />
        ) : filteredNotifications && filteredNotifications.length > 0 ? (
          <NotificationList notifications={filteredNotifications || []} />
        ) : (
          <IonCard className="mb-2">
            <IonCardContent className="card-content">
              <p>{appTxt.noRecords}</p>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default NotificationPage;
