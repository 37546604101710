/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonItem, IonText } from '@ionic/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import LanguageTexts from '../common/language';

type FileUploadProps = {
  id: string;
  label: string;
  onFileSelect: (image: File | null) => void;
  onRemoveUrl?: undefined | (() => void);
  url?: string | null;
  required?: boolean;
  maxFileSize?: number | null;
  disabled?: boolean;
};

const FileUpload: React.FC<FileUploadProps> = ({
  id,
  label,
  onFileSelect,
  onRemoveUrl,
  url,
  required,
  maxFileSize,
  disabled,
}: FileUploadProps): JSX.Element => {
  const [previewFile, setPreviewFile] = useState<File | null>(null);
  const [maxSizeErr, setMaxSizeErr] = useState<string | null>(null);

  function removePreviewFile(e: React.MouseEvent) {
    e.preventDefault();
    if (disabled) return;
    setPreviewFile(null);
    onFileSelect(null);
  }

  function removeFileUrl(e: React.MouseEvent) {
    e.preventDefault();
    if (disabled) return;
    if (onRemoveUrl) {
      onRemoveUrl();
    }
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { files },
    } = e;

    if (files && files.length > 0) {
      setMaxSizeErr(null);

      const file = files[0];
      const sizeInKB = Math.round(file.size / 1024);

      if (maxFileSize && sizeInKB > maxFileSize) {
        setMaxSizeErr(
          LanguageTexts.app.maxSizeErr.replace(
            '{size}',
            `${(maxFileSize / 1024).toFixed(2)}MB`,
          ),
        );
        return;
      }

      setPreviewFile(file);
      onFileSelect(file);
      e.target.value = '';
    }
  }

  return (
    <>
      {previewFile || url ? (
        <IonText className="flex-row-align-center justify-content-center">
          <IonText>{previewFile ? previewFile.name : ''}</IonText>
          {previewFile ? (
            <IonText style={{ marginLeft: '5px' }} onClick={removePreviewFile}>
              <i className="fas fa-times-circle file-close-icon" />
            </IonText>
          ) : null}
          {!previewFile && url ? (
            <Link
              to={url}
              title="View"
              target="_blank"
              className="font-weight-bold"
            >
              View <i className="fas fa-download" />
            </Link>
          ) : null}
          {onRemoveUrl && !previewFile && url ? (
            <IonText style={{ marginLeft: '5px' }} onClick={removeFileUrl}>
              <i className="fas fa-times-circle file-close-icon" />
            </IonText>
          ) : null}
        </IonText>
      ) : null}

      <div>
        <span
          className={maxSizeErr ? '' : 'text-secondary small'}
          style={maxSizeErr ? { color: 'red' } : {}}
        >
          {label}
          {required ? <span style={{ color: 'red' }}>*</span> : null}
        </span>
        {maxSizeErr ? <p>{maxSizeErr}</p> : null}
        <IonItem lines="none" className="inputField">
          <input
            type="file"
            className="upload-file"
            id={id}
            aria-describedby="inputGroupFileAddon04"
            onChange={onFileChange}
            disabled={disabled}
          />
        </IonItem>
        <div className="text-right">
          {maxFileSize ? (
            <IonText color="primary">
              {LanguageTexts.app.maxSize} {(maxFileSize / 1024).toFixed(2)} MB
            </IonText>
          ) : null}
        </div>
      </div>
    </>
  );
};

FileUpload.defaultProps = {
  url: null,
  maxFileSize: null,
  required: false,
  disabled: false,
  onRemoveUrl: undefined,
};

export default FileUpload;
