/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  BankAccountInput,
  BankAccountVerifyInput,
  ChangePasswordInput,
  SetDefaultPaymentMethodInput,
  UploadProfilePhotoInput,
  UserCreditCardInput,
} from '../../common/types';
import ApiService from '../../services/api';
import { whoami } from '../login/login.slice';
import {
  cancelInstituteSubscription,
  cancelInstituteSubscriptionError,
  cancelInstituteSubscriptionSuccess,
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  deletePaymentMethod,
  deletePaymentMethodError,
  deletePaymentMethodSuccess,
  loadBankAccountInfo,
  loadBankAccountInfoError,
  loadBankAccountInfoSuccess,
  loadPaymentMethods,
  loadPaymentMethodsError,
  loadPaymentMethodsSuccess,
  loadProfileInfo,
  loadProfileInfoError,
  loadProfileInfoSuccess,
  removeProfilePhoto,
  removeProfilePhotoError,
  removeProfilePhotoSuccess,
  renewInstituteSubscription,
  renewInstituteSubscriptionError,
  renewInstituteSubscriptionSuccess,
  saveBankAccount,
  saveBankAccountError,
  saveBankAccountSuccess,
  saveUserCreditCard,
  saveUserCreditCardError,
  saveUserCreditCardSuccess,
  setDefaultPaymentMethod,
  setDefaultPaymentMethodError,
  setDefaultPaymentMethodSuccess,
  uploadProfilePhoto,
  uploadProfilePhotoError,
  uploadProfilePhotoSuccess,
  verifyBankAccount,
  verifyBankAccountError,
  verifyBankAccountSuccess,
} from './profile.slice';

export function* uploadProfilePhotoAsync(
  action: PayloadAction<UploadProfilePhotoInput>,
) {
  const { data, errors } = yield call(
    ApiService.uploadProfilePhoto,
    action.payload,
  );

  if (errors) {
    yield put(uploadProfilePhotoError(errors));
  } else {
    yield put(uploadProfilePhotoSuccess(data));
  }
}

export function* removeProfilePhotoAsync(action: PayloadAction<string>) {
  const { data, errors } = yield call(
    ApiService.removeProfilePhoto,
    action.payload,
  );

  if (errors) {
    yield put(removeProfilePhotoError(errors));
  } else {
    yield put(removeProfilePhotoSuccess({ success: data, id: action.payload }));
  }
}

export function* changePasswordAsync(
  action: PayloadAction<ChangePasswordInput>,
) {
  const { data, errors } = yield call(
    ApiService.changePassword,
    action.payload,
  );

  if (errors) {
    yield put(changePasswordError(errors));
  } else {
    yield put(changePasswordSuccess(data));
  }
}

export function* saveUserCreditCardAsync(
  action: PayloadAction<UserCreditCardInput>,
) {
  const { data, errors } = yield call(
    ApiService.saveUserCreditCard,
    action.payload,
  );

  if (errors) {
    yield put(saveUserCreditCardError(errors));
  } else {
    yield put(saveUserCreditCardSuccess(data));
  }
}

export function* cancelInstituteSubscriptionAsync() {
  const { data, errors } = yield call(ApiService.cancelInstituteSubscription);

  if (errors) {
    yield put(cancelInstituteSubscriptionError(errors));
  } else {
    yield put(cancelInstituteSubscriptionSuccess(data));
    yield put(whoami());
  }
}

export function* renewInstituteSubscriptionAsync() {
  const { data, errors } = yield call(ApiService.renewInstituteSubscription);

  if (errors) {
    yield put(renewInstituteSubscriptionError(errors));
  } else {
    yield put(renewInstituteSubscriptionSuccess(data));
    yield put(whoami());
  }
}

export function* loadProfileInfoAsync() {
  const { data, errors } = yield call(ApiService.loadProfileInfo);

  if (errors) {
    yield put(loadProfileInfoError(errors));
  } else {
    yield put(loadProfileInfoSuccess(data));
  }
}

export function* saveBankAccountAsync(action: PayloadAction<BankAccountInput>) {
  const { data, errors } = yield call(
    ApiService.saveBankAccount,
    action.payload,
  );

  if (errors) {
    yield put(saveBankAccountError(errors));
  } else {
    yield put(saveBankAccountSuccess(data));
  }
}

export function* loadBankAccountInfoAsync() {
  const { data, errors } = yield call(ApiService.loadBankAccountInfo);

  if (errors) {
    yield put(loadBankAccountInfoError(errors));
  } else {
    yield put(loadBankAccountInfoSuccess(data));
  }
}

export function* loadPaymentMethodsAsync() {
  const { data, errors } = yield call(ApiService.loadPaymentMethods);

  if (errors) {
    yield put(loadPaymentMethodsError(errors));
  } else {
    yield put(loadPaymentMethodsSuccess(data));
  }
}

export function* verifyBankAccountAsync(
  action: PayloadAction<BankAccountVerifyInput>,
) {
  const { data, errors } = yield call(
    ApiService.verifyBankAccount,
    action.payload,
  );

  if (errors) {
    yield put(verifyBankAccountError(errors));
  } else {
    yield put(verifyBankAccountSuccess(data));
  }
}

export function* setDefaultPaymentMethodAsync(
  action: PayloadAction<SetDefaultPaymentMethodInput>,
) {
  const { data, errors } = yield call(
    ApiService.setDefaultPaymentMethod,
    action.payload,
  );

  if (errors) {
    yield put(setDefaultPaymentMethodError(errors));
  } else {
    yield put(setDefaultPaymentMethodSuccess(data));
  }
}

export function* deletePaymentMethodAsync(
  action: PayloadAction<SetDefaultPaymentMethodInput>,
) {
  const { data, errors } = yield call(
    ApiService.deletePaymentMethod,
    action.payload,
  );

  if (errors) {
    yield put(deletePaymentMethodError(errors));
  } else {
    yield put(deletePaymentMethodSuccess(data));
  }
}

export function* watchProfileAsync() {
  yield takeEvery(uploadProfilePhoto.toString(), uploadProfilePhotoAsync);
  yield takeEvery(removeProfilePhoto.toString(), removeProfilePhotoAsync);
  yield takeEvery(changePassword.toString(), changePasswordAsync);
  yield takeEvery(saveUserCreditCard.toString(), saveUserCreditCardAsync);
  yield takeEvery(
    cancelInstituteSubscription.toString(),
    cancelInstituteSubscriptionAsync,
  );
  yield takeEvery(
    renewInstituteSubscription.toString(),
    renewInstituteSubscriptionAsync,
  );
  yield takeEvery(loadProfileInfo.toString(), loadProfileInfoAsync);
  yield takeEvery(saveBankAccount.toString(), saveBankAccountAsync);
  yield takeEvery(loadBankAccountInfo.toString(), loadBankAccountInfoAsync);
  yield takeEvery(loadPaymentMethods.toString(), loadPaymentMethodsAsync);
  yield takeEvery(verifyBankAccount.toString(), verifyBankAccountAsync);
  yield takeEvery(
    setDefaultPaymentMethod.toString(),
    setDefaultPaymentMethodAsync,
  );
  yield takeEvery(deletePaymentMethod.toString(), deletePaymentMethodAsync);
}
