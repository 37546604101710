/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonCol, IonImg, IonItem, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';

import LanguageTexts from '../common/language';

type ImageUploadProps = {
  id: string;
  label: string;
  onFileSelect: (image: File | null) => void;
  onRemoveUrl?: undefined | (() => void);
  url?: string | null;
  required?: boolean;
  maxFileSize?: number | null;
  fileResolution?: string | null;
  disabled?: boolean;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  id,
  label,
  onFileSelect,
  onRemoveUrl,
  url,
  required,
  maxFileSize,
  fileResolution,
  disabled,
}: ImageUploadProps): JSX.Element => {
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [maxSizeErr, setMaxSizeErr] = useState<string | null>(null);

  function removePreviewImage(e: React.MouseEvent) {
    e.preventDefault();
    if (disabled) return;
    setPreviewImage(null);
    onFileSelect(null);
  }

  function removeImageUrl(e: React.MouseEvent) {
    e.preventDefault();
    if (disabled) return;
    if (onRemoveUrl) {
      onRemoveUrl();
    }
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { files },
    } = e;

    if (files && files.length > 0) {
      setMaxSizeErr(null);

      const file = files[0];
      const sizeInKB = Math.round(file.size / 1024);

      if (maxFileSize && sizeInKB > maxFileSize) {
        setMaxSizeErr(
          LanguageTexts.app.maxSizeErr.replace(
            '{size}',
            `${(maxFileSize / 1024).toFixed(2)}MB`,
          ),
        );
        return;
      }

      const reader = new FileReader();

      reader.onload = function onLoad() {
        setPreviewImage(reader.result ? reader.result.toString() : null);
        onFileSelect(file);
      };

      reader.readAsDataURL(file);
      e.target.value = '';
    }
  }

  return (
    <>
      {previewImage || url ? (
        <div className="user-profile-img mb-1">
          <IonImg
            src={previewImage || url || ''}
            className="profile-img"
            alt=""
          />
          {previewImage ? (
            <IonText className="profile-img-close" onClick={removePreviewImage}>
              <i className="fas fa-times-circle image-close-icon" />
            </IonText>
          ) : null}
          {onRemoveUrl && !previewImage && url ? (
            <IonText className="profile-img-close" onClick={removeImageUrl}>
              <i className="fas fa-times-circle image-close-icon" />
            </IonText>
          ) : null}
        </div>
      ) : null}

      <div>
        <div>
          <label
            className={maxSizeErr ? '' : 'text-secondary small'}
            style={maxSizeErr ? { color: 'red' } : {}}
          >
            {label}
            {required ? '*' : null}
          </label>
        </div>
        {maxSizeErr ? <p>{maxSizeErr}</p> : null}
        <IonItem lines="none" className="inputField">
          <input
            type="file"
            accept="image/*"
            className="custom-file-input"
            id={id}
            aria-describedby="inputGroupFileAddon04"
            onChange={onFileChange}
            disabled={disabled}
          />
        </IonItem>
        <IonRow>
          <IonCol>
            {fileResolution ? (
              <IonText color="primary">
                {LanguageTexts.app.resolution} {fileResolution}
              </IonText>
            ) : null}
          </IonCol>
          <IonCol className="text-right">
            {maxFileSize ? (
              <IonText color="primary">
                {LanguageTexts.app.maxSize} {(maxFileSize / 1024).toFixed(2)} MB
              </IonText>
            ) : null}
          </IonCol>
        </IonRow>
      </div>
    </>
  );
};

ImageUpload.defaultProps = {
  url: null,
  maxFileSize: null,
  fileResolution: null,
  required: false,
  disabled: false,
  onRemoveUrl: undefined,
};

export default ImageUpload;
