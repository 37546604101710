/* eslint-disable no-nested-ternary */
import './AnnouncementPage.scss';

import {
  IonCard,
  IonCardContent,
  IonContent,
  IonLabel,
  IonPage,
  IonText,
} from '@ionic/react';
import matchSorter from 'match-sorter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import Heading from '../../components/Heading';
import TopHeader from '../../components/TopHeader';
import { loadAnnouncements } from './announcements.slice';

const AnnouncementPage: React.FC = () => {
  const { announcements: announcementsTxt, app: appTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { announcements, loading } = useSelector(
    (state: RootState) => state.announcements,
  );
  const {
    filter: {
      centerId: selectedCenter,
      batchId: selectedBatch,
      childrenId: selectedChildren,
    },
  } = useSelector((state: RootState) => state.app);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);

  const sortBy = 'createdAt';
  const latest = 0;

  const data = React.useMemo(() => {
    const sortedAnnouncement = matchSorter(announcements || [], '', {
      keys: [sortBy || ''],
    }).reverse();

    const filteredAnnouncement = sortedAnnouncement?.filter(
      ({ userIds, batches }) => {
        const aCenterIds = batches.map(({ centerId }) => {
          return centerId;
        });
        const aBatchIds = batches.map(({ _id }) => {
          return _id;
        });

        let centerCheck = true;
        let batchCheck = true;
        let childrenCheck = true;

        if (selectedChildren) {
          childrenCheck =
            userIds && userIds.length > 0
              ? userIds.includes(selectedChildren)
              : true;
        }

        if (selectedCenter) {
          centerCheck = aCenterIds.includes(selectedCenter);

          if (!selectedBatch) {
            return centerCheck;
          }
        }

        if (selectedBatch) {
          batchCheck = aBatchIds.includes(selectedBatch);

          if (selectedChildren) {
            return batchCheck && childrenCheck;
          }

          if (!selectedCenter) {
            return batchCheck;
          }
        }

        return centerCheck || batchCheck;
      },
    );

    const announcementData =
      latest && latest > 0
        ? filteredAnnouncement.slice(0, latest)
        : filteredAnnouncement;

    return announcementData.map(
      ({ users, batches, createdAt, ...restAnnouncement }) => {
        const centerTxt = batches
          .map(({ center }) => {
            return center?.name || '';
          })
          .join(', ');
        const batchTxt = batches
          .map(({ name, class: classObj }) => {
            return `${classObj?.name || ''} - ${name}`;
          })
          .join(', ');
        const studentTxt =
          users && users.length > 0
            ? users
                .map(({ name }) => {
                  return name;
                })
                .join(', ')
            : announcementsTxt.allStudents;
        const dateTxt = moment(createdAt).format('L');

        return {
          ...restAnnouncement,
          centerTxt,
          batchTxt,
          studentTxt,
          dateTxt,
        };
      },
    );
  }, [
    announcements,
    announcementsTxt,
    selectedBatch,
    selectedCenter,
    selectedChildren,
    latest,
    sortBy,
  ]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadAnnouncements({}));
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  return (
    <IonPage>
      <TopHeader />

      <IonContent className="main-content" fullscreen>
        <Heading title={announcementsTxt.heading} iconName="fas fa-bullhorn" />

        {loading || !loadRequested ? (
          <AppLoader />
        ) : data.length === 0 ? (
          <IonCard className="mb-2">
            <IonCardContent className="card-content">
              <p>{appTxt.noRecords}</p>
            </IonCardContent>
          </IonCard>
        ) : (
          data.map(({ _id, heading, description }) => {
            return (
              <IonCard key={_id} className="mb-2">
                <div className="flex-row-align-center card-title">
                  <i className="fas fa-angle-double-right card-header-icon mr" />
                  <IonLabel>{heading}</IonLabel>
                </div>

                <IonCardContent className="card-content">
                  <IonText>
                    <p>{description}</p>
                  </IonText>
                </IonCardContent>
              </IonCard>
            );
          })
        )}
      </IonContent>
    </IonPage>
  );
};

export default AnnouncementPage;
