/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  CreateAnnouncementInput,
  GetAnnouncementDepsQuery,
  GetAnnouncementsQuery,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  addAnnouncement,
  addAnnouncementError,
  addAnnouncementSuccess,
  loadAnnouncement,
  loadAnnouncementDeps,
  loadAnnouncementDepsError,
  loadAnnouncementDepsSuccess,
  loadAnnouncementError,
  loadAnnouncements,
  loadAnnouncementsError,
  loadAnnouncementsSuccess,
  loadAnnouncementSuccess,
} from './announcements.slice';

export function* addAnnouncementAsync(
  action: PayloadAction<CreateAnnouncementInput>,
) {
  const { data, errors } = yield call(
    ApiService.addAnnouncement,
    action.payload,
  );

  if (errors) {
    yield put(addAnnouncementError(errors));
  } else {
    yield put(addAnnouncementSuccess(!!data));
  }
}

export function* loadAnnouncementAsync(action: PayloadAction<string>) {
  const { data } = yield call(
    ApiService.loadAnnouncementFormDetails,
    action.payload,
  );

  if (!data) {
    yield put(loadAnnouncementError(['404']));
  } else {
    yield put(loadAnnouncementSuccess(data));
  }
}

export function* loadAnnouncementsAsync(
  action: PayloadAction<GetAnnouncementsQuery>,
) {
  const { data } = yield call(
    ApiService.getAnnouncementsForTable,
    action.payload,
  );

  if (!data) {
    yield put(loadAnnouncementsError(['500']));
  } else {
    yield put(loadAnnouncementsSuccess(data));
  }
}

export function* loadAnnouncementDepsAsync(
  action: PayloadAction<GetAnnouncementDepsQuery>,
) {
  const { data } = yield call(ApiService.getAnnouncementDeps, action.payload);

  if (!data) {
    yield put(loadAnnouncementDepsError(['500']));
  } else {
    yield put(loadAnnouncementDepsSuccess(data));
  }
}

export function* watchAnnouncementsAsync() {
  yield takeEvery(addAnnouncement.toString(), addAnnouncementAsync);
  yield takeEvery(loadAnnouncement.toString(), loadAnnouncementAsync);
  yield takeEvery(loadAnnouncements.toString(), loadAnnouncementsAsync);
  yield takeEvery(loadAnnouncementDeps.toString(), loadAnnouncementDepsAsync);
}
