import './ClassSchedulePage.scss';

import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

import LanguageTexts from '../../common/language';
import Heading from '../../components/Heading';
import TopHeader from '../../components/TopHeader';
import BatchSchedule from './BatchSchedule';

const ClassSchedulePage: React.FC = () => {
  const { classSchedule: classScheduleText } = LanguageTexts;

  return (
    <IonPage>
      <TopHeader />
      <IonContent className="main-content" fullscreen>
        <Heading
          title={classScheduleText.heading}
          iconName="fas fa-calendar-alt"
        />
        <BatchSchedule />
      </IonContent>
    </IonPage>
  );
};

export default ClassSchedulePage;
