import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentMethodType } from '../../../common/constants';
import { PaymentMethodModel, RootState } from '../../../common/types';
import BankAccountDetail from '../bank-account/BankAccountDetail';
import CreditCardDetail from '../credit-card/CreditCardDetail';
import {
  deletePaymentMethod,
  loadPaymentMethods,
  resetDeletePaymentMethod,
  resetSetDefaultPaymentMethod,
  setDefaultPaymentMethod,
} from '../profile.slice';

type PaymentMethodListProps = {
  paymentMethods: PaymentMethodModel[];
  onDefaultSuccess: (success: boolean) => void;
  onDeleteSuccess: (success: boolean) => void;
};

const PaymentMethodList: React.FC<PaymentMethodListProps> = ({
  paymentMethods,
  onDefaultSuccess,
  onDeleteSuccess,
}: PaymentMethodListProps): JSX.Element => {
  const dispatch = useDispatch();

  const {
    setDefaultPaymentMethod: {
      errors: setDefaultPaymentMethodErrors,
      success: setDefaultPaymentMethodSuccess,
    },
    deletePaymentMethod: {
      errors: deletePaymentMethodErrors,
      success: deletePaymentMethodSuccess,
    },
  } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (setDefaultPaymentMethodSuccess) {
      dispatch(resetSetDefaultPaymentMethod());
      dispatch(loadPaymentMethods());
      onDefaultSuccess(true);
    } else if (
      setDefaultPaymentMethodErrors &&
      setDefaultPaymentMethodErrors.length > 0
    ) {
      dispatch(resetSetDefaultPaymentMethod());
    }
  }, [
    setDefaultPaymentMethodSuccess,
    setDefaultPaymentMethodErrors,
    dispatch,
    onDefaultSuccess,
  ]);

  useEffect(() => {
    if (deletePaymentMethodSuccess) {
      dispatch(resetDeletePaymentMethod());
      dispatch(loadPaymentMethods());
      onDeleteSuccess(true);
    } else if (
      deletePaymentMethodErrors &&
      deletePaymentMethodErrors.length > 0
    ) {
      dispatch(resetDeletePaymentMethod());
    }
  }, [
    deletePaymentMethodSuccess,
    deletePaymentMethodErrors,
    dispatch,
    onDeleteSuccess,
  ]);

  function handleSetDefaultPaymentMethod(paymentMethodSourceId: string) {
    dispatch(setDefaultPaymentMethod({ paymentMethodSourceId }));
  }

  function handleDeletePaymentMethod(paymentMethodSourceId: string) {
    dispatch(deletePaymentMethod({ paymentMethodSourceId }));
  }

  return (
    <>
      <div style={{ clear: 'both' }} />
      {paymentMethods.map((pMethod) => {
        switch (pMethod.type) {
          case PaymentMethodType.ACH:
            return (
              <BankAccountDetail
                key={pMethod.id}
                data={pMethod}
                handleSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
                handleDeletePaymentMethod={handleDeletePaymentMethod}
              />
            );
          case PaymentMethodType.Card:
            return (
              <CreditCardDetail
                key={pMethod.id}
                data={pMethod}
                handleSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
                handleDeletePaymentMethod={handleDeletePaymentMethod}
              />
            );
          default:
            return null;
        }
      })}
      <div style={{ clear: 'both' }} />
    </>
  );
};

export default PaymentMethodList;
