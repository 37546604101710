/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  CreateAssignmentInput,
  CreateAssignmentSubmissionInput,
  GetAssignmentDepsQuery,
  GetAssignmentListDepsQuery,
  GetAssignmentsQuery,
  GetAssignmentSubmissionQuery,
  GetAssignmentSubmissionsQuery,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  addAssignment,
  addAssignmentError,
  addAssignmentSubmission,
  addAssignmentSubmissionError,
  addAssignmentSubmissionSuccess,
  addAssignmentSuccess,
  loadAssignment,
  loadAssignmentDeps,
  loadAssignmentDepsError,
  loadAssignmentDepsSuccess,
  loadAssignmentError,
  loadAssignmentListDeps,
  loadAssignmentListDepsError,
  loadAssignmentListDepsSuccess,
  loadAssignments,
  loadAssignmentsError,
  loadAssignmentsSuccess,
  loadAssignmentSubmission,
  loadAssignmentSubmissionError,
  loadAssignmentSubmissions,
  loadAssignmentSubmissionsError,
  loadAssignmentSubmissionsSuccess,
  loadAssignmentSubmissionSuccess,
  loadAssignmentSuccess,
} from './assignments.slice';

export function* addAssignmentAsync(
  action: PayloadAction<CreateAssignmentInput>,
) {
  const { data, errors } = yield call(ApiService.addAssignment, action.payload);

  if (errors) {
    yield put(addAssignmentError(errors));
  } else {
    yield put(addAssignmentSuccess(!!data));
  }
}

export function* loadAssignmentAsync(action: PayloadAction<string>) {
  const { data } = yield call(
    ApiService.loadAssignmentFormDetails,
    action.payload,
  );

  if (!data) {
    yield put(loadAssignmentError(['404']));
  } else {
    yield put(loadAssignmentSuccess(data));
  }
}

export function* loadAssignmentsAsync(
  action: PayloadAction<GetAssignmentsQuery>,
) {
  const { data } = yield call(
    ApiService.getAssignmentsForTable,
    action.payload,
  );

  if (!data) {
    yield put(loadAssignmentsError(['500']));
  } else {
    yield put(loadAssignmentsSuccess(data));
  }
}

export function* loadAssignmentDepsAsync(
  action: PayloadAction<GetAssignmentDepsQuery>,
) {
  const { data } = yield call(ApiService.getAssignmentDeps, action.payload);

  if (!data) {
    yield put(loadAssignmentDepsError(['500']));
  } else {
    yield put(loadAssignmentDepsSuccess(data));
  }
}

export function* loadAssignmentListDepsAsync(
  action: PayloadAction<GetAssignmentListDepsQuery>,
) {
  const { data } = yield call(ApiService.getAssignmentListDeps, action.payload);

  if (!data) {
    yield put(loadAssignmentListDepsError(['500']));
  } else {
    yield put(loadAssignmentListDepsSuccess(data));
  }
}

export function* addAssignmentSubmissionAsync(
  action: PayloadAction<CreateAssignmentSubmissionInput>,
) {
  const { data, errors } = yield call(
    ApiService.addAssignmentSubmission,
    action.payload,
  );

  if (errors) {
    yield put(addAssignmentSubmissionError(errors));
  } else {
    yield put(addAssignmentSubmissionSuccess(!!data));
  }
}

export function* loadAssignmentSubmissionAsync(
  action: PayloadAction<GetAssignmentSubmissionQuery>,
) {
  const { data } = yield call(
    ApiService.getAssignmentSubmission,
    action.payload,
  );

  if (!data) {
    yield put(loadAssignmentSubmissionError(['500']));
  } else {
    yield put(loadAssignmentSubmissionSuccess(data));
  }
}

export function* loadAssignmentSubmissionsAsync(
  action: PayloadAction<GetAssignmentSubmissionsQuery>,
) {
  const { data } = yield call(
    ApiService.getAssignmentSubmissions,
    action.payload,
  );

  if (!data) {
    yield put(loadAssignmentSubmissionsError(['500']));
  } else {
    yield put(loadAssignmentSubmissionsSuccess(data));
  }
}

export function* watchAssignmentsAsync() {
  yield takeEvery(addAssignment.toString(), addAssignmentAsync);
  yield takeEvery(loadAssignment.toString(), loadAssignmentAsync);
  yield takeEvery(loadAssignments.toString(), loadAssignmentsAsync);
  yield takeEvery(loadAssignmentDeps.toString(), loadAssignmentDepsAsync);
  yield takeEvery(
    loadAssignmentListDeps.toString(),
    loadAssignmentListDepsAsync,
  );
  yield takeEvery(
    addAssignmentSubmission.toString(),
    addAssignmentSubmissionAsync,
  );
  yield takeEvery(
    loadAssignmentSubmission.toString(),
    loadAssignmentSubmissionAsync,
  );
  yield takeEvery(
    loadAssignmentSubmissions.toString(),
    loadAssignmentSubmissionsAsync,
  );
}
