import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState, UploadProfilePhotoInput } from '../../../common/types';
import { whoami } from '../../login/login.slice';
import {
  removeProfilePhoto,
  resetProfilePhotoSuccess,
  resetRemoveProfilePhoto,
  uploadProfilePhoto,
} from '../profile.slice';
import UploadProfilePhotoForm from './UploadProfilePhotoForm';

const UploadProfilePhoto: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    showProfilePic,
    uploadProfilePic: { loading, success },
    removeProfilePhoto: {
      loading: removeProfilePhotoLoading,
      success: removeProfilePhotoSuccess,
    },
  } = useSelector((state: RootState) => state.profile);

  function onSubmit(data: UploadProfilePhotoInput) {
    dispatch(uploadProfilePhoto(data));
  }

  function onRemoveProfilePhoto(id: string) {
    dispatch(removeProfilePhoto(id));
  }

  useEffect(() => {
    if (success) {
      dispatch(resetProfilePhotoSuccess());
      dispatch(whoami());
    }
  }, [success, dispatch, history]);

  useEffect(() => {
    if (removeProfilePhotoSuccess) {
      dispatch(resetRemoveProfilePhoto());
      dispatch(whoami());
    }
  }, [removeProfilePhotoSuccess, dispatch]);

  const uploadProfilePhotoInit = showProfilePic
    ? cloneDeep(showProfilePic)
    : {};

  return (
    <>
      {!success && !removeProfilePhotoSuccess ? (
        <UploadProfilePhotoForm
          onSubmit={onSubmit}
          loading={loading || removeProfilePhotoLoading}
          initialValues={uploadProfilePhotoInit}
          onRemoveProfilePhoto={onRemoveProfilePhoto}
        />
      ) : null}
    </>
  );
};

export default UploadProfilePhoto;
