import '../ProfilePage.scss';

import { IonAlert, IonCol, IonRow } from '@ionic/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { CreditCardModel, RootState } from '../../../common/types';

type CreditCardDetailProps = {
  data: CreditCardModel;
  handleSetDefaultPaymentMethod?: (id: string) => void;
  handleDeletePaymentMethod?: (id: string) => void;
};

const CreditCardDetail: React.FC<CreditCardDetailProps> = ({
  data,
  handleSetDefaultPaymentMethod,
  handleDeletePaymentMethod,
}: CreditCardDetailProps): JSX.Element => {
  const { profile: profileTxt, app: appTxt } = LanguageTexts;
  const [showAlert, setShowAlert] = useState(false);
  const {
    setDefaultPaymentMethod: { loading: setDefaultPaymentMethodLoading },
    deletePaymentMethod: { loading: deletePaymentMethodLoading },
  } = useSelector((state: RootState) => state.profile);

  return (
    <div className="payment-card">
      <div className="card-title payment-card-header">
        <IonRow>
          <IonCol>
            <b>{profileTxt.creditCard}</b>
          </IonCol>
          <IonCol>
            <div className="text-right">
              {data.isDefault ? (
                <small>
                  <b>{profileTxt.default}</b>
                </small>
              ) : null}
            </div>
          </IonCol>
        </IonRow>
      </div>
      <div className="payment-card-body">
        <IonRow>
          <IonCol>
            <h5>
              <b>{profileTxt.cardType}</b>
            </h5>
          </IonCol>
          <IonCol>
            <div className="text-right">
              <h5>{data.brand}</h5>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5>
              <b>{profileTxt.cardNumber}</b>
            </h5>
          </IonCol>
          <IonCol>
            <div className="text-right">
              <h5>**** {data.last4}</h5>
            </div>
          </IonCol>
        </IonRow>
        <div className="mb-1">
          <IonRow>
            <IonCol>
              <h5>
                <b>{profileTxt.cardExpiry}</b>
              </h5>
            </IonCol>
            <IonCol>
              <div className="text-right">
                <h5>
                  {data.expMonth}/{data.expYear}
                </h5>
              </div>
            </IonCol>
          </IonRow>
        </div>
        <IonRow>
          <IonCol>
            {!data.isDefault && handleSetDefaultPaymentMethod ? (
              <button
                type="button"
                className="default-btn"
                disabled={setDefaultPaymentMethodLoading}
                onClick={() => handleSetDefaultPaymentMethod(data.id)}
              >
                {profileTxt.setDefault}
              </button>
            ) : null}
          </IonCol>
          <IonCol>
            {!data.isDefault && handleDeletePaymentMethod ? (
              <div className="text-right">
                <button
                  type="button"
                  className="delete-btn"
                  disabled={deletePaymentMethodLoading}
                  onClick={() => setShowAlert(true)}
                >
                  {profileTxt.delete}
                </button>
                <IonAlert
                  isOpen={showAlert}
                  onDidDismiss={() => setShowAlert(false)}
                  header={profileTxt.deletePaymentMethod}
                  message={appTxt.confirmText}
                  buttons={[
                    {
                      text: appTxt.no,
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: () => setShowAlert(false),
                    },
                    {
                      text: appTxt.yes,
                      handler: () => {
                        handleDeletePaymentMethod(data.id);
                      },
                    },
                  ]}
                />
              </div>
            ) : null}
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};

CreditCardDetail.defaultProps = {
  handleSetDefaultPaymentMethod: undefined,
  handleDeletePaymentMethod: undefined,
};

export default CreditCardDetail;
