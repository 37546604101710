export type ILanguageTexts = {
  app: Record<string, string>;
  login: Record<string, string>;
  forgotPassword: Record<string, string>;
  dashboard: Record<string, string>;
  announcements: Record<string, string>;
  assignments: Record<string, string>;
  profile: Record<string, string>;
  contact: Record<string, string>;
  notification: Record<string, string>;
  classSchedule: Record<string, string>;
  auth: Record<string, string>;
};

const LanguageTexts: ILanguageTexts = {
  app: {
    impeccable: 'Impeccable',
    tracker: 'Tracker',
    loading: 'Loading...',
    chooseBatch: 'Choose Batch',
    choose: 'Choose',
    noRecords: 'No records found!',
    maxSize: 'Max. Size ',
    each: 'Each',
    resolution: 'Resolution - ',
    resolutionSize: '500 x 300',
    maxSizeErr: 'Max. allowed image size is {size}',
    mandatoryNote: '* Fields are mandatory',
    invalidCard: 'Please provide valid card details.',
    invalidDate: '$property must be a valid date',
    confirmText: 'Are you sure to do this?',
    yes: 'Yes',
    no: 'No',
    chooseRole: 'Choose Role',
    chooseCenter: 'Choose Center',
    btnSubmit: 'Submit',
    notFound: 'Not Found',
    noData: 'No Data Found',
    InvalidValue: '$property contains invalid value',
  },
  login: {
    'input.email': 'Email/Username',
    'input.password': 'Password',
    title: 'Welcome!',
    subtitle: 'Login to continue',
    forgotPassword: 'Forgot Password?',
    loginBtn: 'LOGIN',
    privacyPolicyText: 'Privacy Policy',
    terms: 'Terms of Use',
    loginNotAllowed:
      'Currently mobile version only supports student & parent login. please use {link}.',
    webVersion: 'web version',
  },
  forgotPassword: {
    'input.email': 'Email/Username',
    title: 'Forgot Password?',
    subtitle: 'Password will send to you at your email address',
    submitBtn: 'SUBMIT',
    backToLogin: 'Back To Login',
    titleSuccess: 'Success!',
    successMsg:
      "New password is emailed to your account. If you don't see it in your inbox it might be in your spam folder.",
  },
  dashboard: {
    section1: 'Announcements',
    section2: 'Assignments',
    section3: 'Class Schedule',
    section4: 'Contact Us',
  },
  announcements: {
    heading: 'Announcements',
  },
  assignments: {
    'input.assignmentSubmissionFormat': 'Assignment Submission Format',
    'input.assignmentUrl': 'URL',
    'input.assignmentFile': 'File',
    heading: 'Assignments',
    dueDate: 'Due Date : ',
    statusText: 'Status : ',
    pending: 'Pending',
    reSubmit: 'Resubmit',
    reSubmitted: 'Resubmitted',
    submitted: 'Submitted',
    completed: 'Completed',
    homework: 'Homework',
    teacherName: 'Teacher Name : ',
    description: 'Description : ',
    remark: 'Remark',
    remarkDate: 'Remark Date :',
    remarkComment: 'Remark Comment : ',
    view: 'View',
    by: 'By',
    submissionFileOrUrl: 'Submission File/URL :',
    submissionDate: 'Submission Date :',
    fileOrUrl: 'File/URL : ',
    uploadFileOrUrl: 'Upload File/URL',
    submissionFormat: 'Assignment Submission Format',
    labelFile: 'File',
    labelUrl: 'URL',
    saveBtn: 'Save',
    grade: 'Grade :',
  },
  profile: {
    heading: 'My Profile',
    uploadProfilePhoto: 'Upload Profile Photo',
    resolutionSize: '500 x 500',
    uploadBtn: 'Upload',
    changePassword: 'Change Password',
    changePassBtn: 'Change',
    paymentInformation: 'Payment Information',
    saveCardBtn: 'Save Card',
    logoutBtn: 'LOGOUT',
    'input.profileImage': 'Upload Profile Photo',
    'input.newPassword': 'New Password',
    'input.oldPassword': 'Old Password',
    'input.updateCard': 'Update Card Details',
    'input.autoPayment': 'This card will be saved for autopayments.',
    'input.name': 'Account Holder Name',
    'input.accountNumber': 'Account number',
    'input.routingNumber': 'Routing number',
    'input.amount1': 'Amount 1',
    'input.amount2': 'Amount 2',
    accountHolderName: 'Account Holder Name',
    bankName: 'Bank Name',
    accountNumber: 'Account Number',
    routingNumber: 'Routing Number',
    uploadProfilePhotoBtn: 'Upload',
    newPassword: 'New Password',
    oldPassword: 'Old Password',
    changePasswordBtn: 'Change Password',
    autoSaveCard: 'This card will be saved for autopayments.',
    updateCardBtn: 'Save Card',
    btnCancel: 'Cancel',
    payAndSaveCardBtn: 'Save Card & Pay',
    cardDetails: 'Card Details',
    cardType: 'Card Type',
    cardNumber: 'Card Number',
    cardExpiry: 'Card Expiry',
    btnEditCard: 'Change Card',
    passwordSuccess: 'Your password changed successfully.',
    btnEdit: 'Edit',
    btnSave: 'Save',
    btnVerify: 'Verify',
    btnAddPaymentMethod: 'Add Payment Method',
    renewSubscriptionMsg1: 'Renew Subscription',
    renewSubscriptionBtn: 'Renew Subscription',
    verificationStatus: 'Status',
    creditCard: 'Credit Card',
    ach: 'ACH',
    paymentMethod: 'Payment Method',
    pendingVerification: 'Pending Verification',
    note: 'NOTE',
    verifyNote: `We will send two different deposits, each under $1, to your
    account. These deposits take 1-2 business days to appear on
    your statement. The statement has a description that includes
    AMTS followed by this two amounts. You will need to enter
    these amounts to verify your account. On submit this amounts
    will be deducted from your bank account.`,
    setDefault: 'Set as Default',
    close: 'Close',
    verifyBankAccount: 'Verify Bank Account',
    verified: 'Verified',
    setDefaultPaymentMethodSuccess:
      'Default payment method changed successfully.',
    default: 'Default',
    amountOne: 'Amount 1',
    amountTwo: 'Amount 2',
    delete: 'Delete',
    deletePaymentMethod: 'Delete Payment method',
    deletePaymentMethodSuccess: 'Payment method deleted successfully.',
  },
  contact: {
    heading: 'Contact Us',
    centerHead: 'Center Head',
    teacher: 'Teacher',
  },
  notification: {
    heading: 'Notifications',
    feesDue:
      'Fees due is {currencySymbol}{amountDue}. Kindly pay before due date.',
    batchScheduleChange: 'Batch schedule has been changed.',
    newAssignment: 'New assignment posted.',
    assignmentStatusChange: 'Assignment status changed to {status}.',
  },
  classSchedule: {
    heading: 'Class Schedule',
    scheduleHeading: 'Schedule Details ',
    dateText: 'Date - ',
    center: 'Center',
    class: 'Class',
    timing: 'Timing',
    closeModalBtn: 'Close',
    to: 'to',
  },
  auth: {
    noaccess: 'You do not have access to this page!',
    renewtoaccess: 'Please renew your subscription to access this page!',
  },
};

export default LanguageTexts;
