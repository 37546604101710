/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { GetNotificationsQuery, NotificationModel } from '../../common/types';
import ApiService from '../../services/api';
import {
  loadNotifications,
  loadNotificationsError,
  loadNotificationsSuccess,
  markAsRead,
  markAsReadError,
  markAsReadSuccess,
} from './notification.slice';

export function* loadNotificationsAsync(
  action: PayloadAction<GetNotificationsQuery>,
) {
  const { data, errors } = yield call(
    ApiService.getNotifications,
    action.payload,
  );

  if (errors) {
    yield put(loadNotificationsError(errors));
  } else {
    yield put(loadNotificationsSuccess(data));
  }
}

export function* markAsReadAsync(action: PayloadAction<NotificationModel>) {
  const { errors } = yield call(ApiService.markAsRead, {
    id: action.payload._id,
  });

  if (errors) {
    yield put(markAsReadError(errors));
  } else {
    yield put(markAsReadSuccess(action.payload));
  }
}

export function* watchNotificationAsync() {
  yield takeLatest(loadNotifications.toString(), loadNotificationsAsync);
  yield takeLatest(markAsRead.toString(), markAsReadAsync);
}
