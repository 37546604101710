import { IonCard, IonCardContent, IonLabel, IonText } from '@ionic/react';
import matchSorter from 'match-sorter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  AppRoutePath,
  AssignmentSubmissionStatus,
  UserRole,
} from '../../common/constants';
import { setTimeToZero } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { GetAssignmentSubmissionsQuery, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import {
  loadAssignmentListDeps,
  loadAssignments,
  loadAssignmentSubmissions,
  resetLoadAssignmentDeps,
} from './assignments.slice';

type AssignmentListProps = {
  latest?: number;
  sortBy?: string;
};

const AssignmentList: React.FC<AssignmentListProps> = ({
  latest,
  sortBy,
}: AssignmentListProps): JSX.Element => {
  const { assignments: assignmentsText, app: appTxt } = LanguageTexts;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    assignments,
    loading,
    loadAssignmentListDeps: {
      loading: loadAssignmentListDepsLoading,
      errors: loadAssignmentListDepsErrors,
      success: loadAssignmentListDepsSuccess,
    },
    loadAssignmentSubmissions: { loading: loadAssignmentSubmissionsLoading },
    students: allStudents,
    assignmentSubmissions,
  } = useSelector((state: RootState) => state.assignments);
  const {
    filter: {
      centerId: selectedCenter,
      batchId: selectedBatch,
      childrenId: selectedChildren,
    },
  } = useSelector((state: RootState) => state.app);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);
  const [
    assignmentDepsLoadRequested,
    setAssignmentDepsRequested,
  ] = useState<boolean>();

  // filter data
  const data = React.useMemo(() => {
    const sortedAssignment = matchSorter(assignments || [], '', {
      keys: [sortBy || ''],
    }).reverse();

    // filter students by selected filter/role
    const allFilteredStudents =
      allStudents?.filter(({ _id, batches: sBatches }) => {
        const aCenterIds = sBatches?.map(({ batch }) => batch.centerId) || [];
        const aBatchIds = sBatches?.map(({ batchId }) => batchId) || [];

        let centerCheck = true;
        let batchCheck = true;
        let childrenCheck = true;

        if (currentUser?.role.includes(UserRole.Student)) {
          return currentUser._id === _id;
        }

        if (selectedChildren) {
          childrenCheck = !!(_id === selectedChildren);

          return childrenCheck;
        }

        if (selectedCenter) {
          centerCheck = aCenterIds.includes(selectedCenter);

          if (!selectedBatch) {
            return centerCheck;
          }
        }

        if (selectedBatch) {
          batchCheck = aBatchIds.includes(selectedBatch);

          if (!selectedCenter) {
            return batchCheck;
          }
        }

        return centerCheck || batchCheck;
      }) || [];

    // filter assignment
    const filteredAssignment = sortedAssignment
      // map assignment to populate student data
      ?.map((assignment) => {
        const { students, studentsExcluded } = assignment;

        // get students for given assignment
        const filteredStudent =
          allFilteredStudents?.filter(({ _id, batches }) => {
            const batchCheck =
              !!batches?.find(
                ({ batchId }) => batchId === assignment.batchId,
              ) || false;

            if (students.length === 0) {
              return batchCheck && !studentsExcluded.includes(_id);
            }

            return batchCheck && !!students.includes(_id);
          }) || [];

        return {
          ...assignment,
          filteredStudent,
        };
      })
      // filter assignment for selected filters
      .filter(({ filteredStudent, batch }) => {
        const aCenterIds = [batch.center?._id];
        const aBatchIds = [batch._id];

        let centerCheck = true;
        let batchCheck = true;
        let childrenCheck = true;

        if (selectedChildren) {
          childrenCheck = !!filteredStudent.find(
            ({ _id }) => _id === selectedChildren,
          );
        }

        if (selectedCenter) {
          centerCheck = aCenterIds.includes(selectedCenter);

          if (!selectedBatch) {
            return centerCheck;
          }
        }

        if (selectedBatch) {
          batchCheck = aBatchIds.includes(selectedBatch);

          if (selectedChildren) {
            return batchCheck && childrenCheck;
          }

          if (!selectedCenter) {
            return batchCheck;
          }
        }

        return centerCheck || batchCheck;
      });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredAssignmentByStudent: any[] = [];

    // iterate assignment to flatten by student
    filteredAssignment.forEach((assignment) => {
      assignment.filteredStudent.forEach((student) => {
        filteredAssignmentByStudent.push({
          ...assignment,
          student,
        });
      });
    });

    const assignmentData =
      latest && latest > 0
        ? filteredAssignmentByStudent.slice(0, latest)
        : filteredAssignmentByStudent;

    return assignmentData.map(({ assignmentDueDate, ...restAssignment }) => {
      const dueDateTxt = moment(setTimeToZero(assignmentDueDate)).format('L');
      const assignmentSubmission = assignmentSubmissions?.find(
        ({ studentId, assignmentId }) =>
          studentId === restAssignment.student._id &&
          assignmentId === restAssignment._id,
      );
      const submissionStatusTxt =
        assignmentSubmission?.submissionStatus ||
        AssignmentSubmissionStatus.Pending;

      return {
        ...restAssignment,
        dueDateTxt,
        submissionStatusTxt,
      };
    });
  }, [
    assignments,
    selectedBatch,
    selectedCenter,
    selectedChildren,
    allStudents,
    latest,
    sortBy,
    currentUser,
    assignmentSubmissions,
  ]);

  // load assignments
  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadAssignments({}));
      const query: GetAssignmentSubmissionsQuery = {};

      if (currentUser?.role.includes(UserRole.Student)) {
        query.studentId = currentUser._id;
      } else if (currentUser?.role.includes(UserRole.Parent)) {
        query.studentId = selectedChildren || undefined;
      }

      dispatch(loadAssignmentSubmissions(query));
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, selectedChildren, dispatch]);

  // load assignment deps
  useEffect(() => {
    if (!assignmentDepsLoadRequested) {
      dispatch(
        loadAssignmentListDeps({
          domain: currentUser?.domain || '',
          role: [UserRole.Student],
        }),
      );
      setAssignmentDepsRequested(true);
    }
  }, [currentUser, assignmentDepsLoadRequested, selectedBatch, dispatch]);

  useEffect(() => {
    if (loadAssignmentListDepsErrors.length > 0) {
      dispatch(resetLoadAssignmentDeps());
    } else if (loadAssignmentListDepsSuccess) {
      dispatch(resetLoadAssignmentDeps());
    }
  }, [loadAssignmentListDepsSuccess, loadAssignmentListDepsErrors, dispatch]);

  if (
    loading ||
    !loadRequested ||
    !assignmentDepsLoadRequested ||
    loadAssignmentListDepsLoading ||
    loadAssignmentSubmissionsLoading
  ) {
    return <AppLoader />;
  }

  return (
    <>
      {data.length === 0 ? (
        <IonCard className="mb-2">
          <IonCardContent className="card-content">
            <p>{appTxt.noRecords}</p>
          </IonCardContent>
        </IonCard>
      ) : (
        data.map((record) => {
          return (
            <IonCard key={record._id} className="mb-2">
              <div className="flex-row-align-center card-title">
                <i className="fas fa-angle-double-right card-header-icon mr" />
                <IonLabel>{record.createdByUser.name}</IonLabel>
              </div>

              <IonCardContent className="card-content">
                <IonText>
                  <p>
                    <b>{assignmentsText.dueDate}</b> {record.dueDateTxt}
                  </p>
                </IonText>
                <IonText>
                  <p>{record.description}</p>
                </IonText>
              </IonCardContent>
              <div className="card-footer">
                <IonText>
                  <p>
                    {assignmentsText.statusText}&nbsp;
                    <button
                      type="button"
                      className="btn-link"
                      onClick={() => {
                        history.push(
                          AppRoutePath.AssignmentsSubmit.replace(
                            ':assignmentId',
                            record._id,
                          ).replace(':studentId', record.student._id),
                        );
                      }}
                    >
                      {record.submissionStatusTxt}
                    </button>
                  </p>
                </IonText>
              </div>
            </IonCard>
          );
        })
      )}
    </>
  );
};

AssignmentList.defaultProps = {
  sortBy: 'createdAt',
  latest: 0,
};

export default AssignmentList;
