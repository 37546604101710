/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Plugins } from '@capacitor/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { FILTER_KEY } from '../common/constants';
import { SelectedFilterInput } from '../common/types';
import {
  resetSelectedFilter,
  resetSelectedFilterSuccess,
  setSelectedFilter,
  setSelectedFilterSuccess,
} from './app.slice';

const storage = Plugins.Storage;

export function* setSelectedFilterAsync(
  action: PayloadAction<SelectedFilterInput>,
) {
  yield call([storage, storage.set], {
    key: FILTER_KEY,
    value: JSON.stringify(action.payload) || '',
  });
  yield put(setSelectedFilterSuccess(action.payload));
}

export function* resetSelectedFilterAsync() {
  yield call([storage, storage.remove], { key: FILTER_KEY });
  yield put(resetSelectedFilterSuccess());
}

export function* watchAppAsync() {
  yield takeLatest(setSelectedFilter.toString(), setSelectedFilterAsync);
  yield takeLatest(resetSelectedFilter.toString(), resetSelectedFilterAsync);
}
