/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, call } from 'redux-saga/effects';

import { watchAnnouncementsAsync } from '../features/announcement/announcements.saga';
import { watchAssignmentsAsync } from '../features/assignment/assignments.saga';
import { watchBatchesAsync } from '../features/batches/batches.saga';
import { watchContactUsAsync } from '../features/contact-us/contact-us.saga';
import { watchForgotPasswordAsync } from '../features/forgot-password/forgot-password.saga';
import { watchCentersAsync } from '../features/institute-centers/institute-centers.saga';
import { watchItSettingAsync } from '../features/it-setting/it-setting.saga';
import { watchLoginAsync } from '../features/login/login.saga';
import { watchNotificationAsync } from '../features/notification/notification.saga';
import { watchProfileAsync } from '../features/profile/profile.saga';
import { watchAppAsync } from './app.saga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoginAsync),
    call(watchForgotPasswordAsync),
    call(watchItSettingAsync),
    call(watchProfileAsync),
    call(watchContactUsAsync),
    call(watchAppAsync),
    call(watchAnnouncementsAsync),
    call(watchAssignmentsAsync),
    call(watchNotificationAsync),
    call(watchCentersAsync),
    call(watchBatchesAsync),
  ]);
}
