import {
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonText,
} from '@ionic/react';
import { shareOutline } from 'ionicons/icons';
import React from 'react';

const IOSPromptContent: React.FC = (): JSX.Element => {
  return (
    <IonContent className="main-content" fullscreen>
      <h1
        style={{ margin: '5px' }}
        className="flex-row-align-center font-weight-bold mb-2"
      >
        <IonText color="secondary">Install Impeccable Tracker</IonText>
      </h1>
      <IonCard className="mb-2">
        <IonCardContent className="card-content">
          <p style={{ margin: '5px' }}>
            Install this application on your home screen for quick and easy
            access when you&apos;re on the go.
          </p>
          <p style={{ margin: '5px' }}>
            Just tap <IonIcon icon={shareOutline} size="large" />
            &nbsp; then &apos;Add to Home Screen&apos;
          </p>
        </IonCardContent>
      </IonCard>
    </IonContent>
  );
};

export default IOSPromptContent;
