import { gql } from '@apollo/client';

export const SIGNUP = gql`
  mutation signup($input: SignupInput!) {
    signup(input: $input) {
      _id
    }
  }
`;

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      role
      status
      accessToken
      domain
    }
  }
`;

export const CHECK_DOMAIN_AVAILIBILITY = gql`
  mutation checkDomainAvailibility($input: DomainAvailibilityCheckInput!) {
    checkDomainAvailibility(input: $input)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

export const WHOAMI = gql`
  query whoAmI {
    whoAmI {
      _id
      email
      role
      status
      name
      gender
      domain
      phone
      profilePicUrl
      instituteId
      institute {
        status
      }
      batches {
        batch {
          _id
          name
          center {
            _id
            name
            phone
            email
          }
        }
      }
      centerIds
      children {
        _id
        name
        batches {
          batch {
            _id
            name
            center {
              _id
              name
              phone
              email
            }
          }
        }
      }
    }
  }
`;

export const IT_SETTING = gql`
  query itSetting {
    navMenu: itSetting(type: "navMenu")
    routePermissions: itSetting(type: "routePermissions")
    stripe: itSetting(type: "stripe")
    reservedSubdomain: itSetting(type: "reservedSubdomain")
    subscriptionPlanFirst: subscriptionPlanFirst {
      _id
      name
      pricePerActiveStudent
    }
  }
`;

export const LOAD_INSTITUTE_SETUP_INFO = gql`
  query loadInstituteSetupInfo {
    instituteBasicInfo: instituteInfo {
      instituteTypeId
      email
      name
      phone
      address {
        address1
        address2
        zipcode
        cityId
        stateId
        countryId
      }
      socialMedia {
        youtube
        facebook
        instagram
      }
      logoUrl
    }
    instituteWebsiteInfo: instituteWebsiteInfo {
      heading
      description
      aboutImageUrl
      testimonial {
        _id
        commenterName
        commenterPicUrl
        commenterContact
        commenterDescription
        comments
      }
    }
    instituteCourses: instituteCourses {
      _id
      name
      description
      imageUrl
    }
    instituteWebsiteMedia: instituteWebsiteInfo {
      media {
        _id
        mediaType
        mediaUrl
      }
    }
    instituteTypes: instituteTypes {
      _id
      name
      imageUrl
    }
    websiteDefaultMainImage: instituteWebsiteInfo {
      defaultMainImageUrl
    }
    institutePaymentInfo: institutePaymentInfo {
      name
      bankName
      dob
      accountNumber
      routingNumber
    }
    cities: cities {
      _id
      cityName
      state {
        _id
        stateName
      }
      country {
        _id
        name
      }
    }
    instituteAddress: instituteInfo {
      address {
        country {
          currencyCode
          countryCode
        }
      }
    }
  }
`;

export const SAVE_INSTITUTE_BASIC_INFO = gql`
  mutation updateInstituteBasicInfo($input: UpdateInstituteBasicInfoInput!) {
    updateInstituteBasicInfo(input: $input) {
      instituteTypeId
      email
      name
      phone
      address {
        address1
        address2
        zipcode
        cityId
        stateId
        countryId
        country {
          currencyCode
          countryCode
        }
      }
      socialMedia {
        youtube
        facebook
        instagram
      }
      logoUrl
    }
  }
`;

export const SAVE_INSTITUTE_WEBSITE_INFO = gql`
  mutation updateInstituteWebsiteInfo(
    $input: UpdateInstituteWebsiteInfoInput!
  ) {
    updateInstituteWebsiteInfo(input: $input) {
      heading
      description
      aboutImageUrl
      testimonial {
        _id
        commenterPicUrl
        commenterName
        commenterContact
        commenterDescription
        comments
      }
    }
  }
`;

export const SAVE_INSTITUTE_COURSES = gql`
  mutation saveInstituteCourses($input: [InstituteCourseInput!]!) {
    saveInstituteCourses(input: $input) {
      _id
      name
      description
      imageUrl
    }
  }
`;

export const SAVE_INSTITUTE_WEBSITE_MEDIA = gql`
  mutation saveInstituteWebsiteMedia(
    $input: [SaveInstituteWebsiteMediaInput!]!
    $defaultMainImage: String
  ) {
    saveInstituteWebsiteMedia(
      input: $input
      defaultMainImage: $defaultMainImage
    ) {
      media {
        _id
        mediaType
        mediaUrl
      }
      defaultMainImageUrl
    }
  }
`;

export const ADD_PRE_REGISTRATION_INFO = gql`
  mutation addPreRegistrationInfo($input: PreRegistrationInfoInput!) {
    addPreRegistrationInfo(input: $input) {
      email
    }
  }
`;

export const LOAD_INSTITUTE_WEBSITE_DETAILS = gql`
  query loadInstituteWebsiteDetails($domain: String!) {
    instituteWebsite: instituteWebsiteInfoByDomain(domain: $domain) {
      _id
      instituteId
      heading
      description
      testimonial {
        _id
        commenterName
        commenterPicUrl
        commenterContact
        commenterDescription
        comments
      }
      media {
        _id
        mediaType
        mediaUrl
      }
      aboutImageUrl
      defaultMainImageUrl
    }
  }
`;

export const LOAD_INSTITUTE_WEBSITE_BASIC_INFO_DETAILS = gql`
  query loadInstituteWebsiteBasicInfoDetails($domain: String!) {
    instituteWebsiteBasicInfo: instituteInfoByDomain(domain: $domain) {
      _id
      instituteTypeId
      instituteType {
        name
      }
      domain
      email
      name
      phone
      address {
        address1
        address2
        zipcode
        city {
          cityName
        }
        state {
          stateName
        }
        country {
          name
        }
      }
      socialMedia {
        youtube
        facebook
        instagram
      }
      logoUrl
    }
  }
`;

export const LOAD_INSTITUTE_WEBSITE_CENTER_DETAILS = gql`
  query loadInstituteWebsiteCenterDetails($query: GetCentersQuery) {
    instituteWebsiteCenterInfo: getCenters(query: $query) {
      _id
      instituteId
      name
      phone
      email
      address {
        address1
        address2
        zipcode
        city {
          cityName
        }
        state {
          stateName
        }
        country {
          name
        }
      }
    }
  }
`;

export const LOAD_INSTITUTE_WEBSITE_COURSE_DETAILS = gql`
  query loadInstituteWebsiteCourseDetails($domain: String!) {
    instituteWebsiteCourseInfo: instituteCoursesByDomain(domain: $domain) {
      _id
      instituteId
      name
      description
      imageUrl
    }
  }
`;

export const LOAD_INSTITUTE_WEBSITE_CLASS_DETAILS = gql`
  query loadInstituteWebsiteClassDetails($query: GetClassesQuery) {
    instituteWebsiteClassInfo: getClasses(query: $query) {
      _id
      instituteId
      courseId
      name
      description
      imageUrl
    }
  }
`;

export const LOAD_INSTITUTE_WEBSITE_TEACHER_DETAILS = gql`
  query loadInstituteWebsiteTeacherDetails($query: GetUsersQuery) {
    instituteWebsiteTeacherInfo: getUsers(query: $query) {
      _id
      name
      email
      phone
      role
      gender
      description
      profilePicUrl
    }
  }
`;

export const ADD_CENTER = gql`
  mutation addCenter($input: CreateCenterInput!) {
    addCenter(input: $input) {
      _id
      instituteId
      name
      phone
      email
      address {
        address1
        address2
        zipcode
        cityId
        stateId
        countryId
      }
    }
  }
`;

export const GET_CENTER_FORM_DETAILS = gql`
  query getCenter($id: String!) {
    getCenter(id: $id) {
      _id
      name
      phone
      email
      address {
        address1
        address2
        zipcode
        cityId
        stateId
        countryId
      }
    }
  }
`;

export const GET_CENTERS_FOR_TABLE = gql`
  query getCentersForTable($query: GetCentersQuery) {
    getCenters(query: $query) {
      _id
      instituteId
      name
      phone
      email
      address {
        address1
        address2
        zipcode
        city {
          cityName
        }
      }
      centerHead {
        _id
        name
      }
    }
  }
`;

export const ADD_CLASS = gql`
  mutation addClass($input: CreateClassInput!) {
    addClass(input: $input) {
      _id
      instituteId
      courseId
      name
      description
      imageUrl
    }
  }
`;

export const GET_CLASS_FORM_DETAILS = gql`
  query getClass($id: String!) {
    getClass(id: $id) {
      _id
      courseId
      name
      imageUrl
      description
    }
  }
`;

export const GET_CLASSES_FOR_TABLE = gql`
  query getClassesForTable($query: GetClassesQuery) {
    getClasses(query: $query) {
      _id
      instituteId
      name
      description
      imageUrl
      course {
        name
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($input: CreateUserInput!) {
    addUser(input: $input) {
      _id
    }
  }
`;

export const UPLOAD_PROFILE_PHOTO = gql`
  mutation uploadProfilePhoto($input: UploadProfilePhotoInput!) {
    uploadProfilePhoto(input: $input) {
      profilePicUrl
    }
  }
`;

export const REMOVE_PROFILE_PHOTO = gql`
  mutation removeProfilePhoto($id: String!) {
    removeProfilePhoto(id: $id)
  }
`;

export const GET_USER_FORM_DETAILS = gql`
  query getUser($id: String!) {
    getUser(id: $id) {
      _id
      email
      username
      role
      status
      name
      gender
      phone
      description
      parentId
      batches {
        _id
        batchId
        enrollmentStatus
        enrollmentDate
        inactiveDate
      }
      centerIds
    }
  }
`;

export const GET_USERS_FOR_DROPDOWN = gql`
  query getUsersForDropdown($query: GetUsersQuery) {
    getUsers(query: $query) {
      _id
      name
    }
  }
`;

export const GET_USERS_FOR_TABLE = gql`
  query getUsersForTable($query: GetUsersQuery) {
    getUsers(query: $query) {
      _id
      name
      email
      username
      role
      status
      batches {
        batch {
          _id
          name
          center {
            _id
            name
          }
          class {
            _id
            name
          }
        }
        enrollmentStatus
        enrollmentDate
        inactiveDate
      }
      centers {
        _id
        name
      }
      children {
        _id
        name
        batches {
          batch {
            _id
            name
            center {
              _id
              name
            }
            class {
              _id
              name
            }
          }
          enrollmentStatus
          enrollmentDate
          inactiveDate
        }
      }
    }
  }
`;

export const ADD_BATCH = gql`
  mutation addBatch($input: CreateBatchInput!) {
    addBatch(input: $input) {
      _id
    }
  }
`;

export const GET_BATCH_FORM_DETAILS = gql`
  query getBatch($id: String!) {
    getBatch(id: $id) {
      _id
      centerId
      classId
      name
      startDate
      endDate
      scheduleType
      schedule {
        _id
        day
        startTime
        endTime
      }
      feesSchedule {
        _id
        feesAmount
        dueDate
      }
      status
    }
  }
`;

export const GET_BATCHES_FOR_TABLE = gql`
  query getBatchesForTable($query: GetBatchesQuery) {
    getBatches(query: $query) {
      _id
      name
      startDate
      endDate
      scheduleType
      status
      class {
        name
      }
      centerId
      center {
        name
      }
      schedule {
        _id
        day
        startTime
        endTime
      }
    }
  }
`;

export const GET_USER_DEPS = gql`
  query getUserDeps($domain: String!, $role: [Role!]) {
    parents: getUsers(query: { domain: $domain, role: $role }) {
      _id
      name
    }
    centers: getCenters(query: { domain: $domain }) {
      _id
      name
    }
    batches: getBatches(query: { domain: $domain }) {
      _id
      name
    }
  }
`;

export const GET_BATCH_DEPS = gql`
  query getBatchDeps($domain: String!) {
    centers: getCenters(query: { domain: $domain }) {
      _id
      name
      address {
        city {
          timeZone
        }
        country {
          currencySymbol
        }
      }
    }
    classes: getClasses(query: { domain: $domain }) {
      _id
      name
    }
  }
`;

export const GET_CLASS_DEPS = gql`
  query getClassDeps($domain: String!) {
    courses: instituteCoursesByDomain(domain: $domain) {
      _id
      name
    }
  }
`;

export const REMOVE_INSTITUTE_LOGO = gql`
  mutation removeInstituteLogo {
    removeInstituteLogo
  }
`;

export const REMOVE_INSTITUTE_ABOUT_IMAGE = gql`
  mutation removeInstituteAboutImage {
    removeInstituteAboutImage
  }
`;

export const REMOVE_TESTIMONIAL_IMAGE = gql`
  mutation removeTestimonialImage($id: String!) {
    removeTestimonialImage(id: $id)
  }
`;

export const REMOVE_COURSE_IMAGE = gql`
  mutation removeCourseImage($id: String!) {
    removeCourseImage(id: $id)
  }
`;

export const REMOVE_CLASS_IMAGE = gql`
  mutation removeClassImage($id: String!) {
    removeClassImage(id: $id)
  }
`;

export const REMOVE_INSTITUTE_WEBSITE_MEDIA = gql`
  mutation removeInstituteWebsiteMedia($id: String!) {
    removeInstituteWebsiteMedia(id: $id)
  }
`;

export const SAVE_INSTITUTE_PAYMENT_INFO = gql`
  mutation updateInstitutePaymentInfo(
    $input: UpdateInstitutePaymentInfoInput!
  ) {
    updateInstitutePaymentInfo(input: $input) {
      name
      bankName
      dob
      accountNumber
      routingNumber
    }
  }
`;

export const LOAD_USER_CREDIT_CARD_INFO = gql`
  query getUserCreditCardInfo {
    userCreditCardInfo: getUserCreditCardInfo {
      id
      brand
      expMonth
      expYear
      last4
    }
  }
`;

export const SAVE_USER_CREDIT_CARD = gql`
  mutation saveUserCreditCard($input: SaveUserCreditCardInput!) {
    saveUserCreditCard(input: $input) {
      id
      brand
      expMonth
      expYear
      last4
    }
  }
`;

export const CANCEL_INSTITUTE_SUBSCRIPTION = gql`
  mutation cancelInstituteSubscription {
    cancelInstituteSubscription
  }
`;

export const RENEW_INSTITUTE_SUBSCRIPTION = gql`
  mutation renewInstituteSubscription {
    renewInstituteSubscription
  }
`;

export const LOAD_CENTER_HEAD_CONTACT_INFO = gql`
  query loadCenterHeadContactInfo($query: GetUsersQuery) {
    centerHeadContactInfo: getUsers(query: $query) {
      _id
      name
      email
      phone
      role
      centerIds
      centers {
        _id
        name
      }
    }
  }
`;

export const LOAD_TEACHER_CONTACT_INFO = gql`
  query loadTeacherContactInfo($query: GetUsersQuery) {
    teacherContactInfo: getUsers(query: $query) {
      _id
      name
      email
      phone
      role
      batches {
        batch {
          _id
          name
          center {
            _id
            name
            phone
            email
          }
        }
      }
    }
  }
`;
export const GET_CENTER_DEPS = gql`
  query getCenterDeps {
    cities: cities {
      _id
      cityName
      state {
        _id
        stateName
      }
      country {
        _id
        name
      }
    }
  }
`;

export const PAY_INVOICE_USING_CARD = gql`
  mutation payInvoiceUsingCard($input: PayInvoiceUsingCardInput!) {
    payInvoiceUsingCard(input: $input)
  }
`;

export const PAY_INVOICE_OFFLINE = gql`
  mutation payInvoiceOffline($input: PayInvoiceOfflineInput!) {
    payInvoiceOffline(input: $input)
  }
`;

export const GET_USER_INVOICE_FORM_DETAILS = gql`
  query getUserInvoice($id: String!) {
    getUserInvoice(id: $id) {
      _id
      instituteId
      userId
      user {
        _id
        email
        status
        name
        gender
      }
      amountDue
      currency
      currencySymbol
      dueDate
      invoiceType
      invoiceStatus
      paymentStatus
      batchId
      batch {
        _id
        name
        center {
          _id
          name
          address {
            city {
              timeZone
            }
            country {
              currencySymbol
            }
          }
        }
        class {
          _id
          name
        }
      }
      stripeInvoiceId
      processingFees
      amountPaid
      invoicePdfDownloadLink
      paymentMethodType
      notes
      chequeBankName
      chequeNumber
      paymentReceivedByUser
      paymentPaidByUser
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_INVOICES_FOR_TABLE = gql`
  query getUserInvoicesForTable($query: GetUserInvoicesQuery) {
    getUserInvoices(query: $query) {
      _id
      instituteId
      institute {
        _id
        name
      }
      userId
      user {
        _id
        email
        status
        name
        gender
      }
      amountDue
      currency
      currencySymbol
      dueDate
      invoiceType
      invoiceStatus
      paymentStatus
      payoutStatus
      batchId
      batch {
        _id
        name
        center {
          _id
          name
          address {
            city {
              timeZone
            }
            country {
              currencySymbol
            }
          }
        }
        class {
          _id
          name
        }
      }
      stripeInvoiceId
      processingFees
      amountPaid
      invoicePdfDownloadLink
      paymentMethodType
      notes
      chequeBankName
      chequeNumber
      paymentReceivedByUser
      paymentPaidByUser
      createdAt
      updatedAt
      paidAt
    }
  }
`;

export const GET_INSTITUTE_OWNERS_FOR_TABLE = gql`
  query getInstituteOwners {
    getInstituteOwners: getInstituteOwners {
      _id
      name
      email
      role
      instituteId
      institute {
        _id
        email
        name
        phone
        address {
          city {
            timeZone
          }
          country {
            currencySymbol
          }
        }
      }
      activeStudentsCount
      lastSubscriptionInvoice {
        invoicePdfDownloadLink
        amountDue
        amountPaid
        invoiceStatus
        paymentStatus
        createdAt
      }
      pendingPayoutAmount
      lastPayoutInvoice {
        invoicePdfDownloadLink
        amountDue
        amountPaid
        invoiceStatus
        paymentStatus
        createdAt
      }
      subscriptionPlan {
        _id
        name
        pricePerActiveStudent
      }
    }
  }
`;

export const RECEIVE_PAYMENT_FOR_INSTITUTE = gql`
  mutation receivePaymentForInstitute(
    $input: ReceivePaymentForInstituteInput!
  ) {
    receivePaymentForInstitute(input: $input)
  }
`;

export const SEND_PAYMENT_FOR_INSTITUTE = gql`
  mutation sendPaymentForInstitute($input: SendPaymentForInstituteInput!) {
    sendPaymentForInstitute(input: $input)
  }
`;

export const ADD_ANNOUNCEMENT = gql`
  mutation addAnnouncement($input: CreateAnnouncementInput!) {
    addAnnouncement(input: $input) {
      _id
      batchIds
      userIds
      heading
      description
      expiresAt
    }
  }
`;

export const GET_ANNOUNCEMENT_FORM_DETAILS = gql`
  query getAnnouncement($id: String!) {
    getAnnouncement(id: $id) {
      _id
      batchIds
      userIds
      heading
      description
      expiresAt
    }
  }
`;

export const GET_ANNOUNCEMENTS_FOR_TABLE = gql`
  query getAnnouncementsForTable($query: GetAnnouncementsQuery) {
    getAnnouncements(query: $query) {
      _id
      batchIds
      userIds
      heading
      description
      createdAt
      batches {
        _id
        name
        centerId
        center {
          _id
          name
          address {
            city {
              timeZone
            }
            country {
              currencySymbol
            }
          }
        }
        class {
          _id
          name
        }
      }
      users {
        _id
        name
      }
    }
  }
`;

export const GET_ANNOUNCEMENT_DEPS = gql`
  query getAnnouncementDeps($domain: String!, $role: [Role!]) {
    centers: getCenters(query: { domain: $domain }) {
      _id
      name
      address {
        city {
          timeZone
        }
        country {
          currencySymbol
        }
      }
    }
    batches: getBatches(query: { domain: $domain }) {
      _id
      name
      class {
        _id
        name
      }
      centerId
    }
    students: getUsers(query: { domain: $domain, role: $role }) {
      _id
      name
      batches {
        batchId
        batch {
          centerId
        }
      }
    }
  }
`;

export const ADD_ATTENDANCE = gql`
  mutation addAttendance($input: CreateAttendanceInput!) {
    addAttendance(input: $input) {
      _id
    }
  }
`;

export const GET_ATTENDANCE_FORM_DETAILS = gql`
  query getAttendance($id: String!) {
    getAttendance(id: $id) {
      _id
      batchId
    }
  }
`;

export const GET_ATTENDANCES_FOR_TABLE = gql`
  query getAttendancesForTable($query: GetAttendancesQuery) {
    getAttendances(query: $query) {
      _id
      batchId
      attendanceDate
      batch {
        _id
        name
        center {
          _id
          name
        }
        class {
          _id
          name
        }
      }
      students {
        userId
        user {
          _id
          name
        }
        attendanceStatus
      }
    }
  }
`;

export const GET_ATTENDANCE_DEPS = gql`
  query getAttendanceDeps($domain: String!, $role: [Role!], $batchId: String!) {
    students: getUsers(query: { domain: $domain, role: $role }) {
      _id
      name
      batches {
        batchId
        batch {
          centerId
        }
      }
    }
    batch: getBatch(id: $batchId) {
      _id
      center {
        _id
        name
        address {
          city {
            timeZone
          }
          country {
            currencySymbol
          }
        }
      }
      name
      startDate
      endDate
      scheduleType
      schedule {
        _id
        day
        startTime
        endTime
      }
      status
    }
  }
`;

export const ADD_ASSIGNMENT = gql`
  mutation addAssignment($input: CreateAssignmentInput!) {
    addAssignment(input: $input) {
      _id
    }
  }
`;

export const GET_ASSIGNMENT_FORM_DETAILS = gql`
  query getAssignment($id: String!) {
    getAssignment(id: $id) {
      _id
      batchId
    }
  }
`;

export const GET_ASSIGNMENTS_FOR_TABLE = gql`
  query getAssignmentsForTable($query: GetAssignmentsQuery) {
    getAssignments(query: $query) {
      _id
      batchId
      assignmentDate
      assignmentDueDate
      assignmentSubmissionFormat
      assignmentUrl
      assignmentFileUrl
      description
      createdAt
      updatedAt
      batch {
        _id
        name
        center {
          _id
          name
        }
        class {
          _id
          name
        }
      }
      students
      studentUsers {
        _id
        name
      }
      studentsExcluded
      studentExcludedUsers {
        _id
        name
      }
      createdByUser {
        _id
        name
      }
    }
  }
`;

export const GET_ASSIGNMENT_DEPS = gql`
  query getAssignmentDeps($domain: String!, $role: [Role!], $batchId: String!) {
    students: getUsers(query: { domain: $domain, role: $role }) {
      _id
      name
      batches {
        batchId
        batch {
          centerId
        }
      }
    }
    batch: getBatch(id: $batchId) {
      _id
      center {
        _id
        name
        address {
          city {
            timeZone
          }
          country {
            currencySymbol
          }
        }
      }
      name
      startDate
      endDate
      scheduleType
      schedule {
        _id
        day
        startTime
        endTime
      }
      status
    }
  }
`;

export const GET_ASSIGNMENT_LIST_DEPS = gql`
  query getAssignmentDeps($domain: String!, $role: [Role!]) {
    students: getUsers(query: { domain: $domain, role: $role }) {
      _id
      name
      batches {
        batchId
        batch {
          centerId
        }
      }
    }
  }
`;

export const ADD_ASSIGNMENT_SUBMISSION = gql`
  mutation addAssignmentSubmission($input: CreateAssignmentSubmissionInput!) {
    addAssignmentSubmission(input: $input) {
      _id
    }
  }
`;

export const GET_ASSIGNMENT_SUBMISSION = gql`
  query getAssignmentSubmission($assignmentId: String!, $studentId: String!) {
    student: getUser(id: $studentId) {
      _id
      name
    }
    assignment: getAssignment(id: $assignmentId) {
      _id
      assignmentDate
      assignmentDueDate
      assignmentSubmissionFormat
      assignmentUrl
      assignmentFileUrl
      description
      createdAt
      createdByUser {
        _id
        name
      }
    }
    assignmentSubmission: getAssignmentSubmissions(
      query: { assignmentId: $assignmentId, studentId: $studentId }
    ) {
      _id
      submissionStatus
      submissionHistory {
        _id
        submissionStatus
        submittedAs
        submittedByUserId
        submittedAt
        comment
        assignmentSubmissionFormat
        assignmentUrl
        assignmentFileUrl
        grade
      }
    }
  }
`;

export const GET_ASSIGNMENT_SUBMISSIONS = gql`
  query getAssignmentSubmissions($assignmentId: String, $studentId: String) {
    getAssignmentSubmissions: getAssignmentSubmissions(
      query: { assignmentId: $assignmentId, studentId: $studentId }
    ) {
      _id
      assignmentId
      studentId
      submissionStatus
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notifications($readByUser: Boolean) {
    notifications(readByUser: $readByUser) {
      _id
      instituteId
      userId
      payload {
        ... on BatchScheduleChangeNotificationPayload {
          notificationType
          batchId
          batch {
            _id
            name
          }
        }
        ... on FeesDueNotificationPayload {
          notificationType
          userInvoiceId
          amountDue
          currency
          currencySymbol
          dueDate
          userInvoice {
            batchId
            batch {
              name
            }
          }
        }
        ... on NewAnnouncementNotificationPayload {
          notificationType
          announcementId
          heading
          description
          announcement {
            batchIds
            createdByUser {
              name
              domain
            }
          }
        }
        ... on NewAssignmentNotificationPayload {
          notificationType
          assignmentId
          assignment {
            batchId
            createdByUser {
              name
            }
          }
        }
        ... on AssignmentSubmissionStatusChangeNotificationPayload {
          notificationType
          assignmentSubmissionId
          assignmentId
          assignment {
            batchId
            createdByUser {
              name
            }
          }
          submissionData {
            _id
            submissionStatus
            submittedAs
            submittedByUserId
            submittedAt
            comment
            assignmentSubmissionFormat
            assignmentUrl
            assignmentFileUrl
          }
        }
      }
      readByUser
      activateAt
      createdAt
      updatedAt
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation markAsRead($id: String!) {
    markAsRead: markNotificationAsRead(id: $id)
  }
`;

export const ADD_CONTACT_US = gql`
  mutation addContactUs($input: ContactUsInput!) {
    addContactUs(input: $input)
  }
`;

export const SAVE_BANK_ACCOUNT = gql`
  mutation saveBankAccount($input: BankAccountInput!) {
    saveBankAccount: saveUserBankAccount(input: $input) {
      name
      bankName
      accountNumber
      routingNumber
      status
    }
  }
`;

export const LOAD_USER_BANK_ACCOUNT = gql`
  query getUserBankAccount {
    userBankAccount: getUserBankAccount {
      name
      bankName
      accountNumber
      routingNumber
      status
    }
  }
`;

export const LOAD_PAYMENT_METHODS = gql`
  query getUserPaymentMethods {
    paymentMethods: getUserPaymentMethods {
      ... on CreditCard {
        type
        id
        brand
        expMonth
        expYear
        last4
        isDefault
      }
      ... on BankAccount {
        type
        id
        name
        bankName
        accountNumber
        routingNumber
        status
        isDefault
      }
    }
  }
`;

export const VERIFY_BANK_ACCOUNT = gql`
  mutation verifyBankAccount($input: VerifyUserBankAccountInput!) {
    verifyBankAccount: verifyUserBankAccount(input: $input)
  }
`;

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation setDefaultPaymentMethod($input: SetDefaultPaymentMethodInput!) {
    setDefaultPaymentMethod: setDefaultPaymentMethod(input: $input)
  }
`;

export const DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($input: DeletePaymentMethodInput!) {
    deletePaymentMethod: deletePaymentMethod(input: $input)
  }
`;
