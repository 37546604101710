/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonText } from '@ionic/react';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  AppRoutePath,
  AssignmentSubmissionStatus,
  UserRole,
} from '../../common/constants';
import { setTimeToZero } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { CreateAssignmentSubmissionInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import {
  loadAssignmentSubmission,
  resetLoadAssignmentSubmission,
} from './assignments.slice';
import AssignmentSubmissionForm from './AssignmentSubmissionForm';

type AssignmentDetailParams = {
  onSubmit: (data: CreateAssignmentSubmissionInput) => void;
  errors: string[];
  loading: boolean;
};

const AssignmentDetail = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: AssignmentDetailParams): JSX.Element => {
  const { assignmentId, studentId } = useParams<{
    assignmentId: string;
    studentId: string;
  }>();

  const { assignments: assignmentsText } = LanguageTexts;
  const { user: currentUser } = useSelector((state: RootState) => state.login);

  const [
    assignmentSubmissionLoadRequested,
    setAssignmentSubmissionLoadRequested,
  ] = useState<boolean>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadAssignmentSubmission: {
      loading: loadAssignmentSubmissionLoading,
      errors: loadAssignmentSubmissionErrors,
      success: loadAssignmentSubmissionSuccess,
    },
    assignmentSubmission,
    student,
    assignment,
  } = useSelector((state: RootState) => state.assignments);

  useEffect(() => {
    if (!assignmentSubmissionLoadRequested) {
      dispatch(loadAssignmentSubmission({ assignmentId, studentId }));
      setAssignmentSubmissionLoadRequested(true);
    }
  }, [assignmentSubmissionLoadRequested, assignmentId, studentId, dispatch]);

  useEffect(() => {
    if (loadAssignmentSubmissionErrors.length > 0) {
      dispatch(resetLoadAssignmentSubmission());
      history.push(AppRoutePath.Assignments);
    } else if (loadAssignmentSubmissionSuccess) {
      dispatch(resetLoadAssignmentSubmission());
    }
  }, [
    loadAssignmentSubmissionErrors,
    loadAssignmentSubmissionSuccess,
    dispatch,
    history,
  ]);

  if (
    !assignmentSubmissionLoadRequested ||
    loadAssignmentSubmissionLoading ||
    !assignment ||
    !student
  ) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSubmit(input: any) {
    onSubmitParent({
      ...input,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const assignmentSubmissionInit: any = {
    _id: assignmentSubmission?._id || undefined,
    assignmentId,
    studentId,
    submittedAs: currentUser?.role.includes(UserRole.Student)
      ? UserRole.Student
      : UserRole.Teacher,
    // eslint-disable-next-line no-nested-ternary
    submissionStatus: currentUser?.role.includes(UserRole.Student)
      ? assignmentSubmission
        ? AssignmentSubmissionStatus.Resubmitted
        : AssignmentSubmissionStatus.Submitted
      : undefined,
    comment: '',
    assignmentSubmissionFormat: undefined,
    assignmentUrl: '',
    assignmentFile: undefined,
    grade: '',
  };

  return (
    <>
      <div className="homework">
        <IonText>
          <h2 className="mb-1">{assignmentsText.homework}</h2>
        </IonText>
        <IonText>
          <p className="mb-1">
            {assignmentsText.teacherName}&nbsp;
            <b>{assignment.createdByUser.name}</b>
          </p>
        </IonText>
        <IonText>
          <p className="mb-1">
            {assignmentsText.dueDate}&nbsp;
            <b>
              {assignment
                ? moment(setTimeToZero(assignment.assignmentDueDate)).format(
                    'L',
                  )
                : ''}
            </b>
          </p>
        </IonText>
        <IonText>
          <p className="mb-1">
            {assignmentsText.description}&nbsp;
            <b>{assignment?.description}</b>
          </p>
        </IonText>
        {assignment?.assignmentUrl || assignment?.assignmentFileUrl ? (
          <IonText>
            <p className="mb-1">
              {assignmentsText.fileOrUrl}&nbsp;
              <a
                href={
                  assignment?.assignmentUrl || assignment?.assignmentFileUrl
                }
                className="font-weight-bold url-link"
                target="_blank"
                rel="noreferrer noopener"
                style={{ color: 'white' }}
              >
                {assignmentsText.view}&nbsp;
                <i className="fas fa-download" />
              </a>
            </p>
          </IonText>
        ) : null}
      </div>

      {assignmentSubmission
        ? assignmentSubmission.submissionHistory.map(
            ({
              _id,
              submittedAs,
              assignmentUrl,
              assignmentFileUrl,
              submittedAt,
              submissionStatus,
              comment,
              grade,
            }) => {
              if (submittedAs === UserRole.Student) {
                return (
                  <React.Fragment key={_id}>
                    <div className="homework submission">
                      <IonText>
                        <h2 className="mb-1">
                          {`${submissionStatus} ${assignmentsText.by} ${student.name}`}
                        </h2>
                      </IonText>
                      <IonText>
                        <p className="mb-1">
                          {assignmentsText.submissionFileOrUrl}&nbsp;
                          <a
                            href={assignmentUrl || assignmentFileUrl}
                            className="font-weight-bold url-link"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {assignmentsText.view}&nbsp;
                            <i className="fas fa-download" />
                          </a>
                        </p>
                      </IonText>
                      <IonText>
                        <p className="mb-1">
                          {assignmentsText.submissionDate}{' '}
                          <IonText color="primary">
                            <b>{moment(submittedAt).format('L')}</b>
                          </IonText>
                        </p>
                      </IonText>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              }

              return (
                <React.Fragment key={_id}>
                  <div className="homework submission">
                    <IonText>
                      <h2 className="mb-1">
                        {`${assignmentsText.remark} ${assignmentsText.by} ${assignment.createdByUser.name}`}
                      </h2>
                    </IonText>
                    <IonText>
                      <p className="mb-1">
                        {assignmentsText.remark} :{' '}
                        <IonText color="primary">
                          <b>{submissionStatus}</b>
                        </IonText>
                      </p>
                    </IonText>
                    <IonText>
                      <p className="mb-1">
                        {assignmentsText.remarkDate}{' '}
                        <IonText color="primary">
                          <b>{moment(submittedAt).format('L')}</b>
                        </IonText>
                      </p>
                    </IonText>
                    <IonText>
                      <p className="mb-1">
                        {assignmentsText.remarkComment}{' '}
                        <IonText color="primary">
                          <b>{comment}</b>
                        </IonText>
                      </p>
                    </IonText>
                    {grade ? (
                      <IonText>
                        <p className="mb-1">
                          {assignmentsText.grade}{' '}
                          <IonText color="primary">
                            <b>{grade}</b>
                          </IonText>
                        </p>
                      </IonText>
                    ) : null}
                    {assignmentUrl || assignmentFileUrl ? (
                      <p>
                        {assignmentsText.resubmissionFile}&nbsp;:&nbsp;
                        <a
                          href={assignmentUrl || assignmentFileUrl}
                          className="font-weight-bold"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {assignmentsText.view}&nbsp;
                          <i className="fas fa-download" />
                        </a>
                      </p>
                    ) : null}
                  </div>
                  <hr />
                </React.Fragment>
              );
            },
          )
        : null}

      {currentUser &&
      ((currentUser.role.includes(UserRole.Teacher) && assignmentSubmission) ||
        currentUser.role.includes(UserRole.Student)) ? (
        <AssignmentSubmissionForm
          onSubmit={onSubmit}
          initialValues={assignmentSubmissionInit}
          errors={errors}
          loading={loading}
          currentUser={currentUser}
        />
      ) : null}
    </>
  );
};

export default AssignmentDetail;
