import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { AppRoutePath, PublicRoutePath } from '../common/constants';
import { getPermissionsForRoute } from '../common/helper';
import { AppRoutePermissions, RootState } from '../common/types';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import AnnouncementPage from '../features/announcement/AnnouncementPage';
import AssignmentPage from '../features/assignment/AssignmentPage';
import AssignmentSubmitPage from '../features/assignment/AssignmentSubmitPage';
import ClassSchedulePage from '../features/class-schedule/ClassSchedulePage';
import ContactUsPage from '../features/contact-us/ContactUsPage';
import DashboardPage from '../features/dashboard/DashboardPage';
import ForgotPasswordPage from '../features/forgot-password/ForgotPasswordPage';
import LoginPage from '../features/login/LoginPage';
import NotificationPage from '../features/notification/NotificationPage';
import ProfilePage from '../features/profile/ProfilePage';

function RootRoutes(): JSX.Element {
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <PublicRoute exact path={PublicRoutePath.Login}>
          <LoginPage />
        </PublicRoute>
        <PublicRoute exact path={PublicRoutePath.ForgotPass}>
          <ForgotPasswordPage />
        </PublicRoute>
        <PrivateRoute
          exact
          path={AppRoutePath.Dashboard}
          permissions={rPermission(AppRoutePath.Dashboard)}
        >
          <DashboardPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={AppRoutePath.Notification}
          permissions={rPermission(AppRoutePath.Notification)}
        >
          <NotificationPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={AppRoutePath.Profile}
          permissions={rPermission(AppRoutePath.Profile)}
        >
          <ProfilePage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={AppRoutePath.ContactUs}
          permissions={rPermission(AppRoutePath.ContactUs)}
        >
          <ContactUsPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={AppRoutePath.Announcements}
          permissions={rPermission(AppRoutePath.Announcements)}
        >
          <AnnouncementPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={AppRoutePath.Assignments}
          permissions={rPermission(AppRoutePath.Assignments)}
        >
          <AssignmentPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={AppRoutePath.AssignmentsSubmit}
          permissions={rPermission(AppRoutePath.AssignmentsSubmit)}
        >
          <AssignmentSubmitPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={AppRoutePath.ClassSchedule}
          permissions={rPermission(AppRoutePath.ClassSchedule)}
        >
          <ClassSchedulePage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={AppRoutePath.ContactUs}
          permissions={rPermission(AppRoutePath.ContactUs)}
        >
          <ContactUsPage />
        </PrivateRoute>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Redirect to="/" />
      </IonRouterOutlet>
    </IonReactRouter>
  );
}

export default RootRoutes;
