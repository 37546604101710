import 'react-day-picker/lib/style.css';

import moment from 'moment-timezone';
import React, { FC } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';

import { setTimeToStart } from '../../../common/helper';

const todayStyle = `.DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  color: #212529;
  font-weight: 400;
}
`;

type BatchScheduleCalendarProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledDays: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scheduleDates: any[];
  selectedDay: moment.Moment | undefined;
  initialMonth: moment.Moment;
  onChange: (newValues: moment.Moment) => void;
};

const BatchScheduleCalendar: FC<BatchScheduleCalendarProps> = ({
  disabledDays,
  scheduleDates,
  selectedDay,
  initialMonth,
  onChange,
}: BatchScheduleCalendarProps): JSX.Element => {
  function handleDayClick(
    dayInit: Date,
    { disabled: dayDisabled }: DayModifiers,
  ) {
    if (dayDisabled) {
      return;
    }

    const day = moment(
      setTimeToStart(moment(dayInit).format(moment.HTML5_FMT.DATE)),
    );

    onChange(day);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modifiers: any = {
    scheduleDates,
  };

  if (selectedDay) {
    modifiers.selectedDay = [selectedDay.toDate()];
  }

  const modifiersStyles = {
    scheduleDates: {
      color: 'white',
      backgroundColor: '#28a745',
    },
    selectedDay: {
      fontWeight: 'bold',
    },
  };

  return (
    <>
      <style>{todayStyle}</style>
      <DayPicker
        initialMonth={initialMonth.toDate()}
        disabledDays={disabledDays}
        selectedDays={
          selectedDay
            ? [
                moment(
                  setTimeToStart(selectedDay.format(moment.HTML5_FMT.DATE)),
                ).toDate(),
              ]
            : []
        }
        onDayClick={handleDayClick}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        showOutsideDays
        enableOutsideDaysClick={false}
      />
    </>
  );
};

export default BatchScheduleCalendar;
