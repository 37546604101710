/* eslint-disable react/jsx-no-target-blank */
import './LoginPage.scss';

import { IonContent, IonPage, IonText } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDomain } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { LoginInput, RootState } from '../../common/types';
import { login } from './login.slice';
import LoginForm from './LoginForm';

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const { login: loginText } = LanguageTexts;

  const { errors, loading } = useSelector((state: RootState) => state.login);
  const domain = getDomain();
  const privacyPage = `https://${domain.replace('m.', '')}/privacy-policy`;

  function onSubmit(input: LoginInput) {
    dispatch(login(input));
  }

  return (
    <IonPage>
      <IonContent>
        <div
          className="bg-image"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/login/side-image.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <section className="logo-part flex-column-align-center">
            <img
              className="logo"
              src={`${process.env.REACT_APP_IMAGE_PATH}website/logo/main-app-logo.jpg`}
              alt="logo"
            />
          </section>
          <section className="auth-part">
            <div className="text-center heading">
              <IonText color="primary">
                <h3 className="font-weight-bold">{loginText.title}</h3>
              </IonText>
              <IonText color="secondary">
                <h5 className="font-weight-bold">{loginText.subtitle}</h5>
              </IonText>
            </div>
            <LoginForm onSubmit={onSubmit} errors={errors} loading={loading} />
            <div className="text-center mb-1">
              <IonText color="primary">
                <a
                  href={`${privacyPage}`}
                  target="_blank"
                  className="privacy-link"
                >
                  {loginText.privacyPolicyText}
                </a>
              </IonText>
            </div>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
