/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BankAccountInput,
  BankAccountModel,
  BankAccountVerifyInput,
  ChangePasswordInput,
  CreditCardModel,
  DeletePaymentMethodInput,
  PaymentMethodModel,
  ProfileState,
  SetDefaultPaymentMethodInput,
  UploadProfilePhotoInput,
  UserCreditCardInput,
  UserModel,
} from '../../common/types';

const initialState: ProfileState = {
  loading: false,
  success: null,
  errors: [],
  saveUserCreditCard: {
    loading: false,
    success: null,
    errors: [],
  },
  showProfilePic: null,
  uploadProfilePic: {
    loading: false,
    success: null,
    errors: [],
  },
  removeProfilePhoto: {
    loading: false,
    success: null,
    errors: [],
  },
  changePassword: {
    loading: false,
    success: null,
    errors: [],
  },
  userCreditCardInfo: null,
  cancelInstituteSubscription: {
    loading: false,
    success: null,
    errors: [],
  },
  renewInstituteSubscription: {
    loading: false,
    success: null,
    errors: [],
  },
  saveBankAccount: {
    loading: false,
    success: null,
    errors: [],
  },
  bankAccountInfo: null,
  loadBankAccountInfo: {
    loading: false,
    success: null,
    errors: [],
  },
  paymentMethods: null,
  loadPaymentMethods: {
    loading: false,
    success: null,
    errors: [],
  },
  verifyBankAccount: {
    loading: false,
    success: null,
    errors: [],
  },
  setDefaultPaymentMethod: {
    loading: false,
    success: null,
    errors: [],
  },
  deletePaymentMethod: {
    loading: false,
    success: null,
    errors: [],
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadProfilePhoto(state, action: PayloadAction<UploadProfilePhotoInput>) {
      state.uploadProfilePic.loading = true;
      state.uploadProfilePic.errors = [];
    },
    uploadProfilePhotoSuccess(state, action: PayloadAction<UserModel>) {
      state.uploadProfilePic.loading = false;
      state.uploadProfilePic.success = !!action.payload;
      state.showProfilePic = action.payload;
    },
    uploadProfilePhotoError(state, action: PayloadAction<string[]>) {
      state.uploadProfilePic.loading = false;
      state.uploadProfilePic.errors = action.payload;
    },
    resetProfilePhotoSuccess(state) {
      state.uploadProfilePic.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeProfilePhoto(state, action: PayloadAction<string>) {
      state.removeProfilePhoto.loading = true;
      state.removeProfilePhoto.errors = [];
    },
    removeProfilePhotoSuccess(
      state,
      action: PayloadAction<{ success: boolean; id: string }>,
    ) {
      state.removeProfilePhoto.loading = false;
      state.removeProfilePhoto.success = action.payload.success;
    },
    removeProfilePhotoError(state, action: PayloadAction<string[]>) {
      state.removeProfilePhoto.loading = false;
      state.removeProfilePhoto.errors = action.payload;
    },
    resetRemoveProfilePhoto(state) {
      state.removeProfilePhoto.success = null;
      state.removeProfilePhoto.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changePassword(state, action: PayloadAction<ChangePasswordInput>) {
      state.changePassword.loading = true;
      state.changePassword.errors = [];
    },
    changePasswordSuccess(state, action: PayloadAction<boolean>) {
      state.changePassword.loading = false;
      state.changePassword.success = action.payload;
    },
    changePasswordError(state, action: PayloadAction<string[]>) {
      state.changePassword.loading = false;
      state.changePassword.errors = action.payload;
    },
    resetChangePassword(state) {
      state.changePassword.success = null;
      state.changePassword.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadProfileInfo(state, action: PayloadAction<undefined>) {
      state.loading = true;
      state.errors = [];
    },
    loadProfileInfoSuccess(state, action: PayloadAction<CreditCardModel>) {
      state.loading = false;
      state.success = !!action.payload;
      state.userCreditCardInfo = action.payload;
    },
    loadProfileInfoError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadProfileInfo(state) {
      state.success = null;
      state.errors = [];
    },
    saveUserCreditCard(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<UserCreditCardInput>,
    ) {
      state.saveUserCreditCard.loading = true;
      state.saveUserCreditCard.errors = [];
    },
    saveUserCreditCardSuccess(state, action: PayloadAction<CreditCardModel>) {
      state.saveUserCreditCard.loading = false;
      state.saveUserCreditCard.success = !!action.payload;
      state.userCreditCardInfo = action.payload;
    },
    saveUserCreditCardError(state, action: PayloadAction<string[]>) {
      state.saveUserCreditCard.loading = false;
      state.saveUserCreditCard.errors = action.payload;
    },
    resetSaveUserCreditCard(state) {
      state.saveUserCreditCard.success = null;
      state.saveUserCreditCard.errors = [];
    },
    cancelInstituteSubscription(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<undefined>,
    ) {
      state.cancelInstituteSubscription.loading = true;
      state.cancelInstituteSubscription.errors = [];
    },
    cancelInstituteSubscriptionSuccess(state, action: PayloadAction<boolean>) {
      state.cancelInstituteSubscription.loading = false;
      state.cancelInstituteSubscription.success = action.payload;
    },
    cancelInstituteSubscriptionError(state, action: PayloadAction<string[]>) {
      state.cancelInstituteSubscription.loading = false;
      state.cancelInstituteSubscription.errors = action.payload;
    },
    resetCancelInstituteSubscription(state) {
      state.cancelInstituteSubscription.success = null;
      state.cancelInstituteSubscription.errors = [];
    },
    renewInstituteSubscription(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<undefined>,
    ) {
      state.renewInstituteSubscription.loading = true;
      state.renewInstituteSubscription.errors = [];
    },
    renewInstituteSubscriptionSuccess(state, action: PayloadAction<boolean>) {
      state.renewInstituteSubscription.loading = false;
      state.renewInstituteSubscription.success = action.payload;
    },
    renewInstituteSubscriptionError(state, action: PayloadAction<string[]>) {
      state.renewInstituteSubscription.loading = false;
      state.renewInstituteSubscription.errors = action.payload;
    },
    resetRenewInstituteSubscription(state) {
      state.renewInstituteSubscription.success = null;
      state.renewInstituteSubscription.errors = [];
    },
    saveBankAccount(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<BankAccountInput>,
    ) {
      state.saveBankAccount.loading = true;
      state.saveBankAccount.errors = [];
    },
    saveBankAccountSuccess(state, action: PayloadAction<BankAccountModel>) {
      state.saveBankAccount.loading = false;
      state.saveBankAccount.success = !!action.payload;
      state.bankAccountInfo = action.payload;
    },
    saveBankAccountError(state, action: PayloadAction<string[]>) {
      state.saveBankAccount.loading = false;
      state.saveBankAccount.errors = action.payload;
    },
    resetSaveBankAccount(state) {
      state.saveBankAccount.success = null;
      state.saveBankAccount.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadBankAccountInfo(state, action: PayloadAction<undefined>) {
      state.loadBankAccountInfo.loading = true;
      state.loadBankAccountInfo.errors = [];
    },
    loadBankAccountInfoSuccess(state, action: PayloadAction<BankAccountModel>) {
      state.loadBankAccountInfo.loading = false;
      state.loadBankAccountInfo.success = !!action.payload;
      state.bankAccountInfo = action.payload;
    },
    loadBankAccountInfoError(state, action: PayloadAction<string[]>) {
      state.loadBankAccountInfo.loading = false;
      state.loadBankAccountInfo.errors = action.payload;
    },
    resetLoadBankAccountInfo(state) {
      state.loadBankAccountInfo.success = null;
      state.loadBankAccountInfo.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadPaymentMethods(state, action: PayloadAction<undefined>) {
      state.loadPaymentMethods.loading = true;
      state.loadPaymentMethods.errors = [];
    },
    loadPaymentMethodsSuccess(
      state,
      action: PayloadAction<PaymentMethodModel[]>,
    ) {
      state.loadPaymentMethods.loading = false;
      state.loadPaymentMethods.success = !!action.payload;
      state.paymentMethods = action.payload;
    },
    loadPaymentMethodsError(state, action: PayloadAction<string[]>) {
      state.loadPaymentMethods.loading = false;
      state.loadPaymentMethods.errors = action.payload;
    },
    resetLoadPaymentMethods(state) {
      state.loadPaymentMethods.success = null;
      state.loadPaymentMethods.errors = [];
    },
    verifyBankAccount(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<BankAccountVerifyInput>,
    ) {
      state.verifyBankAccount.loading = true;
      state.verifyBankAccount.errors = [];
    },
    verifyBankAccountSuccess(state, action: PayloadAction<boolean>) {
      state.verifyBankAccount.loading = false;
      state.verifyBankAccount.success = !!action.payload;
    },
    verifyBankAccountError(state, action: PayloadAction<string[]>) {
      state.verifyBankAccount.loading = false;
      state.verifyBankAccount.errors = action.payload;
    },
    resetVerifyBankAccount(state) {
      state.verifyBankAccount.success = null;
      state.verifyBankAccount.errors = [];
    },
    setDefaultPaymentMethod(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<SetDefaultPaymentMethodInput>,
    ) {
      state.setDefaultPaymentMethod.loading = true;
      state.setDefaultPaymentMethod.errors = [];
    },
    setDefaultPaymentMethodSuccess(state, action: PayloadAction<boolean>) {
      state.setDefaultPaymentMethod.loading = false;
      state.setDefaultPaymentMethod.success = !!action.payload;
    },
    setDefaultPaymentMethodError(state, action: PayloadAction<string[]>) {
      state.setDefaultPaymentMethod.loading = false;
      state.setDefaultPaymentMethod.errors = action.payload;
    },
    resetSetDefaultPaymentMethod(state) {
      state.setDefaultPaymentMethod.success = null;
      state.setDefaultPaymentMethod.errors = [];
    },
    deletePaymentMethod(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<DeletePaymentMethodInput>,
    ) {
      state.deletePaymentMethod.loading = true;
      state.deletePaymentMethod.errors = [];
    },
    deletePaymentMethodSuccess(state, action: PayloadAction<boolean>) {
      state.deletePaymentMethod.loading = false;
      state.deletePaymentMethod.success = !!action.payload;
    },
    deletePaymentMethodError(state, action: PayloadAction<string[]>) {
      state.deletePaymentMethod.loading = false;
      state.deletePaymentMethod.errors = action.payload;
    },
    resetDeletePaymentMethod(state) {
      state.deletePaymentMethod.success = null;
      state.deletePaymentMethod.errors = [];
    },
  },
});

export const {
  changePassword,
  changePasswordSuccess,
  changePasswordError,
  resetChangePassword,
  loadProfileInfo,
  loadProfileInfoSuccess,
  loadProfileInfoError,
  resetLoadProfileInfo,
  saveUserCreditCard,
  saveUserCreditCardSuccess,
  saveUserCreditCardError,
  resetSaveUserCreditCard,
  cancelInstituteSubscription,
  cancelInstituteSubscriptionSuccess,
  cancelInstituteSubscriptionError,
  resetCancelInstituteSubscription,
  renewInstituteSubscription,
  renewInstituteSubscriptionSuccess,
  renewInstituteSubscriptionError,
  resetRenewInstituteSubscription,
  uploadProfilePhoto,
  uploadProfilePhotoSuccess,
  uploadProfilePhotoError,
  resetProfilePhotoSuccess,
  removeProfilePhoto,
  removeProfilePhotoSuccess,
  removeProfilePhotoError,
  resetRemoveProfilePhoto,
  saveBankAccount,
  saveBankAccountSuccess,
  saveBankAccountError,
  resetSaveBankAccount,
  loadBankAccountInfo,
  loadBankAccountInfoSuccess,
  loadBankAccountInfoError,
  resetLoadBankAccountInfo,
  loadPaymentMethods,
  loadPaymentMethodsSuccess,
  loadPaymentMethodsError,
  resetLoadPaymentMethods,
  verifyBankAccount,
  verifyBankAccountSuccess,
  verifyBankAccountError,
  resetVerifyBankAccount,
  setDefaultPaymentMethod,
  setDefaultPaymentMethodSuccess,
  setDefaultPaymentMethodError,
  resetSetDefaultPaymentMethod,
  deletePaymentMethod,
  deletePaymentMethodSuccess,
  deletePaymentMethodError,
  resetDeletePaymentMethod,
} = profileSlice.actions;

export const {
  name: profileSliceName,
  reducer: profileSliceReducer,
} = profileSlice;
