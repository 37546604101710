/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AssignmentDepsResponse,
  AssignmentListDepsResponse,
  AssignmentModel,
  AssignmentsState,
  AssignmentSubmissionModel,
  CreateAssignmentInput,
  CreateAssignmentSubmissionInput,
  GetAssignmentDepsQuery,
  GetAssignmentListDepsQuery,
  GetAssignmentsQuery,
  GetAssignmentSubmissionQuery,
  GetAssignmentSubmissionResponse,
  GetAssignmentSubmissionsQuery,
} from '../../common/types';

const initialState: AssignmentsState = {
  loading: false,
  success: null,
  errors: [],
  assignments: null,
  addAssignment: {
    loading: false,
    success: null,
    errors: [],
  },
  loadAssignment: {
    loading: false,
    success: null,
    errors: [],
  },
  loadAssignmentDeps: {
    loading: false,
    success: null,
    errors: [],
  },
  loadAssignmentListDeps: {
    loading: false,
    success: null,
    errors: [],
  },
  batch: null,
  students: null,
  addAssignmentSubmission: {
    loading: false,
    success: null,
    errors: [],
  },
  loadAssignmentSubmission: {
    loading: false,
    success: null,
    errors: [],
  },
  assignment: null,
  student: null,
  assignmentSubmission: null,
  loadAssignmentSubmissions: {
    loading: false,
    success: null,
    errors: [],
  },
  assignmentSubmissions: null,
};

const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadAssignments(state, action: PayloadAction<GetAssignmentsQuery>) {
      state.loading = true;
      state.errors = [];
    },
    loadAssignmentsSuccess(state, action: PayloadAction<AssignmentModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.assignments = action.payload;
    },
    loadAssignmentsError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadAssignments(state) {
      state.success = null;
      state.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addAssignment(state, action: PayloadAction<CreateAssignmentInput>) {
      state.addAssignment.loading = true;
      state.addAssignment.errors = [];
    },
    addAssignmentSuccess(state, action: PayloadAction<boolean>) {
      state.addAssignment.loading = false;
      state.addAssignment.success = action.payload;
    },
    addAssignmentError(state, action: PayloadAction<string[]>) {
      state.addAssignment.loading = false;
      state.addAssignment.errors = action.payload;
    },
    resetAddAssignmentSuccess(state) {
      state.addAssignment.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadAssignment(state, action: PayloadAction<string>) {
      state.loadAssignment.loading = true;
      state.loadAssignment.errors = [];
    },
    loadAssignmentSuccess(state, action: PayloadAction<AssignmentModel>) {
      state.loadAssignment.loading = false;
      state.loadAssignment.success = action.payload;
    },
    loadAssignmentError(state, action: PayloadAction<string[]>) {
      state.loadAssignment.loading = false;
      state.loadAssignment.errors = action.payload;
    },
    resetLoadAssignment(state) {
      state.loadAssignment.success = null;
      state.loadAssignment.errors = [];
    },
    loadAssignmentDeps(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetAssignmentDepsQuery>,
    ) {
      state.loadAssignmentDeps.loading = true;
      state.loadAssignmentDeps.errors = [];
    },
    loadAssignmentDepsSuccess(
      state,
      action: PayloadAction<AssignmentDepsResponse>,
    ) {
      state.loadAssignmentDeps.loading = false;
      state.loadAssignmentDeps.success = !!action.payload;
      state.students = action.payload.students;
      state.batch = action.payload.batch;
    },
    loadAssignmentDepsError(state, action: PayloadAction<string[]>) {
      state.loadAssignmentDeps.loading = false;
      state.loadAssignmentDeps.errors = action.payload;
    },
    resetLoadAssignmentDeps(state) {
      state.loadAssignmentDeps.success = null;
      state.loadAssignmentDeps.errors = [];
    },
    loadAssignmentListDeps(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetAssignmentListDepsQuery>,
    ) {
      state.loadAssignmentListDeps.loading = true;
      state.loadAssignmentListDeps.errors = [];
    },
    loadAssignmentListDepsSuccess(
      state,
      action: PayloadAction<AssignmentListDepsResponse>,
    ) {
      state.loadAssignmentListDeps.loading = false;
      state.loadAssignmentListDeps.success = !!action.payload;
      state.students = action.payload.students;
    },
    loadAssignmentListDepsError(state, action: PayloadAction<string[]>) {
      state.loadAssignmentListDeps.loading = false;
      state.loadAssignmentListDeps.errors = action.payload;
    },
    resetLoadAssignmentListDeps(state) {
      state.loadAssignmentListDeps.success = null;
      state.loadAssignmentListDeps.errors = [];
    },
    addAssignmentSubmission(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<CreateAssignmentSubmissionInput>,
    ) {
      state.addAssignmentSubmission.loading = true;
      state.addAssignmentSubmission.errors = [];
    },
    addAssignmentSubmissionSuccess(state, action: PayloadAction<boolean>) {
      state.addAssignmentSubmission.loading = false;
      state.addAssignmentSubmission.success = action.payload;
    },
    addAssignmentSubmissionError(state, action: PayloadAction<string[]>) {
      state.addAssignmentSubmission.loading = false;
      state.addAssignmentSubmission.errors = action.payload;
    },
    resetAddAssignmentSubmissionSuccess(state) {
      state.addAssignmentSubmission.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadAssignmentSubmission(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetAssignmentSubmissionQuery>,
    ) {
      state.loadAssignmentSubmission.loading = true;
      state.loadAssignmentSubmission.errors = [];

      state.assignment = null;
      state.student = null;
      state.assignmentSubmission = null;
    },
    loadAssignmentSubmissionSuccess(
      state,
      action: PayloadAction<GetAssignmentSubmissionResponse>,
    ) {
      state.loadAssignmentSubmission.loading = false;
      state.loadAssignmentSubmission.success = !!action.payload;

      state.assignment = action.payload.assignment;
      state.student = action.payload.student;
      state.assignmentSubmission = action.payload.assignmentSubmission;
    },
    loadAssignmentSubmissionError(state, action: PayloadAction<string[]>) {
      state.loadAssignmentSubmission.loading = false;
      state.loadAssignmentSubmission.errors = action.payload;

      state.assignment = null;
      state.student = null;
      state.assignmentSubmission = null;
    },
    resetLoadAssignmentSubmission(state) {
      state.loadAssignmentSubmission.success = null;
      state.loadAssignmentSubmission.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadAssignmentSubmissions(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetAssignmentSubmissionsQuery>,
    ) {
      state.loadAssignmentSubmissions.loading = true;
      state.loadAssignmentSubmissions.errors = [];
      state.assignmentSubmissions = null;
    },
    loadAssignmentSubmissionsSuccess(
      state,
      action: PayloadAction<AssignmentSubmissionModel[]>,
    ) {
      state.loadAssignmentSubmissions.loading = false;
      state.loadAssignmentSubmissions.success = !!action.payload;

      state.assignmentSubmissions = action.payload;
    },
    loadAssignmentSubmissionsError(state, action: PayloadAction<string[]>) {
      state.loadAssignmentSubmissions.loading = false;
      state.loadAssignmentSubmissions.errors = action.payload;

      state.assignmentSubmissions = null;
    },
    resetLoadAssignmentSubmissions(state) {
      state.loadAssignmentSubmissions.success = null;
      state.loadAssignmentSubmissions.errors = [];
    },
  },
});

export const {
  loadAssignment,
  loadAssignmentError,
  loadAssignmentSuccess,
  resetLoadAssignment,
  loadAssignments,
  loadAssignmentsError,
  loadAssignmentsSuccess,
  resetLoadAssignments,
  addAssignment,
  addAssignmentSuccess,
  addAssignmentError,
  resetAddAssignmentSuccess,
  loadAssignmentDeps,
  loadAssignmentDepsSuccess,
  loadAssignmentDepsError,
  resetLoadAssignmentDeps,
  loadAssignmentListDeps,
  loadAssignmentListDepsSuccess,
  loadAssignmentListDepsError,
  resetLoadAssignmentListDeps,
  addAssignmentSubmission,
  addAssignmentSubmissionSuccess,
  addAssignmentSubmissionError,
  resetAddAssignmentSubmissionSuccess,
  loadAssignmentSubmission,
  loadAssignmentSubmissionSuccess,
  loadAssignmentSubmissionError,
  resetLoadAssignmentSubmission,
  loadAssignmentSubmissions,
  loadAssignmentSubmissionsSuccess,
  loadAssignmentSubmissionsError,
  resetLoadAssignmentSubmissions,
} = assignmentsSlice.actions;

export const {
  name: assignmentsSliceName,
  reducer: assignmentsSliceReducer,
} = assignmentsSlice;
