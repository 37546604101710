import 'reflect-metadata';

import { ApolloProvider } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import client from './app/apolo.client';
import App from './app/App';
import configureStore from './app/store';
import * as serviceWorker from './serviceWorker';

const initialState = {};
const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      // eslint-disable-next-line no-alert
      alert('A new version is available. click ok to update app.');
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      setTimeout(() => {
        window.location.href = '/';
      }, 500);
    }
  },
});
