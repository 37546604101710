/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonButton, IonInput, IonItem, IonText } from '@ionic/react';
import { Form, Formik } from 'formik';
import React from 'react';

import { formatErrors, getLangAndErrKeys } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { ChangePasswordInput } from '../../../common/types';

type ChangePasswordFormProps = {
  onSubmit: (data: ChangePasswordInput) => void;
  errors: string[];
  loading: boolean;
  initialValues: ChangePasswordInput;
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  onSubmit,
  errors,
  loading,
  initialValues,
}: ChangePasswordFormProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(
    errorKeys,
    errors,
    LanguageTexts.profile,
  );

  return (
    <>
      <Formik
        initialValues={initVals}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleChange }) => {
          return (
            <Form>
              <IonItem lines="none" className="inputField mb-1">
                <IonInput
                  placeholder={profileTxt.newPassword}
                  name="newPassword"
                  type="password"
                  className="font-weight-bold"
                  color="secondary"
                  onIonChange={handleChange}
                />
              </IonItem>
              <IonItem lines="none" className="inputField mb-1">
                <IonInput
                  placeholder={profileTxt.oldPassword}
                  name="oldPassword"
                  type="password"
                  className="font-weight-bold"
                  color="secondary"
                  onIonChange={handleChange}
                />
              </IonItem>
              <div className="mb-1 error-msg">
                {formattedErrors.map((error) => (
                  <IonText color="secondary">
                    <p style={{ fontWeight: 'bold' }} key={error}>
                      {error}
                    </p>
                  </IonText>
                ))}
              </div>
              <div className="text-right mb-1">
                <IonButton type="submit" disabled={loading}>
                  {profileTxt.changePassBtn}
                </IonButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ChangePasswordForm;
