import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { AppRoutePath } from '../common/constants';
import { RootState } from '../common/types';

type PublicRouteProps = {
  children: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const PublicRoute: React.FC<PublicRouteProps> = ({
  children,
  ...rest
}: PublicRouteProps): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.login);

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        !user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AppRoutePath.Dashboard,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PublicRoute;
