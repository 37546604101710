/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  CreateBatchInput,
  GetBatchDepsQuery,
  GetBatchesQuery,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  addBatch,
  addBatchError,
  addBatchSuccess,
  loadBatch,
  loadBatchDeps,
  loadBatchDepsError,
  loadBatchDepsSuccess,
  loadBatchError,
  loadBatches,
  loadBatchesError,
  loadBatchesSuccess,
  loadBatchSuccess,
} from './batches.slice';

export function* addBatchAsync(action: PayloadAction<CreateBatchInput>) {
  const { data, errors } = yield call(ApiService.addBatch, action.payload);

  if (errors) {
    yield put(addBatchError(errors));
  } else {
    yield put(addBatchSuccess(!!data));
  }
}

export function* loadBatchAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadBatchFormDetails, action.payload);

  if (!data) {
    yield put(loadBatchError(['404']));
  } else {
    yield put(loadBatchSuccess(data));
  }
}

export function* loadBatchesAsync(action: PayloadAction<GetBatchesQuery>) {
  const { data } = yield call(ApiService.getBatchesForTable, action.payload);

  if (!data) {
    yield put(loadBatchesError(['500']));
  } else {
    yield put(loadBatchesSuccess(data));
  }
}

export function* loadBatchDepsAsync(action: PayloadAction<GetBatchDepsQuery>) {
  const { data } = yield call(ApiService.getBatchDeps, action.payload);

  if (!data) {
    yield put(loadBatchDepsError(['500']));
  } else {
    yield put(loadBatchDepsSuccess(data));
  }
}

export function* watchBatchesAsync() {
  yield takeEvery(addBatch.toString(), addBatchAsync);
  yield takeEvery(loadBatch.toString(), loadBatchAsync);
  yield takeEvery(loadBatches.toString(), loadBatchesAsync);
  yield takeEvery(loadBatchDeps.toString(), loadBatchDepsAsync);
}
