import './AssignmentPage.scss';

import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

import LanguageTexts from '../../common/language';
import Heading from '../../components/Heading';
import TopHeader from '../../components/TopHeader';
import AssignmentList from './AssignmentList';

const AssignmentPage: React.FC = () => {
  const { assignments: assignmentsText } = LanguageTexts;

  return (
    <IonPage>
      <TopHeader />
      <IonContent className="main-content" fullscreen>
        <Heading title={assignmentsText.heading} iconName="fas fa-file-alt" />
        <AssignmentList />
      </IonContent>
    </IonPage>
  );
};

export default AssignmentPage;
