/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonButton, IonInput, IonItem, IonText } from '@ionic/react';
import { useStripe } from '@stripe/react-stripe-js';
import { plainToClass } from 'class-transformer';
import { validate } from 'class-validator';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useState } from 'react';

import {
  extractClassValidatorErr,
  formatErrors,
  getLangAndErrKeys,
} from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { BankAccountInput } from '../../../common/types';
import BankAccountDto from './bank-account.dto';

type BankAccountProps = {
  onSubmit: (data: BankAccountInput) => void;
  onCancel: () => void;
  errors: string[];
  loading: boolean;
  showCancel: boolean;
};

const BankAccountForm: React.FC<BankAccountProps> = ({
  onSubmit,
  onCancel,
  errors,
  loading,
  showCancel,
}: BankAccountProps): JSX.Element => {
  const stripe = useStripe();
  const { profile: profileTxt } = LanguageTexts;
  const initialValues = {
    name: '',
    accountNumber: '',
    routingNumber: '',
    bankToken: '',
  };
  const [cErrors, setCErrors] = useState<string[] | undefined>();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        if (!stripe) {
          return null;
        }

        const valuesClass = plainToClass(BankAccountDto, values);

        const err = await validate(valuesClass, {
          validationError: { target: false, value: false },
        });

        if (err.length > 0) {
          setCErrors(extractClassValidatorErr(err));
          return null;
        }

        setCErrors(undefined);

        const { accountNumber, routingNumber, name } = values;

        const { error, token } = await stripe.createToken('bank_account', {
          account_holder_type: 'individual',
          country: 'US',
          currency: 'usd',
          account_holder_name: name,
          account_number: accountNumber,
          routing_number: routingNumber,
        });

        setErrors({ bankToken: error?.message || undefined });

        if (token) {
          onSubmit({
            bankToken: token.id,
          });
          setSubmitting(false);
        }

        return null;
      }}
    >
      {({ values, handleChange }) => {
        const errorKeys = getLangAndErrKeys(values);

        const formattedErrors = formatErrors(
          errorKeys,
          cErrors || errors,
          profileTxt,
        );

        return (
          <Form>
            <IonItem lines="none" className="inputField mb-1">
              <IonInput
                placeholder={profileTxt.accountHolderName}
                name="name"
                type="text"
                className="font-weight-bold"
                color="secondary"
                onIonChange={handleChange}
              />
            </IonItem>
            <IonItem lines="none" className="inputField mb-1">
              <IonInput
                placeholder={profileTxt.accountNumber}
                name="accountNumber"
                type="text"
                className="font-weight-bold"
                color="secondary"
                onIonChange={handleChange}
              />
            </IonItem>
            <IonItem lines="none" className="inputField mb-1">
              <IonInput
                placeholder={profileTxt.routingNumber}
                name="routingNumber"
                type="text"
                className="font-weight-bold"
                color="secondary"
                onIonChange={handleChange}
              />
            </IonItem>
            <div className="mb-1 error-msg">
              {formattedErrors.map((error) => (
                <IonText color="secondary">
                  <p style={{ fontWeight: 'bold' }} key={error}>
                    {error}
                  </p>
                </IonText>
              ))}
              <ErrorMessage
                name="bankToken"
                render={(msg) => <p style={{ fontWeight: 'bold' }}>{msg}</p>}
              />
            </div>
            <div className="text-right mb-1">
              {showCancel ? (
                <IonButton
                  type="button"
                  disabled={loading}
                  onClick={() => onCancel()}
                >
                  {profileTxt.btnCancel}&nbsp;
                </IonButton>
              ) : null}
              &nbsp;
              <IonButton type="submit" disabled={loading}>
                {profileTxt.btnSave}&nbsp;
              </IonButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BankAccountForm;
