/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoginInput, LoginState, UserModel } from '../../common/types';

const initialState: LoginState = {
  loading: false,
  success: null,
  errors: [],
  whoamiState: {
    loading: false,
    success: null,
    errors: [],
  },
  accessToken: null,
  user: null,
  userCheckDone: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    login(state, action: PayloadAction<LoginInput>) {
      state.loading = true;
      state.success = null;
      state.errors = [];

      state.accessToken = null;
      state.user = null;
    },
    loginSuccess(state, action: PayloadAction<UserModel>) {
      state.loading = false;
      state.success = true;

      state.accessToken = action.payload.accessToken || null;
    },
    loginError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    whoami(state, action: PayloadAction) {
      state.whoamiState.loading = true;
      state.whoamiState.success = false;
      state.whoamiState.errors = [];

      state.userCheckDone = false;
    },
    whoamiSuccess(state, action: PayloadAction<UserModel | null>) {
      state.whoamiState.loading = false;
      state.whoamiState.success = true;

      state.user = action.payload;
      state.userCheckDone = true;
    },
    whoamiError(state, action: PayloadAction<string[]>) {
      state.whoamiState.loading = false;
      state.whoamiState.errors = action.payload;

      state.userCheckDone = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logout(state, action: PayloadAction) {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logoutSuccess(state, action: PayloadAction) {
      state.loading = false;
      state.success = null;
      state.errors = [];

      state.whoamiState.loading = false;
      state.whoamiState.success = null;
      state.whoamiState.errors = [];

      state.accessToken = null;
      state.user = null;
      state.userCheckDone = false;
    },
  },
});

export const {
  login,
  loginSuccess,
  loginError,
  whoami,
  whoamiSuccess,
  whoamiError,
  logout,
  logoutSuccess,
} = loginSlice.actions;

export const { name: loginSliceName, reducer: loginSliceReducer } = loginSlice;
