import { Plugins } from '@capacitor/core';

import client from '../app/apolo.client';
import { TOKEN_KEY } from '../common/constants';
import {
  ADD_ANNOUNCEMENT,
  ADD_ASSIGNMENT,
  ADD_ASSIGNMENT_SUBMISSION,
  ADD_ATTENDANCE,
  ADD_BATCH,
  ADD_CENTER,
  ADD_CLASS,
  ADD_CONTACT_US,
  ADD_PRE_REGISTRATION_INFO,
  ADD_USER,
  CANCEL_INSTITUTE_SUBSCRIPTION,
  CHANGE_PASSWORD,
  DELETE_PAYMENT_METHOD,
  FORGOT_PASSWORD,
  GET_ANNOUNCEMENT_DEPS,
  GET_ANNOUNCEMENT_FORM_DETAILS,
  GET_ANNOUNCEMENTS_FOR_TABLE,
  GET_ASSIGNMENT_DEPS,
  GET_ASSIGNMENT_FORM_DETAILS,
  GET_ASSIGNMENT_LIST_DEPS,
  GET_ASSIGNMENT_SUBMISSION,
  GET_ASSIGNMENT_SUBMISSIONS,
  GET_ASSIGNMENTS_FOR_TABLE,
  GET_ATTENDANCE_DEPS,
  GET_ATTENDANCE_FORM_DETAILS,
  GET_ATTENDANCES_FOR_TABLE,
  GET_BATCH_DEPS,
  GET_BATCH_FORM_DETAILS,
  GET_BATCHES_FOR_TABLE,
  GET_CENTER_DEPS,
  GET_CENTER_FORM_DETAILS,
  GET_CENTERS_FOR_TABLE,
  GET_CLASS_DEPS,
  GET_CLASS_FORM_DETAILS,
  GET_CLASSES_FOR_TABLE,
  GET_INSTITUTE_OWNERS_FOR_TABLE,
  GET_NOTIFICATIONS,
  GET_USER_DEPS,
  GET_USER_FORM_DETAILS,
  GET_USER_INVOICE_FORM_DETAILS,
  GET_USER_INVOICES_FOR_TABLE,
  GET_USERS_FOR_DROPDOWN,
  GET_USERS_FOR_TABLE,
  IT_SETTING,
  LOAD_CENTER_HEAD_CONTACT_INFO,
  LOAD_INSTITUTE_SETUP_INFO,
  LOAD_INSTITUTE_WEBSITE_BASIC_INFO_DETAILS,
  LOAD_INSTITUTE_WEBSITE_CENTER_DETAILS,
  LOAD_INSTITUTE_WEBSITE_CLASS_DETAILS,
  LOAD_INSTITUTE_WEBSITE_COURSE_DETAILS,
  LOAD_INSTITUTE_WEBSITE_DETAILS,
  LOAD_INSTITUTE_WEBSITE_TEACHER_DETAILS,
  LOAD_PAYMENT_METHODS,
  LOAD_TEACHER_CONTACT_INFO,
  LOAD_USER_BANK_ACCOUNT,
  LOAD_USER_CREDIT_CARD_INFO,
  LOGIN,
  MARK_AS_READ,
  PAY_INVOICE_OFFLINE,
  PAY_INVOICE_USING_CARD,
  RECEIVE_PAYMENT_FOR_INSTITUTE,
  REMOVE_CLASS_IMAGE,
  REMOVE_COURSE_IMAGE,
  REMOVE_INSTITUTE_ABOUT_IMAGE,
  REMOVE_INSTITUTE_LOGO,
  REMOVE_INSTITUTE_WEBSITE_MEDIA,
  REMOVE_PROFILE_PHOTO,
  REMOVE_TESTIMONIAL_IMAGE,
  RENEW_INSTITUTE_SUBSCRIPTION,
  SAVE_BANK_ACCOUNT,
  SAVE_INSTITUTE_BASIC_INFO,
  SAVE_INSTITUTE_COURSES,
  SAVE_INSTITUTE_PAYMENT_INFO,
  SAVE_INSTITUTE_WEBSITE_INFO,
  SAVE_INSTITUTE_WEBSITE_MEDIA,
  SAVE_USER_CREDIT_CARD,
  SEND_PAYMENT_FOR_INSTITUTE,
  SET_DEFAULT_PAYMENT_METHOD,
  SIGNUP,
  UPLOAD_PROFILE_PHOTO,
  VERIFY_BANK_ACCOUNT,
  WHOAMI,
} from '../common/gql';
import {
  extractErrors,
  formatApiException,
  formatApiResponse,
} from '../common/helper';
import {
  AddressModel,
  AnnouncementDepsResponse,
  AnnouncementModel,
  AssignmentDepsResponse,
  AssignmentListDepsResponse,
  AssignmentModel,
  AssignmentSubmissionModel,
  AttendanceDepsResponse,
  AttendanceModel,
  BankAccountInput,
  BankAccountModel,
  BankAccountVerifyInput,
  BatchDepsResponse,
  BatchModel,
  CenterDepsResponse,
  CenterModel,
  ChangePasswordInput,
  CityModel,
  ClassDepsResponse,
  ClassModel,
  ContactUsInput,
  CreateAnnouncementInput,
  CreateAssignmentInput,
  CreateAssignmentSubmissionInput,
  CreateAttendanceInput,
  CreateBatchInput,
  CreateCenterInput,
  CreateClassInput,
  CreateUserInput,
  CreditCardModel,
  DeletePaymentMethodInput,
  ForgotPasswordInput,
  GetAnnouncementDepsQuery,
  GetAnnouncementsQuery,
  GetAssignmentDepsQuery,
  GetAssignmentListDepsQuery,
  GetAssignmentsQuery,
  GetAssignmentSubmissionQuery,
  GetAssignmentSubmissionResponse,
  GetAssignmentSubmissionsQuery,
  GetAttendanceDepsQuery,
  GetAttendancesQuery,
  GetBatchDepsQuery,
  GetBatchesQuery,
  GetCentersQuery,
  GetClassDepsQuery,
  GetClassesQuery,
  GetNotificationsQuery,
  GetUserDepsQuery,
  GetUserInvoicesQuery,
  GetUsersQuery,
  InstituteBasicInfoInput,
  InstituteBasicInfoModel,
  InstituteCourseInput,
  InstituteCourseModel,
  InstitutePaymentInfo,
  InstitutePaymentInfoInput,
  InstitutePaymentInfoResponse,
  InstitutePreRegistrationInfoModel,
  InstituteTypeModel,
  InstituteWebsiteBasicInfoDetailsResponse,
  InstituteWebsiteCourseDetailsResponse,
  InstituteWebsiteDetailsResponse,
  InstituteWebsiteInfoInput,
  InstituteWebsiteInfoModel,
  InstituteWebsiteModel,
  ItSettingsResponse,
  LoginInput,
  NotificationsResponse,
  PayInvoiceOfflineInput,
  PayInvoiceUsingCardInput,
  PaymentMethodModel,
  PreRegistrationInfoInput,
  ReceivePaymentForInstituteInput,
  RegistrationInput,
  SendPaymentForInstituteInput,
  SetDefaultPaymentMethodInput,
  UploadProfilePhotoInput,
  UserCreditCardInput,
  UserDepsResponse,
  UserInvoiceModel,
  UserModel,
  WebsiteMediaInput,
  WebsiteMediaModel,
} from '../common/types';

const storage = Plugins.Storage;

export default class ApiService {
  static async addPreRegistrationInfo(
    payload: PreRegistrationInfoInput,
  ): Promise<{
    data: InstitutePreRegistrationInfoModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_PRE_REGISTRATION_INFO,
        variables: { input: payload },
      });

      return formatApiResponse(
        'addPreRegistrationInfo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async register(
    payload: RegistrationInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SIGNUP,
        variables: { input: payload },
      });

      return formatApiResponse('signup', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async login(
    payload: LoginInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: LOGIN,
        variables: { input: payload },
      });

      return formatApiResponse('login', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async forgotPassword(
    payload: ForgotPasswordInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: FORGOT_PASSWORD,
        variables: { input: payload },
      });

      return formatApiResponse(
        'forgotPassword',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async changePassword(
    payload: ChangePasswordInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: CHANGE_PASSWORD,
        variables: { input: payload },
      });

      return formatApiResponse(
        'changePassword',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async whoami(): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({ query: WHOAMI });
      return formatApiResponse('whoAmI', data, errors ? [...errors] : null);
    } catch (e) {
      if (e.message === 'Unauthorized') {
        await storage.remove({ key: TOKEN_KEY });
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async itSetting(): Promise<{
    data: ItSettingsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({ query: IT_SETTING });

      const navMenu = data
        ? formatApiResponse('navMenu', data, null).data
        : null;
      const routePermissions = data
        ? formatApiResponse('routePermissions', data, null).data
        : null;
      const stripe = data ? formatApiResponse('stripe', data, null).data : null;
      const reservedSubdomain = data
        ? formatApiResponse('reservedSubdomain', data, null).data
        : null;
      const subscriptionPlanFirst = data
        ? formatApiResponse('subscriptionPlanFirst', data, null).data
        : null;

      return {
        data: {
          navMenu: navMenu ? JSON.parse(navMenu) : null,
          routePermissions: routePermissions
            ? JSON.parse(routePermissions)
            : null,
          stripe: stripe ? JSON.parse(stripe) : null,
          reservedSubdomain: reservedSubdomain
            ? JSON.parse(reservedSubdomain)
            : null,
          subscriptionPlanFirst: subscriptionPlanFirst || null,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadInstituteSetupInfo(): Promise<{
    data: {
      instituteBasicInfo: InstituteBasicInfoModel | null;
      instituteWebsiteInfo: InstituteWebsiteInfoModel | null;
      instituteCourses: InstituteCourseModel[] | null;
      websiteMedia: WebsiteMediaModel[] | null;
      instituteTypes: InstituteTypeModel[] | null;
      websiteDefaultMainImageUrl: string | null;
      institutePaymentInfo: InstitutePaymentInfo | null;
      cities: CityModel[] | null;
      instituteAddress: AddressModel[] | null;
    } | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_INSTITUTE_SETUP_INFO,
      });

      const instituteBasicInfo: InstituteBasicInfoModel = data
        ? formatApiResponse('instituteBasicInfo', data, null).data
        : null;

      const instituteWebsiteInfo: InstituteWebsiteInfoModel = data
        ? formatApiResponse('instituteWebsiteInfo', data, null).data
        : null;

      const instituteCourses: InstituteCourseModel[] = data
        ? formatApiResponse('instituteCourses', data, null).data
        : null;

      const websiteMediaData = data
        ? formatApiResponse('instituteWebsiteMedia', data, null).data
        : null;

      const websiteMedia: WebsiteMediaModel[] = websiteMediaData
        ? websiteMediaData.media
        : [];

      const instituteTypes: InstituteTypeModel[] = data
        ? formatApiResponse('instituteTypes', data, null).data
        : null;

      const websiteDefaultMainImageData = data
        ? formatApiResponse('websiteDefaultMainImage', data, null).data
        : null;

      const websiteDefaultMainImageUrl: string = websiteDefaultMainImageData
        ? websiteDefaultMainImageData.defaultMainImageUrl
        : null;

      const institutePaymentInfo: InstitutePaymentInfo = data
        ? formatApiResponse('institutePaymentInfo', data, null).data
        : null;

      const cities = data ? formatApiResponse('cities', data, null).data : null;
      const instituteAddressData = data
        ? formatApiResponse('instituteAddress', data, null).data
        : null;

      const instituteAddress = instituteAddressData?.address || null;

      return {
        data: {
          instituteBasicInfo,
          instituteWebsiteInfo,
          instituteCourses,
          websiteMedia,
          instituteTypes,
          websiteDefaultMainImageUrl,
          institutePaymentInfo,
          cities,
          instituteAddress,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async saveInstituteBasicInfo(
    payload: InstituteBasicInfoInput,
  ): Promise<{
    data: InstituteBasicInfoModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SAVE_INSTITUTE_BASIC_INFO,
        variables: { input: payload },
      });

      return formatApiResponse(
        'updateInstituteBasicInfo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async saveInstituteWebsiteInfo(
    payload: InstituteWebsiteInfoInput,
  ): Promise<{
    data: InstituteWebsiteInfoModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SAVE_INSTITUTE_WEBSITE_INFO,
        variables: { input: payload },
      });

      return formatApiResponse(
        'updateInstituteWebsiteInfo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async saveInstituteCourses(
    payload: InstituteCourseInput[],
  ): Promise<{
    data: InstituteCourseModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SAVE_INSTITUTE_COURSES,
        variables: { input: payload },
      });

      return formatApiResponse(
        'saveInstituteCourses',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async saveWebsiteMedia(payload: {
    input: WebsiteMediaInput[];
    defaultMainImage: string;
  }): Promise<{
    data: WebsiteMediaModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SAVE_INSTITUTE_WEBSITE_MEDIA,
        variables: { ...payload },
      });

      return formatApiResponse(
        'saveInstituteWebsiteMedia',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadInstituteWebsiteDetails(
    domain: string,
  ): Promise<{
    data: InstituteWebsiteDetailsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_INSTITUTE_WEBSITE_DETAILS,
        variables: { domain },
      });

      const instituteWebsite: InstituteWebsiteModel = data
        ? formatApiResponse('instituteWebsite', data, null).data
        : null;

      return {
        data: {
          instituteWebsite,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadInstituteWebsiteBasicInfoDetails(
    domain: string,
  ): Promise<{
    data: InstituteWebsiteBasicInfoDetailsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_INSTITUTE_WEBSITE_BASIC_INFO_DETAILS,
        variables: { domain },
      });

      const instituteWebsiteBasicInfo: InstituteBasicInfoModel = data
        ? formatApiResponse('instituteWebsiteBasicInfo', data, null).data
        : null;

      return {
        data: {
          instituteWebsiteBasicInfo,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadInstituteWebsiteCenterDetails(
    query: GetCentersQuery,
  ): Promise<{
    data: CenterModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_INSTITUTE_WEBSITE_CENTER_DETAILS,
        variables: { query },
      });

      return formatApiResponse(
        'instituteWebsiteCenterInfo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadInstituteWebsiteCourseDetails(
    domain: string,
  ): Promise<{
    data: InstituteWebsiteCourseDetailsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_INSTITUTE_WEBSITE_COURSE_DETAILS,
        variables: { domain },
      });

      const instituteWebsiteCourseInfo: InstituteCourseModel[] = data
        ? formatApiResponse('instituteWebsiteCourseInfo', data, null).data
        : null;

      return {
        data: {
          instituteWebsiteCourseInfo,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadInstituteWebsiteClassDetails(
    query: GetClassesQuery,
  ): Promise<{
    data: ClassModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_INSTITUTE_WEBSITE_CLASS_DETAILS,
        variables: { query },
      });

      return formatApiResponse(
        'instituteWebsiteClassInfo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadInstituteWebsiteTeacherDetails(
    query: GetUsersQuery,
  ): Promise<{
    data: UserModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_INSTITUTE_WEBSITE_TEACHER_DETAILS,
        variables: { query },
      });

      return formatApiResponse(
        'instituteWebsiteTeacherInfo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addCenter(
    payload: CreateCenterInput,
  ): Promise<{
    data: CenterModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_CENTER,
        variables: { input: payload },
      });

      return formatApiResponse('addCenter', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadCenterFormDetails(
    id: string,
  ): Promise<{
    data: CenterModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_CENTER_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse('getCenter', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getCentersForTable(
    query: GetCentersQuery,
  ): Promise<{
    data: CenterModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_CENTERS_FOR_TABLE,
        variables: { query },
      });

      return formatApiResponse('getCenters', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addClass(
    payload: CreateClassInput,
  ): Promise<{
    data: ClassModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_CLASS,
        variables: { input: payload },
      });

      return formatApiResponse('addClass', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadClassFormDetails(
    id: string,
  ): Promise<{
    data: ClassModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_CLASS_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse('getClass', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getClassesForTable(
    query: GetClassesQuery,
  ): Promise<{
    data: ClassModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_CLASSES_FOR_TABLE,
        variables: { query },
      });

      return formatApiResponse('getClasses', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addUser(
    payload: CreateUserInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_USER,
        variables: { input: payload },
      });

      return formatApiResponse('addUser', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async uploadProfilePhoto(
    payload: UploadProfilePhotoInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: UPLOAD_PROFILE_PHOTO,
        variables: { input: payload },
      });

      return formatApiResponse(
        'uploadProfilePhoto',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async removeProfilePhoto(
    id: string,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: REMOVE_PROFILE_PHOTO,
        variables: { id },
      });

      return formatApiResponse(
        'removeProfilePhoto',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadUserFormDetails(
    id: string,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_USER_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse('getUser', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getUsersForDropdown(
    query: GetUsersQuery,
  ): Promise<{
    data: UserModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_USERS_FOR_DROPDOWN,
        variables: { query },
      });

      return formatApiResponse('getUsers', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getUsersForTable(
    query: GetUsersQuery,
  ): Promise<{
    data: UserModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_USERS_FOR_TABLE,
        variables: { query },
      });

      return formatApiResponse('getUsers', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addBatch(
    payload: CreateBatchInput,
  ): Promise<{
    data: BatchModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_BATCH,
        variables: { input: payload },
      });

      return formatApiResponse('addBatch', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadBatchFormDetails(
    id: string,
  ): Promise<{
    data: BatchModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_BATCH_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse('getBatch', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getBatchesForTable(
    query: GetBatchesQuery,
  ): Promise<{
    data: UserModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_BATCHES_FOR_TABLE,
        variables: { query },
      });

      return formatApiResponse('getBatches', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getUserDeps(
    query: GetUserDepsQuery,
  ): Promise<{
    data: UserDepsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_USER_DEPS,
        variables: query,
      });

      const parents = data
        ? formatApiResponse('parents', data, null).data
        : null;

      const centers = data
        ? formatApiResponse('centers', data, null).data
        : null;

      const batches = data
        ? formatApiResponse('batches', data, null).data
        : null;

      return {
        data: {
          parents,
          centers,
          batches,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getBatchDeps(
    query: GetBatchDepsQuery,
  ): Promise<{
    data: BatchDepsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_BATCH_DEPS,
        variables: query,
      });

      const centers = data
        ? formatApiResponse('centers', data, null).data
        : null;

      const classes = data
        ? formatApiResponse('classes', data, null).data
        : null;

      return {
        data: {
          centers,
          classes,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getClassDeps(
    query: GetClassDepsQuery,
  ): Promise<{
    data: ClassDepsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_CLASS_DEPS,
        variables: query,
      });

      const courses = data
        ? formatApiResponse('courses', data, null).data
        : null;

      return {
        data: {
          courses,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async removeInstituteLogo(): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: REMOVE_INSTITUTE_LOGO,
      });

      return formatApiResponse(
        'removeInstituteLogo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async removeInstituteAboutImage(): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: REMOVE_INSTITUTE_ABOUT_IMAGE,
      });

      return formatApiResponse(
        'removeInstituteAboutImage',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async removeTestimonialImage(
    id: string,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: REMOVE_TESTIMONIAL_IMAGE,
        variables: { id },
      });

      return formatApiResponse(
        'removeTestimonialImage',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async removeCourseImage(
    id: string,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: REMOVE_COURSE_IMAGE,
        variables: { id },
      });

      return formatApiResponse(
        'removeCourseImage',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async removeClassImage(
    id: string,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: REMOVE_CLASS_IMAGE,
        variables: { id },
      });

      return formatApiResponse(
        'removeClassImage',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async removeInstituteWebsiteMedia(
    id: string,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: REMOVE_INSTITUTE_WEBSITE_MEDIA,
        variables: { id },
      });

      return formatApiResponse(
        'removeInstituteWebsiteMedia',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async saveInstitutePaymentInfo(
    payload: InstitutePaymentInfoInput,
  ): Promise<{
    data: InstitutePaymentInfoResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SAVE_INSTITUTE_PAYMENT_INFO,
        variables: { input: payload },
      });

      return formatApiResponse(
        'updateInstitutePaymentInfo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadProfileInfo(): Promise<{
    data: CreditCardModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_USER_CREDIT_CARD_INFO,
      });

      const userCreditCardInfo: CreditCardModel = data
        ? formatApiResponse('userCreditCardInfo', data, null).data
        : null;

      return {
        data: userCreditCardInfo,
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async saveUserCreditCard(
    payload: UserCreditCardInput,
  ): Promise<{
    data: CreditCardModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SAVE_USER_CREDIT_CARD,
        variables: { input: payload },
      });

      return formatApiResponse(
        'saveUserCreditCard',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async cancelInstituteSubscription(): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: CANCEL_INSTITUTE_SUBSCRIPTION,
      });

      return formatApiResponse(
        'cancelInstituteSubscription',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async renewInstituteSubscription(): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: RENEW_INSTITUTE_SUBSCRIPTION,
      });

      return formatApiResponse(
        'renewInstituteSubscription',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadCenterHeadContactInfo(
    query: GetUsersQuery,
  ): Promise<{
    data: UserModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_CENTER_HEAD_CONTACT_INFO,
        variables: { query },
      });

      return formatApiResponse(
        'centerHeadContactInfo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadTeacherContactInfo(
    query: GetUsersQuery,
  ): Promise<{
    data: UserModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_TEACHER_CONTACT_INFO,
        variables: { query },
      });

      return formatApiResponse(
        'teacherContactInfo',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getCenterDeps(): Promise<{
    data: CenterDepsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_CENTER_DEPS,
      });

      const cities = data ? formatApiResponse('cities', data, null).data : null;

      return {
        data: {
          cities,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async payInvoiceUsingCard(
    payload: PayInvoiceUsingCardInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: PAY_INVOICE_USING_CARD,
        variables: { input: payload },
      });

      return formatApiResponse(
        'payInvoiceUsingCard',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async payInvoiceOffline(
    payload: PayInvoiceOfflineInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: PAY_INVOICE_OFFLINE,
        variables: { input: payload },
      });

      return formatApiResponse(
        'payInvoiceOffline',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadUserInvoiceFormDetails(
    id: string,
  ): Promise<{
    data: UserInvoiceModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_USER_INVOICE_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse(
        'getUserInvoice',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getUserInvoicesForTable(
    query: GetUserInvoicesQuery,
  ): Promise<{
    data: UserInvoiceModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_USER_INVOICES_FOR_TABLE,
        variables: { query },
      });

      return formatApiResponse(
        'getUserInvoices',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getInstiuteOwnersForTable(): Promise<{
    data: UserModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_INSTITUTE_OWNERS_FOR_TABLE,
        variables: {},
      });

      return formatApiResponse(
        'getInstituteOwners',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async receivePaymentForInstitute(
    payload: ReceivePaymentForInstituteInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: RECEIVE_PAYMENT_FOR_INSTITUTE,
        variables: { input: payload },
      });

      return formatApiResponse(
        'receivePaymentForInstitute',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async sendPaymentForInstitute(
    payload: SendPaymentForInstituteInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SEND_PAYMENT_FOR_INSTITUTE,
        variables: { input: payload },
      });

      return formatApiResponse(
        'sendPaymentForInstitute',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addAnnouncement(
    payload: CreateAnnouncementInput,
  ): Promise<{
    data: AnnouncementModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_ANNOUNCEMENT,
        variables: { input: payload },
      });

      return formatApiResponse(
        'addAnnouncement',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadAnnouncementFormDetails(
    id: string,
  ): Promise<{
    data: AnnouncementModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ANNOUNCEMENT_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse(
        'getAnnouncement',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getAnnouncementsForTable(
    query: GetAnnouncementsQuery,
  ): Promise<{
    data: AnnouncementModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ANNOUNCEMENTS_FOR_TABLE,
        variables: { query },
      });

      return formatApiResponse(
        'getAnnouncements',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getAnnouncementDeps(
    query: GetAnnouncementDepsQuery,
  ): Promise<{
    data: AnnouncementDepsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ANNOUNCEMENT_DEPS,
        variables: query,
      });

      const centers = data
        ? formatApiResponse('centers', data, null).data
        : null;

      const batches = data
        ? formatApiResponse('batches', data, null).data
        : null;

      const students = data
        ? formatApiResponse('students', data, null).data
        : null;

      return {
        data: {
          centers,
          batches,
          students,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addAttendance(
    payload: CreateAttendanceInput,
  ): Promise<{
    data: AttendanceModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_ATTENDANCE,
        variables: { input: payload },
      });

      return formatApiResponse(
        'addAttendance',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadAttendanceFormDetails(
    id: string,
  ): Promise<{
    data: AttendanceModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ATTENDANCE_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse(
        'getAttendance',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getAttendancesForTable(
    query: GetAttendancesQuery,
  ): Promise<{
    data: AttendanceModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ATTENDANCES_FOR_TABLE,
        variables: { query },
      });

      return formatApiResponse(
        'getAttendances',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getAttendanceDeps(
    query: GetAttendanceDepsQuery,
  ): Promise<{
    data: AttendanceDepsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ATTENDANCE_DEPS,
        variables: query,
      });

      const students = data
        ? formatApiResponse('students', data, null).data
        : null;
      const batch = data ? formatApiResponse('batch', data, null).data : null;

      return {
        data: {
          batch,
          students,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addAssignment(
    payload: CreateAssignmentInput,
  ): Promise<{
    data: AssignmentModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_ASSIGNMENT,
        variables: { input: payload },
      });

      return formatApiResponse(
        'addAssignment',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadAssignmentFormDetails(
    id: string,
  ): Promise<{
    data: AssignmentModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ASSIGNMENT_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse(
        'getAssignment',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getAssignmentsForTable(
    query: GetAssignmentsQuery,
  ): Promise<{
    data: AssignmentModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ASSIGNMENTS_FOR_TABLE,
        variables: { query },
      });

      return formatApiResponse(
        'getAssignments',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getAssignmentDeps(
    query: GetAssignmentDepsQuery,
  ): Promise<{
    data: AssignmentDepsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ASSIGNMENT_DEPS,
        variables: query,
      });

      const students = data
        ? formatApiResponse('students', data, null).data
        : null;
      const batch = data ? formatApiResponse('batch', data, null).data : null;

      return {
        data: {
          batch,
          students,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getAssignmentListDeps(
    query: GetAssignmentListDepsQuery,
  ): Promise<{
    data: AssignmentListDepsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ASSIGNMENT_LIST_DEPS,
        variables: query,
      });

      const students = data
        ? formatApiResponse('students', data, null).data
        : null;

      return {
        data: {
          students,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addAssignmentSubmission(
    payload: CreateAssignmentSubmissionInput,
  ): Promise<{
    data: AssignmentSubmissionModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_ASSIGNMENT_SUBMISSION,
        variables: { input: payload },
      });

      return formatApiResponse(
        'addAssignmentSubmission',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getAssignmentSubmission(
    query: GetAssignmentSubmissionQuery,
  ): Promise<{
    data: GetAssignmentSubmissionResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ASSIGNMENT_SUBMISSION,
        variables: query,
      });

      const student = data
        ? formatApiResponse('student', data, null).data
        : null;
      const assignment = data
        ? formatApiResponse('assignment', data, null).data
        : null;
      const assignmentSubmissionData = data
        ? formatApiResponse('assignmentSubmission', data, null).data
        : null;

      const assignmentSubmission = assignmentSubmissionData?.[0] || null;

      return {
        data: {
          student,
          assignment,
          assignmentSubmission,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getAssignmentSubmissions(
    query: GetAssignmentSubmissionsQuery,
  ): Promise<{
    data: AssignmentSubmissionModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_ASSIGNMENT_SUBMISSIONS,
        variables: query,
      });

      return formatApiResponse(
        'getAssignmentSubmissions',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getNotifications(
    query: GetNotificationsQuery,
  ): Promise<{
    data: NotificationsResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_NOTIFICATIONS,
        variables: query,
      });

      const notifications = data
        ? formatApiResponse('notifications', data, null).data
        : null;

      return {
        data: {
          notifications,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async markAsRead(payload: {
    id: string;
  }): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: MARK_AS_READ,
        variables: { ...payload },
      });

      return formatApiResponse('markAsRead', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addContactUs(
    payload: ContactUsInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_CONTACT_US,
        variables: { input: payload },
      });

      return formatApiResponse(
        'addContactUs',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async saveBankAccount(
    payload: BankAccountInput,
  ): Promise<{
    data: BankAccountModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SAVE_BANK_ACCOUNT,
        variables: { input: payload },
      });

      return formatApiResponse(
        'saveBankAccount',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadBankAccountInfo(): Promise<{
    data: BankAccountModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_USER_BANK_ACCOUNT,
      });

      const userBankAccount: BankAccountModel = data
        ? formatApiResponse('userBankAccount', data, null).data
        : null;

      return {
        data: userBankAccount,
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadPaymentMethods(): Promise<{
    data: PaymentMethodModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_PAYMENT_METHODS,
      });

      const paymentMethods: PaymentMethodModel[] = data
        ? formatApiResponse('paymentMethods', data, null).data
        : null;

      return {
        data: paymentMethods,
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async verifyBankAccount(
    payload: BankAccountVerifyInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: VERIFY_BANK_ACCOUNT,
        variables: { input: payload },
      });

      return formatApiResponse(
        'verifyBankAccount',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async setDefaultPaymentMethod(
    payload: SetDefaultPaymentMethodInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SET_DEFAULT_PAYMENT_METHOD,
        variables: { input: payload },
      });

      return formatApiResponse(
        'setDefaultPaymentMethod',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async deletePaymentMethod(
    payload: DeletePaymentMethodInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: DELETE_PAYMENT_METHOD,
        variables: { input: payload },
      });

      return formatApiResponse(
        'deletePaymentMethod',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }
}
