import { IonButton, IonInput, IonItem, IonText } from '@ionic/react';
import { Form, Formik } from 'formik';
import React from 'react';

import { formatErrors, getLangAndErrKeys } from '../../../common/helper';
import LanguageTexts from '../../../common/language';

type BankAccountVerifyProps = {
  onSubmit: (data: {
    amount1: number | undefined;
    amount2: number | undefined;
  }) => void;
  errors: string[];
  loading: boolean;
};

const BankAccountVerifyForm: React.FC<BankAccountVerifyProps> = ({
  onSubmit,
  errors,
  loading,
}: BankAccountVerifyProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const initialValues: {
    amount1: string | undefined;
    amount2: string | undefined;
  } = {
    amount1: undefined,
    amount2: undefined,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit({
          amount1: values.amount1 ? parseFloat(values.amount1) : undefined,
          amount2: values.amount2 ? parseFloat(values.amount2) : undefined,
        });
        setSubmitting(false);

        return null;
      }}
    >
      {({ values, handleChange }) => {
        const errorKeys = getLangAndErrKeys(values);

        const formattedErrors = formatErrors(errorKeys, errors, profileTxt);

        return (
          <Form>
            <IonItem lines="none" className="inputField mb-1">
              <IonInput
                placeholder={profileTxt.amountOne}
                name="amount1"
                type="text"
                className="font-weight-bold"
                color="secondary"
                onIonChange={handleChange}
              />
            </IonItem>
            <IonItem lines="none" className="inputField mb-1">
              <IonInput
                placeholder={profileTxt.amountTwo}
                name="amount2"
                type="text"
                className="font-weight-bold"
                color="secondary"
                onIonChange={handleChange}
              />
            </IonItem>
            <div className="mb-1 error-msg">
              {formattedErrors.map((error) => (
                <IonText color="secondary">
                  <p style={{ fontWeight: 'bold' }} key={error}>
                    {error}
                  </p>
                </IonText>
              ))}
            </div>
            <div className="text-right mb-1">
              <IonButton type="submit" disabled={loading}>
                {profileTxt.btnSave}&nbsp;
              </IonButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BankAccountVerifyForm;
