import './AssignmentPage.scss';

import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CreateAssignmentSubmissionInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import TopHeader from '../../components/TopHeader';
import AssignmentDetail from './AssignmentDetail';
import {
  addAssignmentSubmission,
  resetAddAssignmentSubmissionSuccess,
} from './assignments.slice';

const AssignmentSubmitPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    addAssignmentSubmission: {
      loading: addAssignmentSubmissionLoading,
      success: addAssignmentSubmissionSuccess,
      errors: addAssignmentSubmissionErrors,
    },
  } = useSelector((state: RootState) => state.assignments);

  function onAssignmentSubmissionSubmit({
    ...data
  }: CreateAssignmentSubmissionInput) {
    dispatch(
      addAssignmentSubmission({
        ...data,
      }),
    );
  }

  useEffect(() => {
    if (addAssignmentSubmissionSuccess) {
      dispatch(resetAddAssignmentSubmissionSuccess());
    }
  }, [addAssignmentSubmissionSuccess, dispatch, history]);

  return (
    <IonPage>
      <TopHeader />
      <IonContent className="main-content assignment-submit" fullscreen>
        {!addAssignmentSubmissionSuccess ? (
          <AssignmentDetail
            key="createassignmentsubmissionform"
            errors={addAssignmentSubmissionErrors}
            loading={addAssignmentSubmissionLoading}
            onSubmit={onAssignmentSubmissionSubmit}
          />
        ) : (
          <AppLoader />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AssignmentSubmitPage;
