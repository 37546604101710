import { IonText } from '@ionic/react';
import React from 'react';

type HeadingProps = {
  title: string;
  iconName: string;
};

const Heading: React.FC<HeadingProps> = ({
  title,
  iconName,
}: HeadingProps): JSX.Element => {
  return (
    <h1 className="flex-row-align-center font-weight-bold mb-2">
      <i className={`${iconName} ml mr heading-icon`} />
      <IonText color="secondary">{title}</IonText>
    </h1>
  );
};

export default Heading;
