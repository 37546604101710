/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContactUsState, GetUsersQuery, UserModel } from '../../common/types';

const initialState: ContactUsState = {
  loading: false,
  success: null,
  errors: [],
  centerHeadContactInfo: null,
  teacherContactInfo: null,
};

const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    loadCenterHeadContactInfo(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetUsersQuery>,
    ) {
      state.loading = true;
      state.errors = [];
    },
    loadCenterHeadContactInfoSuccess(
      state,
      action: PayloadAction<UserModel[]>,
    ) {
      state.loading = false;
      state.success = !!action.payload;
      state.centerHeadContactInfo = action.payload;
    },
    loadCenterHeadContactInfoError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    loadTeacherContactInfo(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetUsersQuery>,
    ) {
      state.loading = true;
      state.errors = [];
    },
    loadTeacherContactInfoSuccess(state, action: PayloadAction<UserModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.teacherContactInfo = action.payload;
    },
    loadTeacherContactInfoError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  loadCenterHeadContactInfo,
  loadCenterHeadContactInfoSuccess,
  loadCenterHeadContactInfoError,
  loadTeacherContactInfo,
  loadTeacherContactInfoSuccess,
  loadTeacherContactInfoError,
} = contactSlice.actions;

export const {
  name: contactSliceName,
  reducer: contactSliceReducer,
} = contactSlice;
