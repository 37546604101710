/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonButton } from '@ionic/react';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { RootState, UploadProfilePhotoInput } from '../../../common/types';
import ImageUpload from '../../../components/ImageUpload';
import { loadProfileInfo } from '../profile.slice';

type UploadProfilePhotoFormProps = {
  onSubmit: (data: UploadProfilePhotoInput) => void;
  onRemoveProfilePhoto: (id: string) => void;
  loading: boolean;
  initialValues: UploadProfilePhotoInput;
};

const UploadProfilePhotoForm: React.FC<UploadProfilePhotoFormProps> = ({
  onSubmit,
  onRemoveProfilePhoto,
  loading,
  initialValues,
}: UploadProfilePhotoFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const { profile: profileTxt } = LanguageTexts;

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { user } = useSelector((state: RootState) => state.login);
  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (!infoLoaded) {
      dispatch(loadProfileInfo());
      setInfoLoaded(true);
    }
  }, [dispatch, infoLoaded]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        if (selectedImage) {
          input.profilePic = selectedImage;
        }

        onSubmit(input);
        setSubmitting(false);
      }}
    >
      {() => {
        return (
          <Form>
            <ImageUpload
              id="upload-profile-photo"
              label=""
              onFileSelect={(file) => {
                setSelectedImage(file);
              }}
              url={user?.profilePicUrl}
              maxFileSize={1024 * 5}
              fileResolution={profileTxt.resolutionSize}
              disabled={loading}
              onRemoveUrl={() =>
                user?._id ? onRemoveProfilePhoto(user?._id) : null
              }
            />

            <div className="text-right">
              <IonButton type="submit" disabled={loading}>
                {profileTxt.uploadBtn}
              </IonButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UploadProfilePhotoForm;
