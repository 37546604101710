/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonButton, IonInput, IonItem, IonText } from '@ionic/react';
import { Form, Formik } from 'formik';
import React from 'react';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { ForgotPasswordInput } from '../../common/types';

type IForgotPasswordProps = {
  onSubmit: (data: ForgotPasswordInput) => void;
  errors: string[];
  loading: boolean;
};

const ForgotPasswordForm: React.FC<IForgotPasswordProps> = ({
  onSubmit,
  errors,
  loading,
}: IForgotPasswordProps): JSX.Element => {
  const { forgotPassword: forgotPasswordText } = LanguageTexts;

  const initialValues: ForgotPasswordInput = {
    email: '',
  };

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(errorKeys, errors, forgotPasswordText);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <IonItem lines="none" className="inputField mb-1">
              <IonInput
                placeholder={forgotPasswordText['input.email']}
                name="email"
                type="text"
                className="font-weight-bold"
                color="secondary"
                value={values.email}
                onIonChange={handleChange}
              />
            </IonItem>
            <div className="mb-1 error-msg">
              {formattedErrors.map((error) => {
                return (
                  <IonText color="secondary">
                    <p style={{ fontWeight: 'bold' }} key={error}>
                      {error}
                    </p>
                  </IonText>
                );
              })}
            </div>
            <div className="mb-1">
              <IonButton
                className="mb-2"
                expand="block"
                type="submit"
                disabled={loading}
              >
                {forgotPasswordText.submitBtn}
              </IonButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

ForgotPasswordForm.defaultProps = {};

export default ForgotPasswordForm;
