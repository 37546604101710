/* eslint-disable no-nested-ternary */
import { IonButton, IonToast } from '@ionic/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { PaymentMethodType } from '../../../common/constants';
import LanguageTexts from '../../../common/language';
import { RootState } from '../../../common/types';
import AppLoader from '../../../components/AppLoader';
import PaymentMethodForm from './PaymentMethodForm';
import PaymentMethodList from './PaymentMethodList';

type PaymentMethodPageProps = {
  infoLoaded: boolean;
};

const PaymentMethodPage: React.FC<PaymentMethodPageProps> = ({
  infoLoaded,
}: PaymentMethodPageProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const [showForm, setShowForm] = useState(false);
  const {
    paymentMethods,
    loadPaymentMethods: { loading: loadPaymentMethodsLoading },
  } = useSelector((state: RootState) => state.profile);
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);

  return (
    <>
      {showForm ? (
        <PaymentMethodForm
          initialValues={{ paymentMethodType: PaymentMethodType.Card }}
          infoLoaded={infoLoaded}
          onCancel={() => setShowForm(false)}
        />
      ) : (
        <IonButton
          type="button"
          onClick={() => setShowForm(true)}
          className="add-payment-method-btn"
        >
          {profileTxt.btnAddPaymentMethod}&nbsp;
        </IonButton>
      )}
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={profileTxt.setDefaultPaymentMethodSuccess}
        duration={5000}
        color="dark"
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={profileTxt.deletePaymentMethodSuccess}
        duration={5000}
        color="dark"
      />
      {loadPaymentMethodsLoading ? (
        <AppLoader />
      ) : paymentMethods ? (
        <PaymentMethodList
          paymentMethods={paymentMethods}
          onDefaultSuccess={(success) => setShowToast1(success)}
          onDeleteSuccess={(success) => setShowToast2(success)}
        />
      ) : null}
    </>
  );
};

export default PaymentMethodPage;
