import { IonAvatar, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppRoutePath, NotificationType } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { NotificationModel, RootState } from '../../common/types';
import {
  loadNotifications,
  markAsRead,
  resetMarkAsReadSuccess,
} from './notification.slice';

type NotificationListProps = {
  notifications: NotificationModel[];
};

const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
}: NotificationListProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { notification: notificationTxt } = LanguageTexts;
  const {
    markAsRead: { success, loading },
  } = useSelector((state: RootState) => state.notification);

  function readNotification(notification: NotificationModel) {
    if (!loading) {
      dispatch(markAsRead(notification));
    }
  }

  const navigateForNotification = useCallback(
    (notification: NotificationModel) => {
      const { payload } = notification;

      switch (payload.notificationType) {
        case NotificationType.FeesDue:
          break;
        case NotificationType.BatchScheduleChange:
          history.push(AppRoutePath.ClassSchedule);
          break;
        case NotificationType.NewAnnouncement:
          history.push(AppRoutePath.Announcements);
          break;
        case NotificationType.NewAssignment:
          history.push(
            AppRoutePath.AssignmentsSubmit.replace(
              ':assignmentId',
              payload.assignmentId,
            ).replace(':studentId', notification.userId),
          );
          break;
        case NotificationType.AssignmentSubmissionStatusChange:
          history.push(
            AppRoutePath.AssignmentsSubmit.replace(
              ':assignmentId',
              payload.assignmentId,
            ).replace(':studentId', notification.userId),
          );
          break;
        default:
          break;
      }
    },
    [history],
  );

  useEffect(() => {
    if (success) {
      dispatch(resetMarkAsReadSuccess());
      dispatch(loadNotifications({ readByUser: false }));
      navigateForNotification(success);
    }
  }, [dispatch, navigateForNotification, success]);

  return (
    <IonList className="notification-list">
      {notifications.map((notification) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { _id, payload } = notification;

        switch (payload.notificationType) {
          case NotificationType.FeesDue:
            return (
              <React.Fragment key={_id}>
                <IonItem
                  lines="none"
                  className="notification mb-1"
                  onClick={() => {
                    readNotification(notification);
                  }}
                >
                  <IonAvatar slot="start">
                    <i className="fas fa-dollar-sign notification-icon" />
                  </IonAvatar>
                  <IonLabel>
                    <IonText color="secondary">
                      <h2>{payload.userInvoice.batch.name}</h2>
                    </IonText>
                    <IonText color="secondary">
                      <p>
                        {notificationTxt.feesDue
                          .replace('{currencySymbol}', payload.currencySymbol)
                          .replace('{amountDue}', payload.amountDue.toString())}
                      </p>
                    </IonText>
                  </IonLabel>
                </IonItem>
              </React.Fragment>
            );
          case NotificationType.BatchScheduleChange:
            return (
              <React.Fragment key={_id}>
                <IonItem
                  lines="none"
                  className="notification mb-1"
                  onClick={() => {
                    readNotification(notification);
                  }}
                >
                  <IonAvatar slot="start">
                    <i className="fas fa-clock notification-icon" />
                  </IonAvatar>
                  <IonLabel>
                    <IonText color="secondary">
                      <h2>{payload.batch.name}</h2>
                    </IonText>
                    <IonText color="secondary">
                      <p>{notificationTxt.batchScheduleChange}</p>
                    </IonText>
                  </IonLabel>
                </IonItem>
              </React.Fragment>
            );
          case NotificationType.NewAnnouncement:
            return (
              <React.Fragment key={_id}>
                <IonItem
                  lines="none"
                  className="notification mb-1"
                  onClick={() => {
                    readNotification(notification);
                  }}
                >
                  <IonAvatar slot="start">
                    <i className="fas fa-bullhorn notification-icon" />
                  </IonAvatar>
                  <IonLabel>
                    <IonText color="secondary">
                      <h2>
                        {payload.announcement.createdByUser.name ||
                          payload.announcement.createdByUser.domain}
                      </h2>
                    </IonText>
                    <IonText color="secondary">
                      <p>{payload.heading}</p>
                    </IonText>
                  </IonLabel>
                </IonItem>
              </React.Fragment>
            );
          case NotificationType.NewAssignment:
            return (
              <React.Fragment key={_id}>
                <IonItem
                  lines="none"
                  className="notification mb-1"
                  onClick={() => {
                    readNotification(notification);
                  }}
                >
                  <IonAvatar slot="start">
                    <i className="fas fa-file-alt notification-icon" />
                  </IonAvatar>
                  <IonLabel>
                    <IonText color="secondary">
                      <h2>{payload.assignment.createdByUser.name}</h2>
                    </IonText>
                    <IonText color="secondary">
                      <p>{notificationTxt.newAssignment}</p>
                    </IonText>
                  </IonLabel>
                </IonItem>
              </React.Fragment>
            );
          case NotificationType.AssignmentSubmissionStatusChange:
            return (
              <React.Fragment key={_id}>
                <IonItem
                  lines="none"
                  className="notification mb-1"
                  onClick={() => {
                    readNotification(notification);
                  }}
                >
                  <IonAvatar slot="start">
                    <i className="fas fa-file-alt notification-icon" />
                  </IonAvatar>
                  <IonLabel>
                    <IonText color="secondary">
                      <h2>{payload.assignment.createdByUser.name}</h2>
                    </IonText>
                    <IonText color="secondary">
                      <p>
                        {notificationTxt.assignmentStatusChange.replace(
                          '{status}',
                          payload.submissionData.submissionStatus,
                        )}
                      </p>
                    </IonText>
                  </IonLabel>
                </IonItem>
              </React.Fragment>
            );
          default:
            return null;
        }
      })}
    </IonList>
  );
};

export default NotificationList;
