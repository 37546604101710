/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Plugins } from '@capacitor/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { resetSelectedFilter } from '../../app/app.slice';
import { ErrorCodes, TOKEN_KEY, UserRole } from '../../common/constants';
import { LoginInput } from '../../common/types';
import ApiService from '../../services/api';
import {
  login,
  loginError,
  loginSuccess,
  logout,
  logoutSuccess,
  whoami,
  whoamiError,
  whoamiSuccess,
} from './login.slice';

const storage = Plugins.Storage;

export function* loginAsync(action: PayloadAction<LoginInput>) {
  const { data, errors } = yield call(ApiService.login, action.payload);

  if (errors) {
    yield put(loginError(errors));
  } else if (
    data.role.includes(UserRole.Student) ||
    data.role.includes(UserRole.Parent)
  ) {
    yield call([storage, storage.set], {
      key: TOKEN_KEY,
      value: data.accessToken,
    });
    yield put(loginSuccess(data));
    yield put(push('/'));
  } else {
    yield put(loginError([ErrorCodes.LoginNotAllowed]));
  }
}

export function* whoamiAsync() {
  const { data, errors } = yield call(ApiService.whoami);

  if (errors) {
    yield put(whoamiError(errors));
  } else {
    yield put(whoamiSuccess(data));
  }
}

export function* logoutAsync() {
  yield call([storage, storage.remove], {
    key: TOKEN_KEY,
  });
  yield put(logoutSuccess());
  yield put(resetSelectedFilter());
  window.location.replace('/');
}

export function* watchLoginAsync() {
  yield takeLatest(login.toString(), loginAsync);
  yield takeLatest(whoami.toString(), whoamiAsync);
  yield takeLatest(logout.toString(), logoutAsync);
}
