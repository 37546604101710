import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import {
  announcementsSliceName,
  announcementsSliceReducer,
} from '../features/announcement/announcements.slice';
import {
  assignmentsSliceName,
  assignmentsSliceReducer,
} from '../features/assignment/assignments.slice';
import {
  batchesSliceName,
  batchesSliceReducer,
} from '../features/batches/batches.slice';
import {
  contactSliceName,
  contactSliceReducer,
} from '../features/contact-us/contact-us.slice';
import {
  forgotPasswordSliceName,
  forgotPasswordSliceReducer,
} from '../features/forgot-password/forgot-password.slice';
import {
  centersSliceName,
  centersSliceReducer,
} from '../features/institute-centers/institute-centers.slice';
import {
  itSettingSliceName,
  itSettingSliceReducer,
} from '../features/it-setting/it-setting.slice';
import {
  loginSliceName,
  loginSliceReducer,
} from '../features/login/login.slice';
import {
  notificationSliceName,
  notificationSliceReducer,
} from '../features/notification/notification.slice';
import {
  profileSliceName,
  profileSliceReducer,
} from '../features/profile/profile.slice';
import { appSliceName, appSliceReducer } from './app.slice';
import history from './history';

const router = { router: connectRouter(history) };
const itSetting = {
  [itSettingSliceName]: itSettingSliceReducer,
};
const login = { [loginSliceName]: loginSliceReducer };
const forgotPassword = {
  [forgotPasswordSliceName]: forgotPasswordSliceReducer,
};

const profile = {
  [profileSliceName]: profileSliceReducer,
};

const contact = {
  [contactSliceName]: contactSliceReducer,
};

const app = {
  [appSliceName]: appSliceReducer,
};

const announcements = {
  [announcementsSliceName]: announcementsSliceReducer,
};

const assignments = {
  [assignmentsSliceName]: assignmentsSliceReducer,
};

const notification = {
  [notificationSliceName]: notificationSliceReducer,
};

const batches = {
  [batchesSliceName]: batchesSliceReducer,
};

const centers = {
  [centersSliceName]: centersSliceReducer,
};

export const rootReducer = combineReducers({
  ...router,
  ...itSetting,
  ...login,
  ...forgotPassword,
  ...profile,
  ...contact,
  ...app,
  ...announcements,
  ...assignments,
  ...notification,
  ...batches,
  ...centers,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    ...injectedReducers,
    ...router,
    ...itSetting,
    ...login,
    ...forgotPassword,
    ...profile,
    ...contact,
    ...app,
    ...announcements,
    ...assignments,
    ...notification,
    ...batches,
    ...centers,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
