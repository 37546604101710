import './DashboardPage.scss';

import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import FilterBox from '../../components/FilterBox';
import TopHeader from '../../components/TopHeader';

const DashboardPage: React.FC = () => {
  const history = useHistory();
  const { dashboard: dashboardText } = LanguageTexts;
  const { loading: appLoading } = useSelector((state: RootState) => state.app);

  return (
    <IonPage>
      <TopHeader showBackButton={false} />
      {!appLoading ? <FilterBox /> : null}
      <IonContent className="main-content" fullscreen>
        <IonGrid>
          <IonRow className="dashboard-parent">
            <IonCol
              onClick={() => {
                history.push(AppRoutePath.Announcements);
              }}
            >
              <div className="dashboard-child flex-column-align-center">
                <i className="section-icon fas fa-bullhorn" />
                <IonText color="light">
                  <p>{dashboardText.section1}</p>
                </IonText>
              </div>
            </IonCol>
            <IonCol
              onClick={() => {
                history.push(AppRoutePath.Assignments);
              }}
            >
              <div className="dashboard-child flex-column-align-center">
                <i className="section-icon fas fa-file-alt" />
                <IonText color="light">
                  <p>{dashboardText.section2}</p>
                </IonText>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="dashboard-parent">
            <IonCol
              onClick={() => {
                history.push(AppRoutePath.ClassSchedule);
              }}
            >
              <div className="dashboard-child flex-column-align-center">
                <i className="section-icon fas fa-calendar-alt" />
                <IonText color="light">
                  <p>{dashboardText.section3}</p>
                </IonText>
              </div>
            </IonCol>
            <IonCol
              onClick={() => {
                history.push(AppRoutePath.ContactUs);
              }}
            >
              <div className="dashboard-child flex-column-align-center">
                <i className="section-icon fas fa-address-book" />
                <IonText color="light">
                  <p>{dashboardText.section4}</p>
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default DashboardPage;
