/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonButton, IonCardContent, IonText } from '@ionic/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';

import { formatErrors, getLangAndErrKeys } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { UserCreditCardInput } from '../../../common/types';

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};

type CreditCardFormProps = {
  onSubmit: (data: UserCreditCardInput) => void;
  onCancel: () => void;
  errors: string[];
  loading: boolean;
  showCancel: boolean;
  isPayMode?: boolean;
};

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  onSubmit,
  onCancel,
  errors,
  loading,
  showCancel,
  isPayMode,
}: CreditCardFormProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const stripe = useStripe();
  const elements = useElements();
  const initialValues: UserCreditCardInput = {
    paymentMethodToken: undefined,
    autoPayment: true,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          if (!stripe || !elements) {
            return null;
          }

          const cardEle = elements.getElement(CardElement) || undefined;
          let paymentMethodToken;

          if (cardEle) {
            const { error, token } = await stripe.createToken(cardEle);

            setErrors({ paymentMethodToken: error?.message || undefined });

            paymentMethodToken = token?.id || undefined;
          } else {
            setErrors({
              paymentMethodToken: LanguageTexts.app.invalidCard,
            });
          }

          if (paymentMethodToken) {
            onSubmit({ ...values, paymentMethodToken });
            setSubmitting(false);
          }

          return null;
        }}
      >
        {({ values }) => {
          const errorKeys = getLangAndErrKeys(values);

          const formattedErrors = formatErrors(errorKeys, errors, profileTxt);

          return (
            <Form>
              <IonCardContent>
                <CardElement
                  options={cardStyle}
                  className="credit-card-element"
                />
              </IonCardContent>
              <IonCardContent>
                <IonText className="my-3">{profileTxt.autoSaveCard}</IonText>
              </IonCardContent>
              <div className="mb-1 error-msg">
                {formattedErrors.map((error) => (
                  <IonText color="secondary">
                    <p style={{ fontWeight: 'bold' }} key={error}>
                      {error}
                    </p>
                  </IonText>
                ))}
                <ErrorMessage
                  name="paymentMethodToken"
                  render={(msg) => <p style={{ fontWeight: 'bold' }}>{msg}</p>}
                />
              </div>
              <div className="text-right mb-1">
                {showCancel ? (
                  <IonButton
                    type="button"
                    disabled={loading}
                    onClick={() => onCancel()}
                  >
                    {profileTxt.btnCancel}&nbsp;
                  </IonButton>
                ) : null}
                &nbsp;
                <IonButton type="submit" disabled={loading}>
                  {isPayMode
                    ? profileTxt.payAndSaveCardBtn
                    : profileTxt.updateCardBtn}
                </IonButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

CreditCardForm.defaultProps = {
  isPayMode: false,
};

export default CreditCardForm;
