import { IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { ChangePasswordInput, RootState } from '../../../common/types';
import { changePassword, resetChangePassword } from '../profile.slice';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword: React.FC = (): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const {
    changePassword: { success, errors, loading },
  } = useSelector((state: RootState) => state.profile);
  const [showToast1, setShowToast1] = useState(false);

  function onSubmit(data: ChangePasswordInput) {
    dispatch(changePassword(data));
  }

  useEffect(() => {
    if (success) {
      dispatch(resetChangePassword());
      setShowToast1(true);
    }
  }, [success, dispatch]);

  const changePasswordInit: ChangePasswordInput = {
    newPassword: '',
    oldPassword: '',
  };

  return (
    <>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={profileTxt.passwordSuccess}
        duration={5000}
      />
      {!success ? (
        <ChangePasswordForm
          onSubmit={onSubmit}
          errors={errors}
          loading={loading}
          initialValues={changePasswordInit}
        />
      ) : null}
    </>
  );
};

export default ChangePassword;
