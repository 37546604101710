/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetNotificationsQuery,
  NotificationModel,
  NotificationsResponse,
  NotificationState,
} from '../../common/types';

const initialState: NotificationState = {
  loading: false,
  success: null,
  errors: [],
  notifications: null,
  markAsRead: {
    loading: false,
    success: null,
    errors: [],
  },
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadNotifications(state, action: PayloadAction<GetNotificationsQuery>) {
      state.loading = true;
      state.success = null;
      state.errors = [];

      state.notifications = null;
    },
    loadNotificationsSuccess(
      state,
      action: PayloadAction<NotificationsResponse>,
    ) {
      state.loading = false;
      state.success = true;

      state.notifications = action.payload.notifications;
    },
    loadNotificationsError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;

      state.notifications = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    markAsRead(state, action: PayloadAction<NotificationModel>) {
      state.markAsRead.loading = true;
      state.markAsRead.errors = [];
    },
    markAsReadSuccess(state, action: PayloadAction<NotificationModel>) {
      state.markAsRead.loading = false;
      state.markAsRead.success = action.payload;
    },
    markAsReadError(state, action: PayloadAction<string[]>) {
      state.markAsRead.loading = false;
      state.markAsRead.errors = action.payload;
    },
    resetMarkAsReadSuccess(state) {
      state.markAsRead.success = null;
    },
  },
});

export const {
  loadNotifications,
  loadNotificationsSuccess,
  loadNotificationsError,
  markAsRead,
  markAsReadSuccess,
  markAsReadError,
  resetMarkAsReadSuccess,
} = notificationSlice.actions;

export const {
  name: notificationSliceName,
  reducer: notificationSliceReducer,
} = notificationSlice;
