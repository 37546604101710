/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import '../theme/variables.scss';
import './App.scss';

import { Plugins } from '@capacitor/core';
import { IonApp, IonButton, IonModal, isPlatform } from '@ionic/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ConnectedRouter } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FILTER_KEY,
  IOS_PROMPT_KEY,
  TOKEN_KEY,
  UserRole,
} from '../common/constants';
import { RootState, SelectedFilterInput } from '../common/types';
import AppLoader from '../components/AppLoader';
import IOSPromptContent from '../components/IOSPromptContent';
import { loadBatches } from '../features/batches/batches.slice';
import { loadCenters } from '../features/institute-centers/institute-centers.slice';
import { itSetting } from '../features/it-setting/it-setting.slice';
import {
  loginSuccess,
  whoami,
  whoamiSuccess,
} from '../features/login/login.slice';
import { setSelectedFilter } from './app.slice';
import history from './history';
import RootRoutes from './rootRoutes';

const promiseStripe = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
const storage = Plugins.Storage;

const App: React.FC = () => {
  const dispatch = useDispatch();
  const {
    accessToken,
    user,
    userCheckDone,
    whoamiState: { loading: whoAmILoading },
  } = useSelector((state: RootState) => state.login);
  const { loaded: itSettingLoaded, loading: itSettingLoading } = useSelector(
    (state: RootState) => state.itSetting,
  );
  const { filter } = useSelector((state: RootState) => state.app);

  const [filterLoaded, setFilterLoaded] = useState(false);
  const [depsLoaded, setDepsLoaded] = useState(false);
  const [promptCheck, setPromptCheck] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    async function getToken() {
      const { value: tokenValue } = await storage.get({
        key: TOKEN_KEY,
      });

      if (tokenValue) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload: any = { accessToken: tokenValue };
        dispatch(loginSuccess(payload));
      }
    }

    if (!accessToken) {
      getToken();
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (!accessToken) {
      dispatch(whoamiSuccess(null));
    } else if (accessToken && !user) {
      dispatch(whoami());
    }
  }, [accessToken, user, dispatch]);

  useEffect(() => {
    if (!itSettingLoaded && !itSettingLoading) {
      dispatch(itSetting());
    }
  }, [itSettingLoaded, itSettingLoading, dispatch]);

  useEffect(() => {
    if (!depsLoaded && user) {
      dispatch(
        loadCenters({
          domain: user.domain,
        }),
      );
      dispatch(
        loadBatches({
          domain: user.domain,
        }),
      );
      setDepsLoaded(true);
    }
  }, [depsLoaded, user, dispatch]);

  // restore filters from local storage
  useEffect(() => {
    async function getFilters() {
      const { value: filtersData } = await storage.get({ key: FILTER_KEY });
      const filters = filtersData ? JSON.parse(filtersData) : null;

      if (filters) {
        dispatch(
          setSelectedFilter({
            ...filters,
          }),
        );
      }

      setFilterLoaded(true);
    }

    if (!filterLoaded) {
      getFilters();
    }
  }, [filterLoaded, dispatch]);

  // init filter based on user role if empty
  useEffect(() => {
    if (
      user &&
      filterLoaded &&
      !filter.role &&
      !filter.batchId &&
      !filter.centerId &&
      !filter.childrenId
    ) {
      const initFilters: SelectedFilterInput = {
        batchId: null,
        centerId: null,
        childrenId: null,
        role: null,
      };

      if (user.role.indexOf(UserRole.Student) !== -1) {
        initFilters.batchId = user.batches?.[0].batch._id || null;
      } else if (user.role.indexOf(UserRole.Parent) !== -1) {
        const firstChild = user.children?.[0] || null;

        if (firstChild) {
          initFilters.childrenId = firstChild._id;
          initFilters.batchId = firstChild.batches?.[0].batch._id || null;
        } else {
          initFilters.childrenId = ' ';
          initFilters.batchId = ' ';
        }
      }

      dispatch(
        setSelectedFilter({
          ...initFilters,
        }),
      );
    }
  }, [filter, user, filterLoaded, dispatch]);

  // check ios prompt for PWA
  useEffect(() => {
    async function checkIOSPrompt() {
      if (isPlatform('ios')) {
        const { value: promptShownData } = await storage.get({
          key: IOS_PROMPT_KEY,
        });

        const promptShown = promptShownData
          ? JSON.parse(promptShownData)
          : false;

        if (!promptShown) {
          setShowPrompt(true);
          await storage.set({
            key: IOS_PROMPT_KEY,
            value: JSON.stringify(true),
          });
        }
      }

      setPromptCheck(true);
    }

    if (!promptCheck) {
      checkIOSPrompt();
    }
  }, [promptCheck, dispatch]);

  if (whoAmILoading || !userCheckDone || !itSettingLoaded || !filterLoaded) {
    return <AppLoader />;
  }

  return (
    <Elements stripe={promiseStripe}>
      <ConnectedRouter history={history}>
        <IonApp>
          <RootRoutes />
          <IonModal isOpen={showPrompt} cssClass="it-ios-pwa-prompt">
            <IOSPromptContent />
            <IonButton onClick={() => setShowPrompt(false)}>Close</IonButton>
          </IonModal>
        </IonApp>
      </ConnectedRouter>
    </Elements>
  );
};

export default App;
